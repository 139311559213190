.front-end .banner-left h2 span {
    font-size: 63px;
}
.front-end .banner-left h2 span.color{
    font-size: 74px;
}


@media(max-width:1440px){
    .front-end .banner-left h2 span {
        font-size: 50px;
    }
    .front-end .banner-left h2 span.color {
        font-size: 60px;
    }
}

@media (max-width:1366px){
    .front-end .banner-left h2 span {
        font-size: 45px;
    }
    .front-end .banner-left h2 span.color {
        font-size: 52px;
    }
}
@media (max-width:1250px){
    .front-end .banner-left h2 span {
        font-size: 42px;
    }

    .front-end .banner-left h2 span.color {
        font-size: 48px;
    }
}

@media (max-width:1200px){
    
}

@media (max-width:1024px){
    
}

@media (max-width:991px){
    
}

@media(max-width:767px){
    
}

@media (max-width:740px){
   
}

@media (max-width:600px){
   
}

@media (max-width:575px){
    .front-end .banner-left h2 span {
        font-size: 33px;
    }

    .front-end .banner-left h2 span.color {
        font-size: 40px;
    }
    
}

@media (max-width:480px){
    .front-end .banner-left h2 span {
        font-size: 28px;
    }

    .front-end .banner-left h2 span.color {
        font-size: 35px;
    }
}

@media (max-width:400px){
    .front-end .banner-left h2 span {
        font-size: 24px;
    }

    .front-end .banner-left h2 span.color {
        font-size: 28px;
    }
}
