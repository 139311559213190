.exit-modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.exit-modal .modal-content{
    max-width: 500px;
    flex: 1;
    margin: 10px;
    background-color: #fff;
    text-align: center;
}
.exit-modal h2{
    font-size: 35px;
    padding-bottom: 15px;
}
.exit-modal p{
    font-size: 20px;
}

.exit-modal .stay-pge-btn{
    font-size: 14px;
    background-color: #F0612E;
    color: #fff;
    padding: 10px 15px;
    margin: 20px 5px 0;
    border-radius: 30px;
    width: 150px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
}

.exit-modal .PhoneInput{
    border-bottom: solid 1px #000;
}
.exit-modal .form-group .PhoneInput input{
    border-bottom: 0;
}

.exit-modal .leave-pge-btn{
    font-size: 14px;
    background-color: #20c0a0;
    color: #fff;
    padding: 10px 15px;
    margin: 20px 5px 0;
    border-radius: 30px;
    width: 150px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
}