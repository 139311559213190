body {font-family: 'Assistant', sans-serif !important}


.design-and-developer .banner-left{
    text-align: center;
    margin: 0 auto;
    width: 100%;
}
.design-and-developer .banner-left h2 span {
    font-size: 75px;
    line-height: 62px;
}
.design-and-developer .banner-left h2 {
    font-size: 50px;
    line-height: 80px;
}
.design-and-developer .banner-left h2 span.color {
    font-size: 77px;
}
.design-and-developer .bnr-dflex form{
    justify-content: center;
}
.design-and-developer .banner-feture{
    max-width: 900px;
    margin: 0 auto;
    text-align: left;
}
.design-and-developer .full-stack .rating_box{
    max-width: 900px;
    margin: 0 auto;
}
.design-and-developer .full-stack-batch{
    max-width: 500px;
    margin: 0 auto;
}
.design-and-developer .full-stack-batch-box img{
    width: auto;
    height: auto;
}

.design-and-developer .hair_designer_bannerbtm {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    border-top: solid 1px rgba(255, 255, 255, 0.4);
}

.design-and-developer .hair_designer_bannerbtm_box {
    color: #fff;
    padding: 0 60px;
    text-align: center;
}
.design-and-developer .hair_designer_bannerbtm_box h3 {
    font-size: 17px;
    font-weight: 400;
    list-style: 25px;
    padding-top: 18px;
}

.design-and-developer .hair_designer_bannerbtm_box+.hair_designer_bannerbtm_box {
    border-left: 1px dashed #fff;
}

.right-sec-prt h3 span.bg{
    line-height: 46px;
}

.contact_ups .contact_content .ttx h2{
    font-size: 42px;
    line-height: 47px;
}

.design-and-developer .pricing_scaling .divider:last-child .top-bar{
    padding: 20px 10px 115px;
}
.design-and-developer .pricing_scaling .divider:last-child .bottom-bar h4 {
    margin-bottom: 15px;
    margin-top: 40px;
}
.design-and-developer .pricing_scaling .divider .bottom-bar h4{
    font-size: 17px;
}
.design-and-developer .pricing_scaling .divider .top-bar p {
    padding-top: 10px;
}

.india-web-design .section_three{
    padding-top: 100px;
}

.india-web-design .offering {
    /* background-color: #f9f9f9; */
    background: rgb(52,41,132);
    background: radial-gradient(circle,rgba(52,41,132,1) 0%,rgba(27,3,52,1) 64%);
    padding: 90px 0;
    text-align: center;
    margin-top: 100px;
}
.india-web-design .offering h3 {
    color: #fff;
    font-family: Montserrat,sans-serif;
    font-size: 28px;
    line-height: 34px;
}
.india-web-design .offering h3 span {
    color: #ff662a;
}
.india-web-design .fullstack_bg {
    background-color: #f0612e;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    line-height: 95%!important;
}
.india-web-design .offering p {
    color: #fff;
    font-family: Montserrat,sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 15px 0 0;
}
.india-web-design .offering_allbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 30px;
}
.india-web-design .offering_allbox>div {
    background-color: #fff;
    margin: 20px 1% 0;
    padding: 20px;
    width: 14.66%;
}

.india-web-design .offering .offering_allbox h3 {
    color: #000;
    font-family: Montserrat,sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-top: 15px;
}

.design-and-developer .banner-left .banner-text-heading{
    max-width: 1200px;
    margin: 0 auto;
    line-height: 60px;
    /* background-image: url('../../Assets/Images/orange_underline_brush_stroke.png'); */
    background-position: 95% 80%;
    background-repeat: no-repeat;
    padding-bottom: 20px;
}
.design-and-developer .banner-left .banner-text-heading span{
    color: #20c0a0;
    font-size: 45px;
    line-height: 60px;
}
.design-and-developer .banner-left .banner-text-para{
    font-size: 22px;
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 50px;
    background-image: url('../../Assets/Images/pattern_devider.png');
    background-position: 50% 0%;
    background-repeat: no-repeat;
}
.design-and-developer .banner-left .banner-text-para span{
    font-weight: 700;
    color: #f0612e;
}

.banner-btn-tag{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px 0 30px;
    flex-wrap: wrap;
}
.banner-btn-tag div{
    background-color: #011dac;
    color: #fff;
    border-radius: 40px;
    padding: 12px 30px;
    font-size: 16px;
    font-weight: 600;
    margin: 10px 10px 0;
    white-space: nowrap;
}
.banner-btn-tag div:nth-child(2){
    background-color: #00739c;
}
.banner-btn-tag div:nth-child(3){
    background-color: #f0612e;
}
.banner-btn-tag div:nth-child(4){
    background-color: #8100e6;
}

.home-banner-bottom{
    display: flex;
    justify-content: space-between;
    text-align: left;
}
.design-and-developer .home-banner-bottom .full-stack-batch{
    max-width: 400px;
}
.design-and-developer .home-banner-bottom p{
    margin-right: 100px;
    margin-top: 35px;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.05);
    font-weight: 600;
    color: #ff662a;
}
.design-and-developer .home-banner-bottom p span{
    color: #fff;
}

.banner-modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9991;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
.banner-modal-box{
    background-color: #fff;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    margin: 20vh auto 0;
    border-radius: 10px;
}
.banner-modal-box .close{
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    color: #818181;
}

.banner-modal-tab{
    display: flex;
    background-color: #eaeaea;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    color: #5b5a5a;
    padding: 15px 25px;
    justify-content: flex-start;
}
.modal-tab-content {
    padding: 35px;
}
.banner-modal-tab > div{
    cursor: pointer;
    margin-bottom: -1px;
    font-size: 14px;
    margin: 0 5px;
    padding: 7px 15px;
}

.banner-modal-box h2 span{
    display: inline-block;
    padding-right: 50px;
    background-color: #1734c4;
    color: #fff;
    padding: 3px 60px 3px 5px;
}
.banner-modal-box h2 span.sky{
    background-color: #0f95d3;
}
.banner-modal-box h2 span.orange{
    background-color: #f0612e;
}
.banner-modal-box h2 span.perpel{
    background-color: #8100e6;
}

.banner-modal-box p{
    padding-top: 15px;
}
.banner-modal-button{
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}
.banner-modal-button button{
    background-color: #818181;
    padding: 10px 25px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 15px;
    color: #fff;
    margin-top: 10px;
    border: 0;
    transition: 0.5s;
}
.banner-modal-button button:hover{
    background-color: #484848;
}
.banner-modal-button button.blue{
    background-color: #000;
}
.banner-modal-button button.blue:hover{
    background-color: #ce4716;
}

.text-mo-slide-new .testmonial-box h5 {
    font-size: 20px;
    min-height: 50px;

}
.text-mo-slide-new .testmonial-box p{
    min-height: 240px;
}
.text-mo-slide-new .testmonial-box .testmonial-details p, .text-mo-slide-new .testmonial-box .testmonial-details h5{
    min-height: auto;
}
.oneside-faq .faq_rifgt {
    width: 70%;
}
.oneside-faq .faq_left {
    width: 30%;
}
.oneside-faq .faq_total {
    display: flex;
}
.oneside-faq .accordion-half{
    width: 100%;
}
.oneside-faq .accordion {
    padding-top: 0;
}
.faq_sec.oneside-faq{
    padding: 0 0 60px;
}

.oneside-faq .faq_total h5 {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    color: #ff662a;
    padding-top: 25px;
}

.oneside-faq .faq_total h3 {
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
    color: #000;
}




.get_experts_total{ display: flex;}
.get_experts_left{ width: 40%; padding-right: 50px; padding-top: 25px;}
.get_experts_right{ width: 60%; display: flex; flex-wrap: wrap;}
.get_experts_right_box{ width:46%; margin:30px 2% 0 2%;-webkit-box-shadow: 0 2.8125rem 3.75rem 0 rgba(34, 34, 34, .1); box-shadow: 0 2.8125rem 3.75rem 0 rgba(34, 34, 34, .1); background-color: #fff; padding: 20px;}

.number_count{display: flex; flex-wrap: wrap;}
.number_count > div{ width: 50%; padding-right: 20px; margin:10px 0;}

.get_experts{ padding:90px 0;}
.get_experts h2{ font-size: 30px; line-height: 37px; color: #000; font-family:'Montserrat', sans-serif ;}
.get_experts p.line{ font-size: 16px; line-height: 24px; color: #000; font-family:'Montserrat', sans-serif ; font-weight: 400; padding: 20px 0; border-bottom: 1px solid #ff662a;}
.number_count{ margin-top: 20px;}

.get_experts_left h3{ font-size: 25px; line-height: 30px; color: #000; font-family:'Montserrat', sans-serif ;}
.get_experts p{ font-size: 16px; line-height: 24px; color: #000; font-family:'Montserrat', sans-serif ; font-weight: 400; padding-top: 10px;}

.requerment_btn{ font-size: 16px; line-height: 50px; font-family:'Montserrat', sans-serif ; font-weight: 600; display: inline-block; padding:0 40px; border-radius: 50px; margin-top: 40px; cursor: pointer; background-color: #ff662a; color: #fff; text-transform: uppercase;}
.section_three{ padding: 0 !important;}
.get_experts_right_box_top{ display: flex; align-items: center;}
.get_experts_right_box_top img{
    width: 40px;
}
.get_experts_right_box_top h3{ font-size:16px; line-height:22px; font-family:'Montserrat', sans-serif ; color: #000; padding-left: 15px;}

.get_experts_right_box ul{ padding-top: 20px;}
.get_experts_right_box ul li{ font: 400 16px/20px 'Montserrat', sans-serif;  padding: 10px 0 0 25px; background: url(../../Assets/Images/landing-page-fullstack/bluearrow.png) no-repeat 0 12px;  width: 100%;  color: #000;}

.india-home-page .section_three{
    padding-top: 80px !important;
}


.india-home-page .why_redensoft{ padding: 90px 0;}
.india-home-page .why_redensoft h2{ font-size: 50px; line-height:60px; font-weight: 700; color: #000;}
.india-home-page .why_redensoft h2 span{ color: #ff662a;}
.india-home-page .why_redensoft_total{ display: flex;}

.india-home-page .why_redensoft_left{ width: 50%;}
.india-home-page .why_redensoft .why_redensoft_left ul{
    padding-top: 15px;
}
.india-home-page .why_redensoft .why_redensoft_left ul li{
    font-size: 14px;
    padding: 5px 0 5px 20px;
    position: relative;
}
.india-home-page .why_redensoft .why_redensoft_left ul li:before{
    position: absolute;
    content: '';
    top: 9px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background-color: #484848;
}
.india-home-page .why_redensoft .why_redensoft_left h3{ font-size:20px; line-height:25px; font-weight:600; color: #000; position: relative; padding-left:32px;}
.india-home-page .why_redensoft .why_redensoft_left h3:after{ position: absolute; background: url(../../Assets/Images/landing-page-fullstack/orange_tick.png) no-repeat 0 0; content: ''; width:32px; height:32px; left: 0; top: 2px;background-size: 1.3125rem;}
.india-home-page .why_redensoft .why_redensoft_left p{ font-size: 16px; line-height: 24px; color: #000; font-family: 'Montserrat', sans-serif; font-weight: 400; padding: 10px 0 0;}
.india-home-page .why_redensoft_left_allbox{ display: flex; flex-wrap: wrap;}
.india-home-page .why_redensoft_left_allbox > div{ width: 50%; padding-right: 25px; margin-top: 40px;}
.india-home-page .why_redensoft .why_redensoft_right_img{ margin-left: 24.66666667%; position: relative;}
.india-home-page .why_redensoft .man_img_box img  {
    border-radius: 50px;
}

.fullstack_banner_ul{ display: flex;}
.fullstack_banner_ul ul+ul{ padding-left: 50px;}



.india-home-page .rating-box{   background: linear-gradient(193deg, rgba(101,172,233,1) 0%, rgba(236,97,0,1) 100%) !important; position: absolute; width: 250px; box-shadow: 0px 20px 36px #00000014;  border-radius: 12px;
    top: 140px;  left: -25%; padding: 20px;}
.india-home-page .rating-box h5{ font-size: 20px; line-height: 25px; font-family: 'Montserrat', sans-serif; color: #fff;}
.india-home-page .why_redensoft .why_redensoft_left .rating-box p{ font-size: 20px; line-height: 25px; font-family: 'Montserrat', sans-serif; font-size: 300; color: #fff;}
.india-home-page .why_redensoft .why_redensoft_left .rating-box .text-white{ padding-bottom: 27px;}

.india-home-page .right-sec-prt h3 {
    color: #000;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
}

.back-to-top-button{
    position: fixed;
    z-index: 99;
    right: 25px;
    bottom: 100px;
    background-color: #20c0a0;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.slick-arrow, .slick-arrow.slick-next{
    background-color: #ffffff !important;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    width: 35px;
    height: 35px;
    border: 0;
    transform: rotate(0);
}
.slick-arrow::after {
    content: "";
    width: 8px;
    position: absolute;
    height: 8px;
    border-top: solid 2px #717171;
    border-left: solid 2px #717171;
    transform: rotate(-45deg);
    left: 14px;
    top: 14px;
}
.slick-arrow.slick-next::after {
    transform: rotate(135deg);
    left: 11px;
    top: 14px;
}

@media (max-width:1441px) {
    .india-home-page .right-sec-prt h3{
        font-size: 39px;
        line-height: 46px;
    }
    .india-home-page .right-sec-prt h3 span{
        padding: 0px 5px;
    }
    .text-mo-slide-new .testmonial-box p {
        min-height: 276px;
    }
    .india-home-page .why_redensoft_left{
        width: 60%;
    }
    .india-home-page .why_redensoft_left + .why_redensoft_left{
        width: 40%;
    }
    .india-home-page .why_redensoft .why_redensoft_left .rating-box p {
        font-size: 14px;
        line-height: 17px;
    }
    .india-home-page .why_redensoft h2 {
        font-size: 32px;
        line-height: 37px;
    }
    .india-home-page .why_redensoft .why_redensoft_left h3 {
        font-size: 19px;
        line-height: 20px;
    }
}
@media (max-width:1366px) {
    .india-home-page .right-sec-prt h3{
        font-size: 37px;
        line-height: 42px;
    }
}
@media (max-width:1240px) {
    .india-home-page .right-sec-prt h3{
        font-size: 30px;
        line-height: 46px;
    }
}
@media (max-width:991px) {
    .get_experts_left{
        width: 100%;
        padding-right: 0;
    }
    .get_experts_total, .india-home-page .why_redensoft_total{
        flex-wrap: wrap;
    }
    .india-home-page .why_redensoft .why_redensoft_right_img{
        margin-top: 30px;
        margin-left: 0;
    }
    .india-home-page .why_redensoft .man_img_box img{
        border-radius: 10px;
        max-width: 400px;
    }
    .india-home-page .rating-box{
        left: 0;
        top: auto;
        bottom: 3px;
    }
    .get_experts_right, .india-home-page .why_redensoft_left, .india-home-page .why_redensoft_left + .why_redensoft_left{
        width: 100%;
    }
    .oneside-faq .faq_left {
        width: 100%;
    }
    .oneside-faq .faq_total{
        flex-wrap: wrap;
    }
    .oneside-faq .faq_rifgt {
        width: 100%;
    }
    .design-and-developer .home-banner-bottom p{
        margin-right: 0;
        margin: 20px 0 0;
        padding: 10px;
    }
    .home-banner-bottom{
        display: block;
    }
    .design-and-developer .home-banner-bottom .full-stack-batch{
        padding: 20px 0;
    }
    .landibgfullstack .offering_allbox > div {
        width: 24%;
        margin: 20px 0.5% 0 0.5%;
        padding: 20px;
    }
    .design-and-developer .banner-left .banner-text-heading, 
    .design-and-developer .banner-left .banner-text-heading span{
        font-size: 35px;
        background-image: none;
        line-height: 40px;
    }
    .design-and-developer .banner-left .banner-text-para{
        font-size: 15px;
        line-height: 22px;
        background-size: 200px;
        padding-top: 25px;
    }
    .india-home-page .responsive-video-padd{
        padding: 0 20px;
    }
    .india-home-page .text-mo-slide-new.responsive-video-padd{
        padding: 0 5px;
    }
    .india-home-page .testmonial-box{
        padding: 20px;
    }
    .india-home-page .testmonial-box p{
        font-size: 14px;
        line-height: 22px;
    }
}

@media (max-width:767px)  {
    .get_experts_right_box {
        width: 100%;
        margin: 20px 0 0;
    }
    .india-home-page .why_redensoft_left_allbox > div{
        width: 100%;
        padding-right: 0;
    }
    .design-and-developer .hair_designer_bannerbtm{
        flex-wrap: wrap;
    }
    .design-and-developer .hair_designer_bannerbtm_box{
        width: 50%;
        padding: 20px;
    }
    .design-and-developer .hair_designer_bannerbtm_box:nth-child(3){
        border-left: 0;
    }

    .design-and-developer .banner-left h2 {
        font-size: 35px;
        line-height: 50px;
    }
    .design-and-developer .banner-left h2 span.color {
        font-size: 42px;
    }
    .design-and-developer .banner-left h2 span {
        font-size: 42px;
        line-height: 42px;
    }
    .right-sec-prt h3 span.bg{
        line-height: 28px;
    }
    .design-and-developer .banner-left .banner-text-heading, 
    .design-and-developer .banner-left .banner-text-heading span{
        font-size: 25px;
        background-image: none;
        line-height: 32px;
    }
    .banner-btn-tag div{
        font-size: 12px;
        padding: 10px 15px;
        margin: 5px;
    }
    .india-home-page .contact_ups .contact_content .frm-box-rp{
        padding: 20px 0 0;
    }
    .india-home-page .get_experts{
        padding: 30px 0 60px;
    }
    .india-web-design .offering{
        padding: 60px 0;
    }
}


@media (max-width:520px)  {
    .india-web-design .offering, .india-home-page .why_redensoft, 
    .india-web-design .developer-sec, .india-web-design .developer-video-slider{
        padding: 50px 0 !important;
    }
    .india-home-page .section_three{
        padding-top: 50px !important;
    }
    .india-home-page .right-sec-prt h3 {
        font-size: 24px;
        line-height: 32px;
    }
    .india-home-page footer .copy-riht{
        margin-top: 35px;
    }

    .india-home-page .text-mo-slide-new .testmonial-box p{
        min-height: auto;
    }

    .india-home-page .contact_ups .contact_content .ttx h2{
        font-size: 22px;
        line-height: 28px;
    }

    .design-and-developer .banner-left h2 {
        font-size: 33px;
        line-height: 45px;
    }
    .design-and-developer .banner-left h2 span.color {
        font-size: 35px;
    }
    .design-and-developer .banner-left h2 span {
        font-size: 35px;
        line-height: 38px;
    }
    .banner-btn-tag div{
        width: 200px;
        margin: 5px;
    }
    .banner-btn-tag{
        padding-bottom: 0;
    }
    .modal-tab-content{
        padding: 20px;
    }
    .banner-modal-button button{
        font-size: 13px;
    }
    .testmonial-box{
        padding: 15px;
    }
    .india-home-page .why_redensoft .man_img_box img{
        max-width: 380px;
    }
}

@media (max-width:420px)  {
    .india-home-page .why_redensoft .man_img_box img{
        max-width: 100%;
    }

    .design-and-developer .banner-left h2 {
        font-size: 28px;
        line-height: 35px;
    }
    .design-and-developer .banner-left h2 span.color {
        font-size: 30px;
    }
    .design-and-developer .banner-left h2 span {
        font-size: 30px;
        line-height: 32px;
    }

    .banner-btn-tag div{
        font-size: 10px;
        padding: 10px 15px;
        margin: 5px;
    }
}