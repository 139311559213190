.header_top_border  {
    width: 100%;
    background: linear-gradient(90deg, #C96254 36.77%, rgba(201, 98, 84, 0.00) 50.13%), #4748C3;
    height: 16px;
}

.banner_header  {
    background: linear-gradient(156deg, #FFF3F0 14.64%, rgba(255, 243, 240, 0.00) 41.31%), #F4F4FF;
}

.banner_header .header-part .header_middle ul li .menu_items {
    color: #000;
}

.banner_header .header-part .header_middle ul li .menu_items:hover {
    color: #20c0a0;
}

/* .header-section.slide-in .header-part .header_middle ul li .menu_items {
    color: #fff;
} */

.banner_header .banner_part  {
    padding-top: 87px;
    padding-bottom: 365px;
    position: relative;
}

.banner_header .banner_part .banner_part_text  {
    max-width: 850px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.banner_header .banner_part .banner_part_text::before {
    position: absolute;
    content: '';
    width: 208px;
    height: 205px;
    top: -50px;
    background: url(../../Assets//Images/hiredeveloper/banner-imgone.png) no-repeat 0 100%;
    left: -183px;
}

.banner_header .banner_part .banner_part_text::after {
    position: absolute;
    content: '';
    width: 187px;
    height: 223px;
    top: 50px;
    background: url(../../Assets//Images/hiredeveloper/banner-imgtwo.png) no-repeat 0 100%;
    right: -230px;
}

.banner_header .banner_part .banner_part_text h2  {
    color: #000;
    text-align: center;
    font-size: 25px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    padding-bottom: 20px;
}

.banner_header .banner_part .banner_part_text h1  {
    color: #000;
    font-size: 90px;
    font-weight: 800;
    line-height: 85px;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 40px;
}

.banner_header .banner_part .banner_part_text h1 span  {
    background: linear-gradient(115deg, #DD6745 8.43%, rgba(178, 84, 84, 0.00) 77.51%), #4748C3;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner_header .banner_part .banner_part_text a  {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 80px;
    background: linear-gradient(115deg, #DD6745 8.43%, rgba(178, 84, 84, 0.00) 77.51%), #4748C3;
    padding: 20px 42px;
    display: inline-block;
}

.banner_header .banner_part .banner_part_text a:hover  {
    background: #20c0a0;
    background: linear-gradient(115deg, #20c0a0 8.43%, rgba(178, 84, 84, 0) 77.51%), #20c0a0;
}

.banner_header .banner_part .banner_part_text a span  {
    width: 13%;
    padding-left: 6px;
}

.expert_section  {
    margin-top: -243px;
    padding: 170px 0 146px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0 280px 0 0;
    position: relative;
}

.expert_section .expert_heading  {
    max-width: 1195px;
}

.expert_section .expert_heading h2  {
    color: #FFF;
    font-size: 70px;
    font-weight: 800;
    line-height: 75px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
}

.expert_section .expert_heading h2 span  {
    font-size: 80px;
    background: linear-gradient(115deg, #DD6745 8.43%, rgba(178, 84, 84, 0.00) 77.51%), #4748C3;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.expert_section .expert_flex  {
    display: flex;
    position: relative;
}

.expert_section .expert_flex .expert_para  {
    width: 437px;
    padding-top: 60px;
}

.expert_section .expert_flex .expert_para p  {
    color: #FFF;
    font-size: 20px;
    font-weight: 300;
    line-height: 34px;
}

.expert_section .expert_flex .expert_para p span  {
    font-weight: 700;
}

.expert_section .expert_flex .expert_round  {
    position: relative;
    width: calc(100% - 437px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.expert_section .expert_flex .expert_round .expert_round_box  {
    position: relative;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_roundspan  {
    border-radius: 578.418px;
    border: 1px dashed #FFF;
    background: rgba(255, 255, 255, 0.00);
    width: 578.418px;
    height: 578.418px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_roundspan .expert_round_text  {
    position: relative;
    border-radius: 354.609px;
    background: linear-gradient(150deg, #FFDBD1 12.86%, rgba(255, 243, 240, 0.00) 62.69%), linear-gradient(0deg, rgba(244, 244, 255, 0.20) 0%, rgba(244, 244, 255, 0.20) 100%), #E8E8FF;
    width: 354.609px;
    height: 354.609px;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_roundspan .expert_round_text::after  {
    content: "";
    width: 502.236px;
    height: 502.236px;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    border-radius: 502.236px;
    border: 1px dashed #FFF;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.00);
    position: absolute;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_roundspan .expert_round_text::before  {
    content: "";
    width: 431.697px;
    height: 431.697px;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    border-radius: 502.236px;
    border: 1px dashed #FFF;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.00);
    position: absolute;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_roundspan .expert_round_text h3  {
    color: #000;
    font-size: 28px;
    font-weight: 900;
    line-height: 34px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    padding-top: 20px;
    max-width: 250px;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_round_one  {
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 273.69px;
    background: linear-gradient(115deg, #DD6745 8.43%, rgba(178, 84, 84, 0.00) 77.51%), #4748C3;
    position: absolute;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_round_one h3  {
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    padding-bottom: 15px;
    max-width: 170px;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_round_one img  {
    width: 7%;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_round_one_position  {
    top: 0;
    left: -40px;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_round_two_position  {
    top: 45px;
    right: -70px;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_round_three_position  {
    bottom: -84px;
    right: 60px;
}

.expert_section .expert_flex .expert_round .expert_round_box .expert_round_four_position  {
    bottom: 50px;
    left: -80px;
}

.our_works  {
    padding-bottom: 160px;
}

.our_works .top_bar {
    padding-top: 110px;
    padding-bottom: 270px;
    background: #CC6352;
}

.our_works .top_bar .title h2 {
    color: #FFF;
    text-align: center;
    font-size: 140px;
    font-weight: 800;
    letter-spacing: 1.4px;
    text-transform: uppercase;
}

.our_works .works_imagebox  {
    margin-top: -200px;
    display: flex;
    justify-content: space-between;
}

.our_works .works_imagebox .works_image  {
    width: 33.33%;
    padding: 15px;
}

.our_works .works_imagebox .works_image img  {
    width: 100%;
    display: block;
    border-radius: 10px;
}

.our_works .works_imagebox .works_image h3  {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    padding-top: 10px;
}

.our_works .works_imagebox .works_image h3 span  {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.7px;
}

.Work_section .Work_section_flex  {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Work_section .Work_section_flex .Work_section_devide  {
    width: 50%;
    padding: 15px;
    position: relative;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_text h2 {
    color: #000;
    font-size: 57px;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0.74px;
    text-transform: uppercase;
    padding-bottom: 40px;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_text a  {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 80px;
    background: linear-gradient(115deg, #DD6745 8.43%, rgba(178, 84, 84, 0.00) 77.51%), #4748C3;
    padding: 20px 42px;
    display: inline-block;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_text a:hover  {
    background: #20c0a0;
    background: linear-gradient(115deg, #20c0a0 8.43%, rgba(178, 84, 84, 0) 77.51%), #20c0a0;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_text a span  {
    width: 13%;
    padding-left: 6px;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_slider  {
    padding-left: 30px;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_slider .work_slider_box  {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.28);
    padding: 30px;
    text-align: center;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_slider .slick-slide {
    padding: 15px 10px;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_slider .slick-dots li button:before  {
    font-size: 12px;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_slider .work_slider_imagebox  {
    width: 120px;
    height: 120px;
    border-radius: 50px;
    margin: auto;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_slider .work_slider_imagebox img  {
    width: 100%;
    border-radius: 50px;
    margin: auto;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_slider .client_slider_text  {
    padding-top: 15px;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_slider .client_slider_text h4  {
    color: #000;
    font-size: 23px;
    font-weight: 600;
    letter-spacing: -0.23px;
    padding-bottom: 4px;
    line-height: normal;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_slider .client_slider_text p  {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 18px;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_slider .client_slider_text ul  {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_slider .client_slider_text ul li  {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    padding: 2px 9px;
    letter-spacing: -0.13px;
    border-radius: 50px;
    border: 1px solid #000;
    background: #F1F3F4;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_logo  {
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 159.856px;
    height: 278.21px;
}

.Work_section .Work_section_flex .Work_section_devide .Work_devide_logo img  {
    width: 100%;
    height: 100%;
}

.deserve_section  {
    padding-top: 130px;
}

.deserve_section .deserve_background  {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 95px 100px;
    position: relative;
    border-radius: 50px;
}

.deserve_section .deserve_background .deserve_flex  {
    display: flex;
}

.deserve_section .deserve_background .deserve_flex .deserve_sub  {
    width: 50%;
}

.deserve_section .deserve_background .deserve_flex .deserve_sub .deserve_bold_text h2  {
    color: #FFF;
    font-size: 70px;
    font-weight: 800;
    line-height: 65px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
}

.deserve_section .deserve_background .deserve_flex .deserve_sub .deserve_bold_text .font_size  {
    font-size: 40px;
    letter-spacing: 0.4px;
}

.deserve_section .deserve_background .deserve_flex .deserve_sub .deserve_bold_text .font_colour  {
    background: linear-gradient(115deg, #DD6745 8.43%, rgba(178, 84, 84, 0.00) 77.51%), #4748C3;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.deserve_section .deserve_background .deserve_flex .deserve_sub .deserve_text  {
    padding-left: 40px;
    border-left: 1px dotted #fff;
}

.deserve_section .deserve_background .deserve_flex .deserve_sub .deserve_text p  {
    color: #FFF;
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
    padding-bottom: 40px;
}

.deserve_section .deserve_background .deserve_flex .deserve_sub .deserve_text p span  {
    font-weight: 700;
}

.deserve_section .deserve_background .deserve_flex .deserve_sub .deserve_text a  {
    color: #FFF;
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    background: linear-gradient(115deg, #DD6745 8.43%, rgba(178, 84, 84, 0.00) 77.51%), #4748C3;
    padding: 18px 50px;
    display: inline-block;
    border-radius: 50px;
}

.deserve_section .deserve_background .deserve_flex .deserve_sub .deserve_text a:hover  {
    background: #20c0a0;
    background: linear-gradient(115deg, #20c0a0 8.43%, rgba(178, 84, 84, 0) 77.51%), #20c0a0;
}

.deserve_section .deserve_background .deserve_flex .deserve_sub .deserve_text a span  {
    width: 13%;
    padding-left: 6px;
}

.mind_blowing_section.mind_blowing .title h3 {
    font-size: 70px;
    font-weight: 800;
}

.mind_blowing_section .d-flex-wap  {
    padding: 0 170px;
}

.mind_blowing_section.mind_blowing .item-one  {
    background: #FFF;
    border: none;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.22);
}

.mind_blowing_section.mind_blowing .item-one h4 {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.34px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 4px;
}

.mind_blowing_section.mind_blowing .item-one h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #CC6352;
    text-align: center;
    font-size: 76px;
    font-weight: 700;
    line-height: 66px;
    letter-spacing: 0.9px;
}

.mind_blowing_section.mind_blowing .item-one h3  {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.3px;
    margin-top: 2%;
}

.mind_blowing_section.mind_blowing .item-one p {
    color: #000;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.34px;
    text-transform: uppercase;
}

.mind_blowing_section.mind_blowing .item-two  {
    background: none;
    box-shadow: none;
    border: 1px dashed #292929;
    padding: 10px;
}

.mind_blowing_section.mind_blowing .item-two .item-two-padding  {
    background: linear-gradient(115deg, #DD6745 8.43%, rgba(178, 84, 84, 0.00) 77.51%), #4748C3;
    width: 100%;
    height: 100%;
    border-radius: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mind_blowing_section.mind_blowing .item-two h4 {
    color: #FFF;
    font-size: 22px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.66px;
    text-transform: uppercase;
}

.mind_blowing_section.mind_blowing .item-two h2  {
    color: #FFF;
    font-size: 60px;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0.75px;
    background: none;
    background-clip: inherit;
    -webkit-background-clip: inherit;
    -webkit-text-fill-color: inherit;
    padding: 10px 0;
}

.mind_blowing_section.mind_blowing .item-two p {
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.66px;
    text-transform: uppercase;
}

.fasts_affordably  {
    padding: 100px 0 90px 0;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.fasts_affordably .fast_affordably_box  {
    text-align: center;
}

.fasts_affordably .fast_affordably_box h2  {
    color: #FFF;
    font-size: 100px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    padding-bottom: 15px;
}

.fasts_affordably .fast_affordably_box h2 span  {
    font-size: 70px;
    line-height: 64px;
}

.fasts_affordably .fast_affordably_box h3  {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    background: linear-gradient(115deg, #FF8E7C 8.43%, rgba(178, 84, 84, 0.00) 77.51%), #8A8BFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 24px;
}

.fasts_affordably .fast_affordably_box p  {
    color: #FFF;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    max-width: 980px;
    margin: 0 auto 30px auto;
}

.fasts_affordably .fast_affordably_box p span  {
    font-weight: 700;
}

.fasts_affordably .fast_affordably_box a  {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 28px;
    color: #f5f5f5;
    font-weight: 700;
    border-radius: 38px;
    filter: drop-shadow(2px 3.464px 28.5px rgba(95,128,193,0.49));
    background-image: linear-gradient(88deg, #df6541 0%, #678bcb 100%);
    padding: 18px 71px;
    display: inline-block;
    transition: all ease-in-out .3s;
}

.fasts_affordably .fast_affordably_box a span  {
    width: 13%;
    padding-left: 6px;
    display: inline-block;
}

.fasts_affordably .fast_affordably_box a:hover  {
    background: #f07651;
    color: #fff;
}