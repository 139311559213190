.india-priceing-full-stack.landibgfullstack .banner-left h2, 
.landibgfullstack .banner-left h2 span{
    font-size: 48px;
    line-height: 53px;
}
.india-priceing-full-stack .banner-developer-sec .banner-form h3 {
    font-size: 17px;
}

.india-priceing-full-stack .banner-developer-sec .banner-form h3 span{
    font-size: 14px;
}