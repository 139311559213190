/* --------------------------------------------------------------------- */
.header-part .header_middle{}
.header-part .header_middle ul{display:flex;flex-direction:row;margin-left:0.875vw;margin-right:auto;padding-left:0;margin-bottom:0;list-style:none;}
.header-part .header_middle ul li{margin:0 0.4375vw;}
.header-part .header_middle ul li{padding:10px 20px;letter-spacing:0;}
.header-part .header_middle ul li .menu_items{font-size:15px;font-weight:500;color:#fff; cursor: pointer;}
.header-part .header_middle ul li .menu_items:hover{color:#20c0a0;}
.hair-designer .header-part .header_middle ul li .menu_items:hover{color:#DD6745;}

.youtube-video{
    position: relative;
}
.youtube-video::before{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
}
.videoModal{
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    padding: 50px 15px;
}
.videoModalInside{
    max-width: 700px;
    border-radius: 5px;
    margin: auto;
    position: relative;
    margin-top: 100px;
}
.inline-btn{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 20px;
    height: 20px;
}

.hair-designer .banner-left{color:#fff;width:100%;text-align:center;position:relative;}
/* .hair-designer .banner-left::before{position:absolute;content:'';width:247px;height:302px;top:-45px;background:url(/src/Assets/Images/hiredesigner/banner_left_image.png) no-repeat 0 100%;left:-247px;} */
/* .hair-designer .banner-left::after{position:absolute;content:'';width:250px;height:313px;top:-45px;background:url(/src/Assets/Images/hiredesigner/banner_left_image2.png) no-repeat 0 100%;right:-250px;} */
.hair-designer .banner-section{align-items:center;justify-content:center;}
.hair-designer .banner{background:rgb(52,41,132);background:radial-gradient(circle,rgba(52,41,132,1) 0%,rgba(27,3,52,1) 64%);overflow-x:hidden;}
.hair-designer .banner-left h4{
    color: #FFF;
    font-size: 76px;
    font-weight: 900;
    line-height: 70px;
    letter-spacing: 1.78px;
}
.hair-designer .banner-left h4 span{
    position:relative;
    color: #FF6767;
}
.hair-designer .banner-left h4 span::before{
    position:absolute;
    content:'';
    width: 220px;
    height: 15px;
    bottom: -2px;
    background:url(/src/Assets/Images/hiredesigner/banner_span_bg.png) no-repeat 0 100%;
    left: 15px;
}
.hair-designer .banner-left h4 span.bg  {
    background-color: #F0612E;
    border-radius: 5px;
    padding: 5px;
    display: inline-block;
}

.hair-designer .banner-left h4 span.color  {
    color: #F0612E;
    text-shadow: 1px 0 #fff, -1px 0 #fff, 0 1px #fff, 0 -1px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
}

.hiredesigner_option .banner-feture {
    padding: 40px 0 0px 0;
    max-width: 820px;
    margin: auto;
}

.hiredesigner_option .banner-feture {
    flex-wrap: wrap;
}

.hiredesigner_option .banner-first-feture {
    border-right: none;
    display: flex;
    margin-bottom: 15px;
    padding-right: 20px;
    width: 50%;
}

.hiredesigner_option .banner-second-feture {
    margin-bottom: 15px;
}

.hiredesigner_option .banner-feture p {
    background-image: url(../../Assets/Images/right-arrow.svg);
    background-position: 0 2px;
    background-repeat: no-repeat;
    background-size: 20px;
    color: #55A7F1;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.26px; 
    padding: 2px 0 2px 32px;
}

.hiredesigner_option .banner-feture p .image-text {
    background-color: #f0612e;
    border-radius: 10px 10px 10px 0;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    margin-left: 5px;
    padding: 1px 5px;
}

.hiredesigner_batch  {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: auto;
    max-width: 400px;
}

.hiredesigner_batch .hiredesigner_batchsub  {
    width: 33.333%;
    padding: 0 15px;
}

.hiredesigner_batch .hiredesigner_batchsub img  {
    width: 100%;
}

.hiredesigner_batch .hiredesigner_batchsub p  {
    color: #F5F5F5;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.17px;
    padding: 0;
    max-width: 200px;
    text-align: left;
}

.banner-section .designer_div h6  {
    font-size: 22px;
    padding-top: 36px;
    line-height: 28px;
    font-weight: 400;
    max-width: 1040px;
    margin: auto;
}

.banner-section .designer_div h6 span  {
    font-weight: 600;
    color: #fd7a2e;
}

/* .hair-designer .banner-left p{font-size:17px;line-height:22px;font-weight:400;padding-top:30px;} */
.hair-designer .banner-section{padding:70px 0 20px 0;}
.hair-designer .hair_designer_bannerbtm{display:flex;padding:30px 0;justify-content:center;}
.hair-designer .hair_designer_bannerbtm_box{text-align:center;color:#FFFFFF;padding:0 60px;}
.hair-designer .hair_designer_bannerbtm_box+.hair_designer_bannerbtm_box{border-left:dashed 1px #fff;}
.hair-designer .hair_designer_bannerbtm_box h3{font-size:17px;list-style:25px;font-weight:400;padding-top:18px;}
.hair-designer .hair_designer_bannerbtm_boeder{border-top:1px solid #8F849C;}
.hair-designer .hair-designer-btn{border:solid 1px #50E6C8;padding:17px 44px;border:1px solid #FFF;background:linear-gradient(151deg,#DD6745 0%,rgba(178,84,84,0.00) 100%),#4748C3;display:inline-flex;border-radius:50px;color:#fff;font-size:19px;font-weight:600;text-transform:uppercase;cursor:pointer;transition:0.5s;margin-top:42px;}
.hair-designer .hair-designer-btn:hover{background:#50e6c8;color:#000;}
/* .hair-designer .full-stack-top-btn.fill-btn{background:linear-gradient(151deg,#DD6745 0%,rgba(178,84,84,0.00) 100%),#4748C3;border:2px solid #fff;} */
/* .hair-designer .full-stack-top-btn.fill-btn:hover{background:transparent;color:#000;} */

.hiredesigner_client  {
    border-top: 1px solid #8f849c;
    padding: 23px 0;
}

.hiredesigner_client .banner-location {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 0px;
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hiredesigner_client .banner-location span {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding-top: 5px;
}

.hiredesigner_client .banner-location span img  {
    width: 3%;
}

.banner_featur_width  {
    width: 700px;
    margin: auto;
}

.banner_featur_width .banner-first-feture  {
    border-right: none;
}

.banner_featur_width .banner-second-feture {
    padding-left: 0px;
    width: 50%;
}

/*************************************/

.Cutting_section  {
    position: relative;
}

.Cutting_section::after  {
    content: "";
    width: 85%;
    height: 85%;
    top: 0;
    right: 0;
    background-image: url(../../Assets/Images/hiredesigner/cutting-background.png);
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
}

.Cutting_section .container  {
    display: flex;
}

.Cutting_section .Cutting_divide  {
    width: 50%;
    padding: 120px 15px 90px 15px;
    z-index: 1;
}

.Cutting_section .Cutting_divide .cutting_text h2  {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    line-height: 46px; 
    letter-spacing: 0.48px;
    padding-bottom: 30px;
    max-width: 500px;
}

.Cutting_section .Cutting_divide .cutting_text h2 span  {
    color: #fd7a2e;
}

.Cutting_section .Cutting_divide .cutting_text ul li  {
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.Cutting_section .Cutting_divide .cutting_text ul li span  {
    color: #0764D0;
    font-weight: 700;
}

.Cutting_section .Cutting_divide .cutting_text ul li:not(:last-child)  {
    padding-bottom: 18px;
}

.Cutting_section .Cutting_divide .cutting_text a  {
    color: #FFF;
    font-size: 19px;
    font-weight: 900;
    text-transform: uppercase;
    border-radius: 50px;
    background: linear-gradient(115deg, #DD6745 8.43%, rgba(178, 84, 84, 0.00) 77.51%), #4748C3;
    padding: 17px 49px;
    display: inline-block;
    margin-top: 46px;
}

.Cutting_section .Cutting_divide .cutting_box  {
    display: flex;
    flex-wrap: wrap;
    padding-left: 140px;
}

.Cutting_section .Cutting_divide .cutting_box .cutting_boxsub  {
    width: 50%;
    padding: 10px;
}

.Cutting_section .Cutting_divide .cutting_box .cutting_boxsub .cutting_boxbackground  {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    padding: 20px 15px;
    text-align: center;
    height: 100%;
}

.Cutting_section .Cutting_divide .cutting_box .cutting_boxsub .cutting_boxbackground img  {
    width: 22%;
    margin: auto;
}

.Cutting_section .Cutting_divide .cutting_box .cutting_boxsub .cutting_boxbackground p  {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.2px; 
    padding-top: 10px;
}

/* --------------------------------------------------------------------- */


/***************************************************/

.key_skills .key_skills_flex  {
    display: flex;
}

.key_skills .key_skills_flex .key_skills_divide  {
    width: 50%;
    padding: 15px;
}

.key_skills .key_skills_flex .key_skills_divide .key_skills_image img  {
    width: 100%;
    display: block;
}

.key_skills .key_skills_flex .key_skills_divide .key_skills_text  {
    padding-left: 30px;
}

.key_skills .key_skills_flex .key_skills_divide .key_skills_text h2 {
    color: #000;
    font-size: 40px;
    font-weight: 900;
    line-height: 49px;
    letter-spacing: 0.48px;
    max-width: 580px;
    padding-bottom: 15px;
}

.key_skills .key_skills_flex .key_skills_divide .key_skills_text h2 span  {
    color: #fd7a2e;
}

.key_skills .key_skills_flex .key_skills_divide .key_skills_text h2 span.bg  {
    background-color: #F0612E;
    border-radius: 5px;
    padding: 2px;
    display: inline-block;
    color: #fff;
}

.key_skills .key_skills_flex .key_skills_divide .key_skills_text p  {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.23px;
    padding-left: 24px;
    position: relative;
}

.key_skills .key_skills_flex .key_skills_divide .key_skills_text p:after  {
    content: "";
    width: 8px;
    height: 8px;
    top: 8px;
    left: 0;
    background: #000;
    position: absolute;
    border-radius: 50px;
}

.key_skills .key_skills_flex .key_skills_divide .key_skills_text p:not(:last-child)  {
    padding-bottom: 15px;
}

/***************************************/

.three_section  {
    background-color: #F1F3F4;
    padding-top: 90px;
    margin-top: 90px;
    position: relative;
}

.three_section:before {
    background: url(../../Assets/Images/vector-1.png) no-repeat 0 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 308px;
}


.hair-designer .approach__building{position:relative;padding:90px 0 105px;}

/* .hair-designer .approach__building::before { content: '';  position: absolute; top: -18px; left: 50%; transform: translateX(-50%);
    width: 41px; height: 41px; background-repeat: no-repeat; background-size: cover;
    background-image: url(/src/Assets/Images/hiredesigner/down-cicle-clr.svg);} */
.hair-designer .approach__building .section_title{text-align:center;}
.hair-designer .approach__building .section_title h2{color:#000;font-size:48px;font-family:'Poppins',sans-serif;font-style:normal;font-weight:700;line-height:56px;letter-spacing:-1.24px;text-transform:capitalize;margin-bottom:22px;}
.hair-designer .approach__building .section_title p{color:#000;font-size:20px;margin-bottom:0;font-style:normal;font-weight:300;line-height:30px;letter-spacing:-0.4px;text-transform:capitalize;}
.hair-designer .approach__building .section_title p span{font-weight:800;}
.hair-designer .approach__building .circle_box_cont{margin-top:55px;}
.hair-designer .approach__building .circle_box_cont >*{padding:0;position:relative;transform:scale(1.07);}
.hair-designer .approach__building .circle_box_cont >*::after{content:'';border:1px dashed #000;height:100%;width:100%;left:0;right:0;position:absolute;bottom:0;top:0;border-radius:50%;}
.hair-designer .approach__building .circle_box_cont >*::before{content:'';position:absolute;top:50%;transform:translateY(-50%);width:15px;height:15px;right:-8px;border-radius:50%;background:#000;z-index:10;}
.hair-designer .approach__building .mgk-content{position:relative;padding-top:100%;}
.hair-designer .approach__building .mgk-content::after{content:'';border-bottom:1px solid #000;width:20px;position:absolute;right:0;top:50%;transform:translateY(-50%);}
.hair-designer .approach__building .item-crle{text-align:center;position:absolute;left:0;top:40px;right:0;bottom:0;width:100%;height:100%;border-radius:348px;display:flex;flex-direction:column;padding:25px 55px;}
.hair-designer .approach__building .circle_box_cont [class^="col"]:hover .item-crle{background:#FFFFFF;}
.hair-designer .approach__building .item-crle h3{color:#000;text-align:center;font-size:17px;margin-bottom:24px;padding-top:25px;font-weight:800;line-height:22px;text-transform:capitalize;}
.hair-designer .approach__building .item-crle p{color:#000;text-align:center;font-size:18px;margin-bottom:0;font-weight:300;line-height:27px;letter-spacing:-0.36px;text-transform:capitalize;}
.hair-designer .approach__building .circle_box_cont:last-child:before,.hair-designer .approach__building .circle_box_cont:last-child .mgk-content::after{content:unset;}
.hair-designer .circle_box_cont{display:flex;}
.hair-designer  .circle_box_cont_singlebox{width:25%;}
.hair-designer  .item-crle img{width:70px;height:70px;margin:0 auto;}

/* --------------------------------------------------------------------- */
.hair-designer .girlwith_laptop{position:relative;}
.hair-designer .girlwith_laptop::before{position:absolute;content:'';width:42px;height:42px;top:30px;left:50%;transform:translate(-50%);background:url(/src/Assets/Images/hiredesigner/pagedownarrow.svg) no-repeat 50% 50%;z-index:9;}
.hair-designer .beat{width:375px;border-radius:10px;background-color:#fff;padding:25px 15px;box-shadow:0px 4px 20px 0px rgba(0,0,0,0.18);display:flex;align-items:flex-start;position:absolute;z-index:9;left:50px;left:270px;top:285px;}
.hair-designer .beat_text{padding-left:15px;}
.hair-designer .security h4,.hair-designer .obligation h4,.hair-designer .post_hiring h4{font-size:20px;line-height:24px;color:#000;padding-bottom:8px;}
.hair-designer .beat_text p{font-size:17px;line-height:20px;color:#000;padding:5px 0 0;}
.girl{display:flex;justify-content:center;position:relative;z-index:7;}
.girlwith_laptop_inside{background:url(/src/Assets/Images/hiredesigner/round_bg_gradient.svg) no-repeat 50% 100%;background-size:cover;padding-top:206px;}
.hair-designer .skip{width:230px;border-radius:10px;background-color:#fff;padding:25px 15px;box-shadow:0px 4px 20px 0px rgba(0,0,0,0.18);display:flex;align-items:flex-start;position:absolute;z-index:9;right:304px;top:200px;}
.hair-designer .save h4,.hair-designer .save p{color:#fff;}
.hair-designer .save{width:430px;padding:25px 15px;display:flex;align-items:flex-start;position:absolute;z-index:9;right:114px;top:389px;border-radius:10px;border:4px solid #FFF;background:linear-gradient(151deg,#DD6745 0%,rgba(178,84,84,0.00) 100%),#4748C3;box-shadow:0px 4px 20px 0px rgba(0,0,0,0.18);}
.hair-designer .girlwith_laptop h2{color:#000;text-align:center;font-size:48px;font-family:'Poppins',sans-serif;font-style:normal;font-weight:700;line-height:56px;letter-spacing:-1.24px;text-transform:capitalize;margin-bottom:22px;position:absolute;top:100px;left:50%;transform:translate(-50%);z-index:9;}
.hair-designer .security{width:325px;border-radius:50px;border:1px dashed #000;display:flex;background-color:#fff;align-items:center;justify-content:center;top:500px;position:absolute;padding:10px 0;left:170px;z-index:9;}
.hair-designer .security h4{padding-bottom:0!important;padding-left:8px;}
.hair-designer .security{width:325px;border-radius:50px;border:1px dashed #000;display:flex;background-color:#fff;align-items:center;justify-content:center;top:500px;position:absolute;padding:10px 0;left:170px;z-index:9;}
.hair-designer .security h4,.hair-designer .obligation h4,.hair-designer .post_hiring h4{padding-bottom:0!important;padding-left:8px;}
.hair-designer .obligation{width:330px;border-radius:50px;border:1px dashed #000;display:flex;background-color:#fff;align-items:center;justify-content:center;bottom:100px;position:absolute;padding:10px 0;right:220px;z-index:9;}
.hair-designer .post_hiring{width:455px;border-radius:50px;border:1px dashed #000;display:flex;background-color:#fff;align-items:center;justify-content:center;bottom:45px;position:absolute;padding:10px 0;right:142px;z-index:9;}

/* --------------------------------------------------------------------- */
.hair-designer .ourportfolio{background:rgb(52,41,132);background:radial-gradient(circle,rgba(52,41,132,1) 0%,rgba(27,3,52,1) 64%);padding:80px 0;}
.hair-designer .trusted-section .slick-slide{padding:0 15px;}
.hair-designer .ourportfolio h6{font-size:16px;line-height:24px;font-weight:500;padding-top:10px;color:#fff;}
.hair-designer .ourportfolio h6 span{font-size:15px;line-height:18px;font-weight:300;}
.hair-designer .ourportfolio h2{color:#fff;font-size:50px;line-height:60px;font-family:'Poppins',sans-serif;font-style:normal;font-weight:700;text-transform:capitalize;text-align:center;padding-bottom:70px;}
.hair-designer .trusted-section{padding:0;}
.hair-designer .hire_review{padding:140px 0;position:relative;}
.hair-designer .hire_review::before{position:absolute;content:'';height:1px;width:100%;border-top:dashed 1px #000;top:49%;left:0;right:0;}
.hair-designer .reviewbox{display:flex;align-items:flex-start;}
.hair-designer .single{display:flex;padding-top:12px;}
.hair-designer .reviewbox ul{display:flex;}
.hair-designer .allreview_box h3{font-size:26px;line-height:30px;}
.hair-designer .allreview_box{display:flex;align-items:center;}
.hair-designer .allreview_box_left{width:32%;}
.hair-designer .allreview_box_mdl{width:36%;}
.hair-designer .reviewbox_text{padding-left:30px;flex:1;}
.hair-designer .single h4{font-size:18px;line-height:22px;color:#000;font-weight:600;padding-left:5px;}
.hair-designer .single h4 span{font-weight:400;color:#717171;}
.hair-designer .reviewbox_text p{font-size:16px;line-height:20px;color:#505050;padding-top:20px;font-weight:400;}
.hair-designer .reviewbox ul{padding-top:17px;}
.hair-designer .reviewbox ul li{color:#4E565F;padding:10px 15px;border-radius:50px;cursor:pointer;border:1px solid #9B9999;}
.hair-designer .reviewbox ul li:hover{background-color:#d34043;color:#fff!important;}
.hair-designer .reviewbox ul li:hover button{color:#fff!important;}
.hair-designer .reviewbox ul li+li{margin-left:6px;}
.hair-designer .allreview_box_btn{font-size:16px;color:#1B0334;font-weight:900;border-bottom:1px solid #1B0334;background:transparent;margin-top:17px;cursor:pointer;}
.hair-designer .allreview_box_left .reviewbox+.reviewbox{margin-top:60px;}
.hair-designer .allreview_box_mdl_inside{background-color:#787878;width:480px;height:480px;margin:auto;display:flex;align-items:center;justify-content:center;flex-direction:column;text-align:center;border-radius:50%;padding:30px 40px;background-image:linear-gradient(90deg,white,white),linear-gradient(90deg,hsl(0,66%,55%),hsl(226,85%,37%));background-clip:padding-box,border-box;background-origin:border-box;border:24px solid transparent;}
.hair-designer .allreview_box .allreview_box_mdl_inside h3{font-size:45px;line-height:50px;color:#000;}
.hair-designer .allreview_box .allreview_box_mdl_inside p{font-size:17px;line-height:25px;color:#000;padding-top:14px;}
.hair-designer .allreview_box_mdl_btn{padding:20px 30px;background:linear-gradient(151deg,#DD6745 0%,rgba(178,84,84,0.00) 100%),#4748C3;display:inline-flex;border-radius:50px;color:#fff;font-size:19px;font-weight:600;text-transform:uppercase;cursor:pointer;transition:0.5s;margin-top:25px;}
.hair-designer .videoslider .trusted-section h6{font-size:22px;line-height:25px;color:#000;padding-top:17px;}
.hair-designer .videoslider .trusted-section h6 span{font-size:16px;color:#505050;line-height:18px;font-weight:400;}
.hair-designer .videoslider .trusted-section .slick-slide{padding:0 5px!important;text-align:center;}
.hair-designer .videoslider{background:linear-gradient(156deg,#FFF3F0 0%,rgba(255,243,240,0.00) 64.56%),#F4F4FF;padding:95px 0;}
.hair-designer .videoslider h2{color:#000;text-align:center;font-size:48px;font-family:'Poppins',sans-serif;font-style:normal;font-weight:700;line-height:56px;text-transform:capitalize;margin-bottom:90px;}
.hair-designer .videoslider h2 span{
    color: #F0612E;
}
.hair-designer .reviewbox ul li button{background:transparent;font-size:15px;line-height:16px;cursor:pointer;}
.hair-designer .reviewbox .reviewbox_round_img img{height:130px;width:130px;border-radius:100%;}
.hair-designer .hair-designer-btn+.hair-designer-btn{display:none;}
.hair-designer .black_tick_new{display:none;}
.hair-designer .accordion-item ul li{font-size:15px;line-height:20px;background:url('../../Assets/Images/hiredesigner/beat-tick.svg') no-repeat 0 7px;padding-left:26px;padding-top:6px;}
.accordion-title.active{color:#d34043;}

.allreview_box_mdl_inside p span  {
    font-size: 24px;
    padding-bottom: 0px;
    display: block;
    font-weight: 600;
    line-height: 33px;
}

.hair-designer .allreview_box .allreview_box_mdl_inside p {
    font-size: 20px;
    line-height: 25px;
}

.hair-designer .full-stack-top-btn.fill-btn{
    background: linear-gradient(151deg,#DD6745 0%,rgba(178,84,84,0.00) 100%),#4748C3;
    border-color: #fff;
}
.hair-designer .full-stack-top-btn.fill-btn:hover{
    background: #DD6745;
    border-color: #DD6745;
}

@media (max-width:1420px)  {
    .Cutting_section .Cutting_divide .cutting_box {
        padding-left: 70px;
    }
    .key_skills .key_skills_flex .key_skills_divide .key_skills_text {
        padding-left: 0px;
    }
}


@media (max-width:1366px){
    .hair-designer .skip{right:214px;}
    .hair-designer .save{right:14px;}
    .hair-designer .obligation{right:100px;}
    .hair-designer .post_hiring{right:10px;}
    .hair-designer .security{left:100px;}
    .hair-designer .beat{top:300px;left:230px;}
    .hair-designer .girlwith_laptop_inside{background-size:100%;}
    .hair-designer .ourportfolio h6,.hair-designer .ourportfolio h6 span{font-size:13px;line-height:16px;}
    .hair-designer .allreview_box h3{font-size:20px;line-height:24px;}
    .hair-designer .reviewbox ul li button{font-size:10px;line-height:12px;}
    .hair-designer .reviewbox ul li{padding:4px 12px;}
    .hair-designer .reviewbox .reviewbox_round_img img{height:70px;width:70px;}
    .hair-designer .allreview_box_mdl_inside{height:400px;width:400px;}
    .hair-designer .videoslider h2{margin-bottom:30px;}
    .hair-designer .allreview_box .allreview_box_mdl_inside h3{font-size:25px;line-height:27px;}
    .hair-designer .allreview_box .allreview_box_mdl_inside p{font-size:16px;line-height:20px;}
    .hair-designer .allreview_box_mdl_btn{font-size:14px;padding:15px 25px;}
    .allreview_box_mdl_inside p span {
        font-size: 19px;
        line-height: 27px;
    }
} 
@media (max-width:1280px){
    .hair-designer .hair_designer_bannerbtm_box h3 br{display:none;}
    .hair-designer .approach__building .item-crle h3{font-size:15px;line-height:20px;padding-top:15px;}
    .hair-designer .approach__building .item-crle{top:20px;}
    .hair-designer .girlwith_laptop h2{position:inherit;}
    .hair-designer .girlwith_laptop_inside{padding-top:116px;}
    .hair-designer .beat{left:0;top:200px;}
    .hair-designer .security{left:0;}
    .hair-designer .skip{right:70px;top:150px;}
    .hair-designer .save{right:0;top:359px;}
    .hair-designer .obligation{right:0;}
    .hair-designer .post_hiring{right:0;}
    .hair-designer .ourportfolio h6,.hair-designer .ourportfolio h6 span{font-size:11px;line-height:14px;}
    .hair-designer .allreview_box_mdl_inside{height:350px;width:350px;}
    .hair-designer .reviewbox_text p{font-size:13px;line-height:17px;}
    .hair-designer .hair_designer_bannerbtm_box {
        padding: 0px 20px;
    }
    .hair-designer .banner-left h4 {
        font-size: 54px;
        line-height: 62px;
    }
    .Cutting_section::after {
        width: 100%;
        height: 86%;
    }
    .key_skills .key_skills_flex .key_skills_divide .key_skills_text h2 {
        font-size: 34px;
        padding-bottom: 13px;
    }
    .key_skills .key_skills_flex .key_skills_divide .key_skills_text p:not(:last-child) {
        padding-bottom: 10px;
    }
    
    .key_skills .key_skills_flex .key_skills_divide .key_skills_text p {
        font-size: 15px;
        line-height: 26px;
    }
    .banner-section .designer_div h6 {
        font-size: 18px;
        padding-top: 20px;
        line-height: 28px;
    }
}
@media (max-width:1024px){
    .hair-designer .banner-left::before,.hair-designer .banner-left::after{display:none;}
}
@media (max-width:991px){
    .hair-designer .full-stack-top-btn{
        font-size: 12px;
        padding: 11px 14px;
    }
    .hair-designer .header-part .header_middle ul li {
        padding: 0px;

    }
    .hair-designer .header-part .header_middle ul li .menu_items{
        font-size: 13px;
    }
    .hair-designer .hair_designer_bannerbtm_box{padding:0 15px;}
    .hair-designer .banner-section{padding:70px 0 30px 0;}
    .hair-designer .banner-left h2{font-size:40px;line-height:44px;}
    .hair-designer .circle_box_cont_singlebox{width:50%;}
    .hair-designer .circle_box_cont{flex-wrap:wrap;}
    .hair-designer .circle_box_cont_singlebox:nth-child(2)::before{display:none;}
    .hair-designer .circle_box_cont_singlebox:nth-child(2) .mgk-content::after{display:none;}
    .hair-designer .approach__building .item-crle{top:0;justify-content:center;}
    .hair-designer .approach__building .item-crle h3{font-size:22px;line-height:33px;}
    .hair-designer .approach__building{padding:80px 0;}
    .hair-designer .skip{right:0;top:130px;}
    .hair-designer .ourportfolio h2{padding-bottom:30px;}
    .hair-designer .security h4,.hair-designer .obligation h4,.hair-designer .post_hiring h4{font-size:14px;}
    .hair-designer .post_hiring{width:339px;}
    .hair-designer .obligation{width:250px;}
    .hair-designer .security{width:250px;}
    .hair-designer .beat{width:330px;}
    .hair-designer .allreview_box{flex-wrap:wrap;}
    .hair-designer .allreview_box_left{width:100%;}
    .hair-designer .allreview_box_mdl{width:100%;margin:30px 0;}
    .hair-designer .allreview_box_left{display:flex;}
    .hair-designer .allreview_box_left .reviewbox+.reviewbox{margin-top:0;}
    .hair-designer .hire_review{padding:80px 0;}
    .hair-designer .ourportfolio h6,.hair-designer .ourportfolio h6 span{font-size:14px;line-height:18px;}
    .hair-designer .ourportfolio h6 br{display:none;}
    .hair-designer .approach__building .section_title h2 br{display:none;}
    .hair-designer .beat_text p{font-size:16px;}
    .hair-designer .reviewbox{width:48%;margin-left:1%;margin-right:1%;}
    .hair-designer .videoslider .trusted-section h6 span{font-size:13px;}
    .hair-designer .videoslider .trusted-section h6{font-size:20px;line-height:21px;}
    .hair-designer .banner-left h4 span::before {
        width: 164px;
        bottom: -3px;
        left: 6px;
    }
    .banner-section .designer_div h6 {
        font-size: 16px;
        padding-top: 20px;
        line-height: 26px;
    }
    .hiredesigner_option .banner-feture p {
        font-size: 17px;
        text-align: left;
        line-height: 22px;
    }
    .hiredesigner_option .banner-first-feture {
        margin-bottom: 10px;
        padding-right: 10px;
    }
    .hiredesigner_option .banner-feture {
        padding: 30px 0 0px 0;
    }
    .hair-designer .hair-designer-btn {
        padding: 13px 39px;
        font-size: 18px;
        margin-top: 30px;
    }
    .hiredesigner_batch {
        padding-top: 20px;
    }
    .hiredesigner_client .banner-location {
        flex-wrap: wrap;
    }
    .hiredesigner_client {
        padding: 15px 0;
    }
    .hair-designer .hair_designer_bannerbtm {
        padding: 20px 0;
        justify-content: flex-start;
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .hair-designer .hair_designer_bannerbtm_box {
        width: 50%;
    }
    .Cutting_section .Cutting_divide {
        width: 100%;
        padding: 0px 15px 30px 15px;
    }
    .Cutting_section .container {
        flex-wrap: wrap;
        text-align: center;
    }
    .Cutting_section .Cutting_divide .cutting_text h2 {
        line-height: 42px;
        padding-bottom: 24px;
        margin: auto;
    }
    .Cutting_section .Cutting_divide .cutting_box {
        padding-left: 0px;
    }
    .Cutting_section {
        padding: 80px 0 50px;
    }
    .Cutting_section::after  {
        display: none;
    }
    .key_skills .key_skills_flex {
        flex-wrap: wrap;
    }
    .key_skills .key_skills_flex .key_skills_divide {
        width: 100%;
    }
    .key_skills .key_skills_flex .key_skills_divide .key_skills_image img {
        margin: auto;
    }
    .key_skills .key_skills_flex .key_skills_divide .key_skills_text h2 {
        font-size: 34px;
        padding-bottom: 13px;
        margin: auto;
        text-align: center;
    }
}
@media (max-width:780px){
    .hair-designer .approach__building .section_title h2,.hair-designer .ourportfolio h2,.videoslider h2{font-size:35px;line-height:40px;}
    .hair-designer .videoslider{padding:60px 0;}
}
@media (max-width:767px){
    .hair-designer .beat,.hair-designer .security,.hair-designer .skip,.hair-designer .save,.hair-designer .obligation,.hair-designer .post_hiring{position:relative;top:auto;width:100%;margin-top:20px;bottom:auto;}
    .hair-designer .girlwith_laptop h2{top:inherit;left:inherit;transform:inherit;font-size:35px;line-height:40px;}
    .hair-designer .girlwith_laptop::before{top:-52px;}
    .girl{display:none;}
    .hair-designer .beat,.hair-designer .security,.hair-designer .skip,.hair-designer .save,.hair-designer .obligation,.hair-designer .post_hiring{border-radius:10px;background-color:#fff!important;padding:25px 15px;box-shadow:0px 4px 20px 0px rgba(0,0,0,0.18);display:flex;justify-content:flex-start;border:none;}
    .hair-designer .save{background:#fff;}
    .hair-designer .save h4,.hair-designer .save p{color:#000;}
    .hair-designer .security h4,.hair-designer .obligation h4,.hair-designer .post_hiring h4{padding-left:15px;}
    .hair-designer .black_tick_new{display:block;}
    .white_tick_new{display:none;}
    .hair-designer .girlwith_laptop_inside{padding-bottom:60px;}
    .hair-designer .girlwith_laptop_inside{padding-top:0;}
    .hair-designer .banner-left h4 {
        font-size: 34px;
        line-height: 40px;
    }
    .hiredesigner_option .banner-first-feture {
        margin-bottom: 7px;
        padding-right: 0px;
        width: 100%;
    }
    .hiredesigner_option .banner-feture p {
        font-size: 15px;
        line-height: 21px;
    }
    .hiredesigner_option .banner-second-feture {
        margin-bottom: 7px;
    }
    .key_skills .key_skills_flex .key_skills_divide .key_skills_text p  {
        font-weight: 500;
    }
    .three_section:before {
        background-size: contain;
    }
    .hair-designer .hair_designer_bannerbtm_box{
        width: 50%;
        padding: 15px 10px;
    }
    .Cutting_section {
        padding: 50px 0 20px;
    }
    .Cutting_section .Cutting_divide {
        padding: 0px;
    }
    .Cutting_section .Cutting_divide .cutting_text h2,
    .key_skills .key_skills_flex .key_skills_divide .key_skills_text h2 {
        line-height: 38px;
        font-size: 27px;
    }
    .Cutting_section .Cutting_divide .cutting_text ul li {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        text-align: left;
    }
    .Cutting_section .Cutting_divide .cutting_text ul li:not(:last-child) {
        padding-bottom: 13px;
    }
    .Cutting_section .Cutting_divide .cutting_text a {
        padding: 15px 46px;
        display: inline-block;
        margin-top: 35px;
        margin-bottom: 30px;
    }
    .Cutting_section .Cutting_divide .cutting_box .cutting_boxsub {
        padding: 6px;
    }
    .Cutting_section .Cutting_divide .cutting_box .cutting_boxsub .cutting_boxbackground {
        padding: 15px 10px;
    }
    .Cutting_section .Cutting_divide .cutting_box .cutting_boxsub .cutting_boxbackground img {
        width: 36%;
    }
    .Cutting_section .Cutting_divide .cutting_box .cutting_boxsub .cutting_boxbackground p {
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
    }
    .key_skills .key_skills_flex .key_skills_divide {
        padding: 10px 0;
    }
    .three_section {
        padding-top: 60px;
        margin-top: 60px;
    }
    .section_three h2 {
        font-size: 35px;
        padding-bottom: 15px;
    }
    .banner_featur_width .banner-first-feture  {
        width: 100%;
    }
}
@media (max-width:576px){
    .hair-designer .girlwith_laptop_inside{padding-top:0;}
    .banner-left h2{font-size:30px;line-height:34px;}
    .hair-designer .banner-left h4 span::before{display:none;}
    .hair-designer .banner-section{padding:30px 0;}
    .hair-designer .hair-designer-btn{padding:14px 22px;width:80%;justify-content:center;align-items:center;}
    .hair-designer .full-stack-top-btn.fill-btn{margin-left:5px;}
    .hair-designer .hair_designer_bannerbtm{flex-wrap:wrap;}
    .hair-designer .hair_designer_bannerbtm_box{padding:15px;}
    .hair-designer .approach__building .section_title h2,.hair-designer .ourportfolio h2,.videoslider h2{font-size:25px;line-height:35px;}
    .hair-designer .videoslider h2{font-size:30px;line-height:37px;}
    .hair-designer .circle_box_cont_singlebox{width:100%;}
    .hair-designer .approach__building .circle_box_cont >*::before{display:none;}
    .hair-designer .approach__building .mgk-content::after{display:none;}
    .hair-designer .ourportfolio .slick-next{right:0;}
    .hair-designer .ourportfolio .slick-prev{left:0;}
    .hair-designer .allreview_box_left{flex-wrap:wrap;}
    .hair-designer .allreview_box_left{margin-top:30px}
    .hair-designer .allreview_box_left .reviewbox+.reviewbox{margin-top:30px}
    .hair-designer .videoslider .trusted-section h6,.hair-designer .videoslider .trusted-section h6 span{font-size:15px;line-height:22px;}
    .hair-designer .hire_review{padding:50px 0 70px;}
    .sales-page .container .header-part{padding:20px 0 0;}
    .hair-designer .banner-left h2{font-size:30px;line-height:35px;}
    /* .hair-designer .banner-left p{padding-top:15px;} */
    .hair-designer .hair-designer-btn{margin-top:25px;}
    .hair-designer .hair-designer-btn+.hair-designer-btn{display:block;margin:10px auto 0;}
    .hair-designer .approach__building .circle_box_cont >*{transform:inherit;margin-top:20px;}
    .hair-designer .approach__building .circle_box_cont >*::after{border-radius:20px;}
    .hair-designer .approach__building .circle_box_cont{margin-top:0;}
    .hair-designer .approach__building .mgk-content{padding-top:65%;}
    .hair-designer .approach__building{padding:40px 0 80px;}
    .hair-designer .reviewbox_text{padding-left:15px;}
    .hair-designer .ourportfolio{padding:50px 0;}
    .hair-designer .approach__building .item-crle h3{font-size:17px;line-height:22px;}
    .hair-designer .reviewbox{width:100%;margin-left:0;margin-right:0;}
    .hair-designer .girlwith_laptop h2{font-size:30px;line-height:35px;}
    .hair-designer .hair_designer_bannerbtm_box h3{
        font-size: 14px;
        padding-top: 5px;
    }
    .hair-designer .hair_designer_bannerbtm_box img{
        height: 25px;
    }
}