.banner-bg h1,.pckg-btn{
    line-height:64px;font-weight:500
}
.sales-page.graphics .container .header-part .mail-box{
    line-height: 34px;
    margin-left: 15px;
    cursor: pointer;
    font-weight: 500;
}
.sales-page.graphics .container .header-part .mail-box:hover{
    background-color: #0c72e6;
    color: #fff !important;
}
.graphics .container .second-section.re-make_offer *,.price_new_section *,.sales-page.graphics,body{
    font-family:Montserrat,sans-serif!important
}
.offer_reMake_section,.package-box,.ribbon{overflow:hidden} 
.sales-page.graphics .banner-bg{
    padding:150px 25px 80px;background:#e4f1fb;text-align:center
}
.banner-bg h1{
    font-size:56px;background:#d3071a;background:linear-gradient(to right,#d3071a 0,#0b99ef 50%,#4922c2 100%);background-clip:border-box;-webkit-background-clip:text;-webkit-text-fill-color:transparent;display:table;margin:0 auto;letter-spacing:-2px
}
.banner-bg p{
    font-size:28px;line-height:38px;padding-top:20px;font-family:Mulish,sans-serif
}
.title_colabr h2,.title_colabr p{
    font-family:Montserrat,sans-serif
}
.banner-bg p span{font-weight:900}
body{font-size:14px;}
.header-part::after{content:unset}
.pckg-btn{
    background: #954a6a;
    background: linear-gradient(90deg,#ab235d 0,#7b3861 50%,#0f48a4 100%);
    font-size: 24px;
    padding: 0 48px;
    border-radius: 45px;
    display: inline-block;
    margin-top: 60px;
    color: #fff;
    cursor: pointer;
}
.sales-page.graphics .container .header-part .mail-box:hover{color:#000}
.sales-page.graphics .container .header-part .mail-box:hover span img{filter:inherit}
.sales-page.graphics .container .header-part .mail-box{background-color:#fff;border:none;box-shadow:0 0 8px #e6e6e6} 
.Checkout-sec{background:#e4f1fb;padding-bottom:150px}
.top-minus-space{margin-top:-200px}
.package-box{
    width: 31.333%;
    float: left;
    background-color:#fff;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.15);
    transition:background .3s,border .3s,border-radius .3s,box-shadow .3s;
    text-align: center;
    margin: 0 1%;
    position: relative;
}
.package-box h2 span,.price-text{background:#da6645;-webkit-text-fill-color:transparent}
.price-text{
    padding-top:20px;
}
.package-box h2{font-size:28px;line-height:30px;padding-bottom:30px}
.package-box h2 span{background:linear-gradient(to right,#da6645 0,#0b99ef 50%,#4922c2 100%);background-clip:border-box;-webkit-background-clip:text}
.package-box p{font-size:16px;line-height:20px; padding-top: 15px;}
.price-text{font-size:40px;line-height:52px;background:linear-gradient(to right,#da6645 0,#0b99ef 50%,#4922c2 100%);background-clip:border-box;-webkit-background-clip:text;font-weight:700;display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:center;align-items:center}
.price-text span{font-size:20px;font-weight:400}
.elementor-divider{margin-top:42px;padding-bottom:22px;border-top:1px solid #ccc}
.package-list li{font-size:18px;line-height:24px;padding:0 0 10px 30px;background-image:url('../../Assets/Images/sales-check.png');background-position:0 10px;background-repeat:no-repeat;background-size:15px;text-align:left}
.package-list h3{font-size:30px;line-height:35px;text-align:left;padding:20px 0 0}
.mst,.ribbon span,.talk-btn,.title_colabr{text-align:center}
.talk-btn{background:#f75a1c;border:1px solid #f75a1c;border-radius:60px;color:#fff;display:block;font-size:18px;font-weight:400;letter-spacing:.3px;letter-spacing:1px;margin:20px 15px 0 0;padding:14px 40px;text-transform:uppercase}
.talk-btn:hover{background-color:#0c72e6;border-color:#0c72e6}
.mst{margin-bottom:20px;animation:1.3s linear infinite MoveUpDown;position:absolute;left:50%;top:0;transform:translateX(-50%)}
.popular-pckg{position:relative;padding-top:100px}
@keyframes MoveUpDown{
    0%,100%{top:0}
    50%{top:10px;opacity:.5}
}
.price-text del{padding-right:5px;-webkit-text-fill-color:red}
.extra-space{margin-top:100px!important}
.sales-page.graphics .logos .slick-initialized .slick-slide>div{border:1px solid #dbdbdb;border-radius:5px}
.ribbon{width:150px;height:150px;position:absolute}
.ribbon::after,.ribbon::before{position:absolute;z-index:-1;content:'';display:block;border:5px solid #2980b9}
.ribbon span{position:absolute;display:block;width:225px;padding:10px 0;background-color:#f75a1c;box-shadow:0 5px 10px rgba(0,0,0,.1);color:#fff;font-size:14px;text-transform:uppercase;font-weight:700}
.ribbon-top-left{top:-18px;left:-18px}
.ribbon-top-left::after,.ribbon-top-left::before{border-top-color:transparent;border-left-color:transparent}
.ribbon-top-left::before{top:0;right:0}
.ribbon-top-left::after{bottom:0;left:0}
.ribbon-top-left span{right:-25px;top:38px;transform:rotate(-45deg)}
.sales-page.graphics .container .second-section .gradeint-box,.sales-page.graphics .container .second-section .offer-box{position:relative;z-index:9}
.sales-page.graphics .gradeint-box::before{position:absolute;right:0;width:200vh;height:100%;top:0;bottom:0;background:#d72e36;content:"";z-index:-1}
.sales-page.graphics .container .second-section .offer-box::before{position:absolute;left:0;width:200vh;height:100%;top:0;bottom:0;background:#000;content:"";z-index:-1}
.sales-page .container .slider-section .slider-image img{width:auto!important;max-width:100%;height:auto;vertical-align:middle;border-radius:5px}
.slick-slide{padding:0 10px}
.package-list ul.deactive-list li{background:0 0;padding-left:0;color:#c6c6c6}
.package-list ul.deactive-list li svg{margin-right:14px}
.package-list ul.deactive-list{padding-top:0}
.header_right{display:flex}

.header_right button+button span{color:#fff} 
.mb-dspn{display:none}
.slick-dots{
    bottom: -40px;
}
.sales-page .container .slider-section .slick-dots li{
    background-color: #dcdcdc;
}
@media (max-width:1200px){
    .package-box h2{font-size:28px;line-height:33px}
}
@media (max-width:1024px){
    .banner-bg h1{font-size:60px;line-height:65px}
    .banner-bg p{font-size:30px;line-height:35px}
    .package-box{width:98%;float:none;margin-top:20px} 
.top-minus-space{margin-top:-156px}
}


@media (max-width:740px){
    .banner-bg h1{font-size:27px;line-height:34px}
    .banner-bg p{font-size:17px;line-height:23px}
    .banner-bg p br{
        display: none;
    }
    .mb-dspn{display:none}
    .mst.mb-dspb{position:inherit;display:table;left:inherit;top:inherit;margin:48px auto 0;text-align:center;transform:none} 
.price_new_section .price__title{margin-bottom:20px}
}
.title_colabr{padding:60px 0}
.title_colabr h2{font-size:64px;line-height:normal;color:#000;font-weight:800;margin-bottom:7px;}
.title_colabr p{font-size:36px;line-height:30px;color:#000;font-weight:400} 
.sales-page .slider-section .inside_title{display:flex;align-items:center} 
.sales-page .slider-section .inside_title::after{
    content:'';
    flex-grow:1;
    border-bottom:1px solid #a7a7a7;
    display:block;
    width:10%;
    margin-left:10px;
    visibility:visible
} 
.sales-page .slider-section .inside_title h3{text-align:left;flex-shrink:0} 
.graphics .container .second-section.re-make_offer .offer_thmbl{position:absolute;top:260px;left:50%;transform:translateX(-50%);width:170px;z-index:99} 
.graphics .header-top{position:absolute;left:0;right:0;width:100%;top:0} 
.sales-page.graphics .container .second-section .gradeint-box,.sales-page.graphics .container .second-section .offer-box{max-width:50%;width:auto;flex:0 0 50%} 
.graphics .container.offer_reMake_section{padding:0} 
.graphics .container .second-section .gradeint-box{display:flex;align-items:center;justify-content:center;background:#d72e36!important} 
.graphics .container .second-section.re-make_offer .gradeint-box .ttx{max-width:586px;margin:auto} 
.graphics .container .second-section.re-make_offer .gradeint-box h3{
    font-size:50px;
    letter-spacing:1px;
    line-height:96px;
    color:#fff;
    font-weight:400;
    text-align:center;
    max-width:440px;
    margin:auto
} 

.graphics .container .second-section.re-make_offer .gradeint-box h4{
    font-size:60px;
    letter-spacing:1px;
    line-height:75px;
    color:#fff;
    font-weight:500;
    text-align:center;
    margin:auto;
    padding-right: 40px;
} 
.graphics .container .second-section.re-make_offer .gradeint-box h4 span{
    display: block;
    border: dashed 2px rgba(255, 255, 255, 0.5);
    padding: 5px 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.graphics .container .second-section.re-make_offer .gradeint-box h3 span{text-align:center;display:block; line-height:normal;} 
.graphics .container .second-section.re-make_offer .gradeint-box h2{font-size:80px;letter-spacing:-1px;line-height:normal;text-transform:uppercase;color:#fff;font-weight:700;padding:0;border:none;text-align:center;margin:26px 0 40px} 
.graphics .container .second-section.re-make_offer .gradeint-box p{font-size:23px;letter-spacing:1px;line-height:34px;color:#fff;font-weight:300;text-align:center;padding:0;max-width:initial} 
.graphics .container .second-section.re-make_offer .gradeint-box p span{background:#c11825;padding:5px 12px;margin-top:5px;display:inline-block} 
.graphics .container .second-section.re-make_offer .offer-box{background-color:#000;border:none} 
.graphics .container .second-section.re-make_offer .offer-box h3{font-size:127px;letter-spacing:-1px;line-height:normal;color:#fff;font-weight:700} 
.graphics .container .second-section.re-make_offer .offer-box .top_ttle{display:flex;align-items:center;justify-content:center} 
.graphics .container .second-section.re-make_offer .offer-box .top_ttle span{font-size:40px;letter-spacing:0;line-height:48px;color:#c83733;font-weight:700;background-color:#fff;width:112px;height:112px;text-align:center;display:inline-flex;align-items:center;justify-content:center;border-radius:50%;text-transform:uppercase;margin-left:14px} 
.graphics .container .second-section.re-make_offer .offer-box h6{font-size:36px;letter-spacing:1px;line-height:48px;color:#fff;font-weight:700} 
.graphics .container .second-section.re-make_offer .btn-outline-blue{font-size:26px;line-height:30px;color:#fff;font-weight:500!important;background:linear-gradient(90deg,#60439a 0,#c53244 100%);border-radius:50px;border:none;padding:18px 44px;margin-top:30px} 
.graphics .container .second-section.re-make_offer .offer-box .time-count-box p{font-size:21px;letter-spacing:4px;line-height:30px;color:#fff;font-weight:250;padding-bottom:0} 
.graphics .container .second-section.re-make_offer .offer-box .time-count-box .time-count h3{font-size:26px;letter-spacing:5px;line-height:normal;color:#fff;font-weight:300;align-items:center;padding-top:0} 
.graphics .container .second-section.re-make_offer .offer-box .time-count{margin-top:-8px} 
.graphics .container .second-section.re-make_offer .offer-box .time-count-box .time-count h3>*{margin-top:36px} 
.graphics .container .second-section.re-make_offer .offer-box .time-count-box .time-count h3 span{font-size:26px;letter-spacing:5px;line-height:30px;color:#fff;font-weight:250} 
.graphics .container .second-section.re-make_offer .offer-box .time-count-box .time-count h3 span .text{font-size:18px;letter-spacing:2px;line-height:30px;color:#c83733;font-weight:500} 
.price_new_section{
    margin-top:130px;
}
.price_new_section.bg-gray{
    margin-top:80px;
    background-color: #f1f1f1;
    padding: 100px 0;
}
.price_new_section.trust-logo {
    background-color: #fff;
    margin-top: 0;
    padding: 50px 0;
    display: block;
}

.price_new_section .price__title{margin-bottom:90px} 
.price_new_section .price__title h2{
    max-width: 1000px;
    margin: 0 auto;
}
.graphics .testmonial-section.testmonial-section_neo .testmonial-top h4,.price_new_section .price__title h2{font-size:72px;letter-spacing:-2px;line-height:normal;color:#000;font-weight:600;text-align:center} 
.graphics .testmonial-section.testmonial-section_neo .testmonial-top h4 span,.price_new_section .price__title h2 span{background:#121fcf;background:linear-gradient(to right,#121fcf 0,#cf1512 100%);-webkit-background-clip:text;-webkit-text-fill-color:transparent;font-weight:700} 
.price_new_section .packages{display:flex;flex-wrap:wrap} 
.price_new_section .popular-pckg .package-box{
    position:relative;
    box-shadow:none;
    display:flex;
    flex-direction:column
} 
.price_new_section .popular-pckg .package-box h2{
    font-size:35px; 
    line-height:43px; 
    font-weight:800; 
    text-align:center; 
    color: #fff; 
    text-transform: uppercase;
    padding: 20px 10px; 
    background-color: #b93a2d;
} 

.price_new_section .popular-pckg .package-box.second_package-box h2{
    background-color: #a609b1;
}

.price_new_section .popular-pckg .package-box.third_package-box h2{
    background-color: #5494ea;
}

.price_new_section .popular-pckg .package-box .price-text{flex-wrap:wrap;font-weight:500;font-size:72px;background:unset;padding:8px 0 0} 
.price_new_section .popular-pckg .package-box .price-text del{width:100%;display:block;font-size:26px;color:#858585;font-weight:500;text-align:center;margin-bottom:6px;-webkit-text-fill-color:initial} 
.price_new_section .popular-pckg .package-box .price-text span{font-size:24px;font-weight:500;text-align:center} 
.price_new_section .popular-pckg .package-box .package-list{margin-top:20px} 
.price_new_section .popular-pckg .package-box .package-list h3{
    font-size:18px;
    letter-spacing:1px;
    line-height:25px;
    text-transform:uppercase;
    color:#fff;
    font-weight:500;
    padding:7px 30px;
    margin:20px 0;
    background-color: #b93a2d;
} 

.price_new_section .popular-pckg .package-box.second_package-box .package-list h3{
    background-color: #a609b1;
}
.price_new_section .popular-pckg .package-box.third_package-box .package-list h3{
    background-color: #5494ea;
}
.package-list ul{
    padding: 0 30px;
}

.price_new_section .popular-pckg .package-box .package-list h3.features-text,
.price_new_section .popular-pckg .package-box.second_package-box .package-list h3.features-text,
.price_new_section .popular-pckg .package-box.third_package-box .package-list h3.features-text{
    background-color: #e8e8e8;
    color: #5a5a5a;
}
.price_new_section .popular-pckg .package-box .package-list li{font-size:14px;letter-spacing:.5px;color:#5a5a5a;font-weight:500;line-height:30px;background-repeat-x:no-repeat;background-image:url(../../Assets/Images/sales-check_green.png)} 
.price_new_section .popular-pckg .package-box .package-list .deactive-list li{background-repeat-y:no-repeat;background-image:unset;color:#c6c6c6} 
.price_new_section .popular-pckg .package-box .package-list .deactive-list li svg,.price_new_section .popular-pckg .package-box .package-list .deactive-list li svg path{fill:#C73331} 
.price_new_section .popular-pckg .package-box .btm-grp{margin-top:auto;display:flex;align-items:center;padding-top:26px} 
.price_new_section .popular-pckg .package-box .btm-grp form{
    width: 100%;
    padding: 0 30px 30px;
}
.price_new_section .popular-pckg .package-box .btm-grp .talk-btn{
    font-size:20px;
    letter-spacing:0;
    line-height:30px;
    color:#fff;
    font-weight:700;
    text-align:center;
    margin:0;
    border:none;
    width: 100%;
    padding:14px 38px 11px;
    cursor: pointer;
    transition: 0.5s;
    opacity: 1;
} 
.price_new_section .popular-pckg .package-box .btm-grp .talk-btn:hover{
    opacity: 0.8;
}
.price_new_section .popular-pckg .package-box .btm-grp .talk-btn.outline{
    margin-top: 15px;
    color: #c73331;
    background-color: transparent;
    border: solid 2px #c73331;
    opacity: 1;
}
.price_new_section .popular-pckg .package-box .btm-grp .talk-btn.outline:hover{
    color: #fff;
    background-color: #c73331;
}
.price_new_section .popular-pckg .package-box .btm-grp .org-page{font-size:15px;letter-spacing:1px;line-height:30px;color:#000;font-weight:700;display:inline-block;text-align:center;text-decoration:underline;margin:auto} 

.price_new_section .popular-pckg .package-box .price-text, .price_new_section .popular-pckg .package-box h2 span{
    -webkit-text-fill-color:unset;
} 
.price_new_section .popular-pckg .first_package-box .btm-grp .talk-btn{
    background-color:#c73331;
} 
.price_new_section .popular-pckg .first_package-box .price-text{
    color:#b93a2d;
} 
.price_new_section .popular-pckg .second_package-box .btm-grp .talk-btn{
    background-color:#a609b1;
}
.price_new_section .popular-pckg .package-box.second_package-box .btm-grp .talk-btn.outline{
    color: #a609b1;
    border: solid 2px #a609b1;
}
.price_new_section .popular-pckg .package-box.second_package-box .btm-grp .talk-btn.outline:hover{
    background-color: #a609b1;
    color: #fff;
}

.price_new_section .popular-pckg .second_package-box .price-text{
    color:#a609b1
}

.price_new_section .popular-pckg .third_package-box .btm-grp .talk-btn{
    background-color:#5494ea;
}
.price_new_section .popular-pckg .third_package-box .price-text{
    color:#5494ea
}

.price_new_section .popular-pckg .package-box.third_package-box .btm-grp .talk-btn.outline{
    border: solid 2px #5494ea;
    color: #5494ea;
}
.price_new_section .popular-pckg .package-box.third_package-box .btm-grp .talk-btn.outline:hover{
    background-color: #5494ea;
    color: #fff;
}

.price_new_section .popular-pckg .third_package-box .btm-grp .talk-btn,.price_new_section .popular-pckg .third_package-box::before{background-color:#5494ea}
.price_new_section .popular-pckg .third_package-box .price-text{color:#5494ea}
.price_new_section .popular-pckg .save-text{
    display: block;
    padding-top: 8px;
    color: #5a5a5a;
}
.testmonial-section_neo .top_head{text-align:center}
.testmonial-section_neo .top_head img{margin:auto auto 30px;display:block;width:58px}
.testmonial-section_neo .top_head h3{
    font-size:24px;letter-spacing:0;line-height:51px;color:#000;font-weight:400;font-family:'Patua One',cursive;font-style:italic
}
.sales-page.graphics .testmonial-section .testmonial-grid .testmonial-box{background:#fff;border-radius:35px;border:1px solid #dadada;box-shadow:0 0 20px rgba(0,0,0,.1);padding:50px}
.graphics .testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box .des{font-size:19px;letter-spacing:0;line-height:35px;color:#000;text-align:center;font-family:Mulish,sans-serif} 
.graphics .testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box .testmonial-user .testmonial-user-image{width:100px;height:100px} 
.graphics .testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box .testmonial-user .testmonial-user-details>*{flex-wrap:wrap;align-items:center;}
.graphics .testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box .testmonial-user .testmonial-user-details h4{font-size:21px;letter-spacing:0;line-height:35px;color:#2c65c1;font-weight:700;padding:3px 10px 3px 3px;font-family:Mulish,sans-serif}
.graphics .testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box .testmonial-user .testmonial-user-details h6{font-size:18px;letter-spacing:0;line-height:normal;color:#000;padding:3px;font-family:Mulish,sans-serif}
.graphics.testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box{border:1px solid #dadada;background:#fff;box-shadow:2px 4px 20px #48484838;border-radius:50px}
.graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box{display:flex;align-items:center;margin-bottom:138px}
.graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-image{width:280px;height:280px;flex-shrink:0;border-radius:50%;overflow:hidden;margin-top:78px}
.graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-image img{width:100%;height:100%;object-fit:cover}
.graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details{padding:0 40px 0 80px}
.graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .des{font-size:32px;letter-spacing:0;line-height:50px;color:#000;text-align:center;font-family:Mulish,sans-serif;font-style:italic}
.sales-page.graphics .testmonial-section{padding-bottom:0}
.graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .top_head{margin-bottom:30px}
.graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .top_head h3{font-size:50px;letter-spacing:0;color:#000;font-weight:400;font-style:italic}
.sales-page .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .top_head img{max-width:100%}
.graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .profn_dtl{display:flex;align-items:center;justify-content:center;margin-top:30px}
.graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .profn_dtl h4{font-size:24px;letter-spacing:0;line-height:51px;color:#2c65c1;font-weight:700;padding:3px}
.graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .profn_dtl h6{font-size:21px;letter-spacing:0;line-height:51px;color:#000;padding:3px 3px 3px 20px}
.graphics .testmonial-section.testmonial-section_neo .testmonial-top{margin-bottom:56px}
.graphics .testmonial-section.testmonial-section_neo{padding-top:100px}
.graphics .testmonial-section.testmonial-section_neo.box-testi{
    padding:0 0 90px;
    background:url(../../Assets/Images/grdnt.jpg) center repeat-x
}
.graphics .testmonial-section .testmonial-grid .testmonial-box .testmonial-user{
    margin-top:30px
}

.unlimited-graphic-design-packages .header_right{
    align-items: normal;
}
.unlimited-graphic-design-packages .header-part .header-gradeint-outline{
    background-color: #0368b5;
    border-radius: 5px;
    padding: 8px 15px;
    display: inline-block;
    line-height: 25px;
    transition: 0.5s;
    margin-left: 5px;
    font-weight: 600;
}
.unlimited-graphic-design-packages .header-part .header-gradeint-outline:hover{
    background-color: #d3071a;
}

.free-trial-box{
    background-image: url('../../Assets/Images/bg-free-trial.jpg');
    background-size: cover;
    border-radius: 10px;
    padding: 50px;
    margin: 80px 15px 0;
    display: flex;
    color: #fff;
    position: relative;
    align-items: flex-end;
    background-position: center;
}
.free-trial-box:before{
    position: absolute;
    top: 40%;
    left: 38%;
    width: 100px;
    content: "";
    height: 100px;
    background-image: url('../../Assets/Images/free-trial-arrow.png');
    background-repeat: no-repeat;
    background-position:center;
    background-size: 100%;
}
.free-trial-box ul{
    display: flex;
    flex-wrap: wrap;
    padding-left: 66px;
}
.free-trial-box ul li{
    width: 50%;
    padding: 10px 20px 10px 30px;
    font-size: 16px;
    position: relative;
}
.free-trial-box ul li:before{
    content: "";
    width: 10px;
    height: 5px;
    border-bottom: solid 2px #fff;
    border-left: solid 2px #fff;
    transform: rotate(-45deg);
    position: absolute;
    top: 15px;
    left: 0;
}
.free-trial-left-box{
    width: 35%;
    text-align: center;
    
}
.free-trial-right-box{
    padding-left: 100px;
    flex: 1;
}
.free-trial-box p{
    font-size: 18px;
    line-height: 28px;
    padding-top: 10px;
}
.free-trial-box h6{
    font-size: 22px;
    background-color: rgba(0,0,0,.3);
    display: inline-block;
    padding: 8px 30px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 15px;
}
.free-trial-box h2{
    font-size: 60px;
    text-shadow: 0 2px 1px rgba(0,0,0,0.8);
}
.free-trial-box button{
    color: #f3ffa3;
    text-decoration: underline;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
    margin-top: 25px;
    cursor: pointer;
}
.free-trial-box .btn{
    color: #692dbe;
    display: block;
    background-color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-top: 25px;
    padding: 10px 30px;
    border-radius: 20px;
    margin: 30px auto 0;
    cursor: pointer;
}
.free-trial-box .btn:hover{
    background-color: #f3ffa3;
}


.Guideline-modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0,0,0,.5);
    padding: 50px 30px;
    min-height: 100vh;
    overflow-y: scroll;
}

.Guideline-modal .inline-btn {
    align-items: center;
    background-color: initial;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 25px;
    height: 25px;
}

.Guideline-modal-box{
    max-width: 600px;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    margin: 0 auto;
}
.Guideline-modal-inside{
    padding: 30px;
    height: calc(100vh - 100px);
    overflow-y: scroll;
}
/* .Guideline-modal-box .inline-btn{
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    cursor: pointer;
} */

.Guideline-modal-box h2{
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: solid 1px #e1e1e1;
}
.Guideline-modal-box p{
    font-size: 15px;
    line-height: 22px;
    padding-bottom: 15px;
    color: #5a5a5a;
    font-weight: 500;
}

.Guideline-modal-box h6{
    font-size: 14px;
    font-weight: 600;
    padding-top: 10px;
}
.Guideline-modal-box h5{
    font-size: 18px;
    font-weight: 400;
    padding-top: 15px;
    text-align: center;

}

.list-items{
    padding-top: 10px;
}
.list-items li{
    font-size:15px;
    color:#5a5a5a;
    font-weight:500;
    line-height:20px;
    padding: 5px 0 5px 20px;
    background-repeat-x:no-repeat;
    background-image:url('../../Assets/Images/sales-check_green.png');
    background-repeat: no-repeat;
    background-position: 0 10px;
    background-size: 13px;
}

#style-1::-webkit-scrollbar-track{
	border-radius: 5px;
	background-color: #fff;
}

#style-1::-webkit-scrollbar{
	width: 6px;
	background-color: #fff;
}

#style-1::-webkit-scrollbar-thumb{
	border-radius: 4px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #ccc;
}



@media (max-width:1440px){
    .price_new_section .popular-pckg .package-box h2{font-size:27px;line-height:29px}
    .price_new_section .popular-pckg .package-box p{font-size:18px;line-height:20px}
    .price_new_section .popular-pckg .package-box .price-text{font-size:50px}
    .price_new_section .popular-pckg .package-box .btm-grp .talk-btn{font-size:18px;letter-spacing:0;line-height:30px;color:#fff;font-weight:700;text-align:center;margin:0;border:none;padding:10px 30px}
    .price_new_section .popular-pckg .package-box .package-list li{font-size:14px}
    .free-trial-box h2{
        font-size: 50px;
    }
}
@media (max-width:1366px){
    .free-trial-box h2{
        font-size: 40px;
    }
    .free-trial-box ul{
        padding-left: 30px;
    }
    .free-trial-box ul li{
        padding: 10px 15px 10px 19px;
        font-size: 16px;
    }
    .graphics .container .second-section.re-make_offer .gradeint-box h4{
        font-size: 50px;
    }
}
@media (max-width:1240px){
    .graphics .container .second-section.re-make_offer .offer-box h6{
        font-size: 25px;
    }
    .graphics .container .second-section.re-make_offer .offer-box h3{
        font-size: 100px;
    }
    .graphics .container .second-section.re-make_offer .offer-box .top_ttle span{
        font-size: 25px;
        width: 80px;
        height: 80px;
    }
}

@media (max-width:1024px){
    .graphics .container .second-section.re-make_offer .offer_thmbl{
        position:absolute;top:14px;left:inherit;transform:none;width:151px;margin:0 auto -48px;right:0
    }
    .extra-space{margin-top:10px!important}
    .free-trial-box{
        display: block;
    }
    .free-trial-left-box{
        width: 100%;
    }
    .free-trial-box:before{
        display: none;
    }
    .free-trial-right-box{
        padding-left: 0;
    }
    .free-trial-box ul{
        padding-left: 100px;
        padding-top: 50px;
    }
    .graphics .container .second-section.re-make_offer .gradeint-box h4 {
        font-size: 37px;
        line-height: 50px;
    }
}

@media (max-width:991px){
    .unlimited-graphic-design-packages .header-part .header-gradeint-outline, .unlimited-graphic-design-packages .header-part .header-gradeint-btn{
        padding: 8px 15px;
        font-size: 12px;
        line-height: 15px;
    }
    
}

@media (max-width:768px){
    .sales-page.graphics .container .second-section .gradeint-box{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .top-minus-space {
        margin-top: -100px;
    }
    .banner-bg h1 br{
        display: none;
    }
    .unlimited-graphic-design-packages .testmonial-section.testmonial-section_neo .testmonial-lg-box{
        flex-direction: column;
    }
    .graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-image{
        width: 200px;
        height: 200px;
    }
    .graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .top_head h3{
        font-size: 30px;
    }
}


@media (max-width:740px){
    .price_new_section.trust-logo{
        padding: 20px 0 0;
    }
    .price_new_section.bg-gray{
        padding-top: 50px;
    }
    .price_new_section .price__title h2{font-size:32px;line-height:38px}
    .price_new_section{margin-top:30px}
    .graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box{flex-wrap:wrap;margin-bottom:0}
    .sales-page .testmonial-section.testmonial-section_neo .testmonial-top h4{font-size:30px;line-height:34px}
    .sales-page .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-image{margin:0 auto 15px}
    .sales-page .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details{padding:10px}
    .sales-page .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .des{
        font-size:20px;letter-spacing:0;line-height:33px
    }
    .sales-page .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .profn_dtl{
        flex-wrap:wrap
    }
    .sales-page .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .profn_dtl h6{
        line-height:21px
    }
    .sales-page .testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box .testmonial-user .testmonial-user-image{
        margin:0 auto
    }
    .sales-page .testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box .testmonial-user .testmonial-user-details h6{
        text-align:center
    }
    .sales-page .testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box .testmonial-user .testmonial-user-details>.sales-page2 .testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box .testmonial-user .testmonial-user-details>*{
        justify-content:center
    }
    .sales-page .testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box .des{font-size:17px}
    .sales-page.graphics .container .second-section .gradeint-box,.sales-page.graphics .container .second-section .offer-box{
        max-width:100%;width:auto;flex:0 0 100%
    }
    .graphics .testmonial-section.testmonial-section_neo{padding-top:80px}
    .sales-page.graphics .container .second-section .offer-box::before,.sales-page.graphics .gradeint-box::before{content:unset}
    .sales-page.graphics .banner-bg{padding-top:120px}
    .banner-bg h1{font-size:25px}
    .price_new_section .price__title{margin-bottom:20px}
    .title_colabr h2{font-size:30px;line-height:37px}
    .free-trial-box ul{
        padding-left: 0;
    }
    
}
@media (max-width:575px){
    .sales-page .container .header-part{flex-wrap:wrap;}
    .header_right{margin-top:10px}
    .package-box h2{font-size:24px;line-height:28px}
    .banner-bg h1,.price_new_section .popular-pckg .package-box h2,.title_colabr p{font-size:25px}
    .package-list li{font-size:16px}
    .banner-bg{padding:50px 15px 100px}
    .sales-page .container .second-section .gradeint-box h2{font-size:34px}
    .popular-pckg{padding-top:50px}
    .pckg-btn {
        font-size: 15px;
        line-height: 38px;
        padding: 0 34px;
    }
    .free-trial-box{
        padding: 40px 15px;
    }
    .top-minus-space{margin-top:-100px}
    .slider-sec-total{padding:20px 0}
    .title_colabr h2{font-size:44px}
    .sales-page .container .second-section.re-make_offer .gradeint-box h3{
        font-size:30px
    }
    .sales-page .container .second-section.re-make_offer .gradeint-box h2{
        font-size:42px
    }
    .sales-page .container .second-section.re-make_offer .offer-box h3{
        font-size:60px
    }
    .sales-page .container .second-section.re-make_offer .offer-box h6{
        font-size:20px
    }
    .sales-page .container .second-section.re-make_offer .btn-outline-blue{
        font-size:19px
    }
    .sales-page .container .second-section.re-make_offer .offer_thmbl{
        position:inherit;
        top:inherit;
        left:inherit;
        transform:none;
        width:151px;
        margin:0 auto -48px;
        right:0
    }
    .sales-page .container .second-section.re-make_offer .gradeint-box p{
        font-size:18px;letter-spacing:1px;line-height:23px
    }
    .graphics .testmonial-section.testmonial-section_neo .testmonial-lg-box .testmonial-user-details .top_head h3{
        line-height:30px;font-size:28px
    }
    .sales-page.graphics .testmonial-section .testmonial-grid .testmonial-box{
        padding:20px
    }
    .graphics .testmonial-section.testmonial-section_neo .testmonial-grid .testmonial-box .testmonial-user .testmonial-user-details>*{
        justify-content:center
    }
    .testmonial-section_neo .top_head h3{
        font-size:24px
    }

    .unlimited-graphic-design-packages .header-part .header-gradeint-outline.mobile-none{
        display: none;
    }

    
}