.hair-duda .text-center{
    text-align: center;
}
.hair-duda .d-flex-wap{
    display: flex;
    flex-wrap: wrap;
}
.hair-duda .banner{
    padding-bottom: 30px;
    position: relative;
    background: #160334;
    background-image: url('../../Assets/Images/dudadesigner/banner-duda.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.hair-duda .banner .header-part .mail__info {
    color: #FFF;
    font-size: 19px;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    margin-right: 30px;
}
.hair-duda .banner .header-part .full-stack-top-btn {
    border: 2px solid #FFF;
}
.hair-duda .banner .header-part .full-stack-top-btn:hover{
    border-color: #DB5400;
}

.hair-duda .banner .header-part .full-stack-top-btn.fill-btn{
    border-color: #20c0a0;
    background-color: #20c0a0;
}
.hair-duda .banner .header-part .full-stack-top-btn.fill-btn:hover{
    border-color: #DB5400;
    background-color: #DB5400;
}
.hair-duda .banner .banner-section p{
    color: #FFF;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.87px;
}

.hair-duda .banner .banner-section h5{
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    max-width: 1000px;
    margin: 0 auto;
}
.hair-duda .banner .banner-section h5 span{
    color: #DB5400;
    font-weight: 600;
    
}
.hair-duda .banner .banner-section .banner-feture p{
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}
.hair-duda .banner_featur_width{
    max-width: 800px;
    width: 100%;
    padding-left: 100px;
}

.hair-duda .banner .banner-section h2{
    color: #FFF;
    font-size: 75px;
    font-weight: 600;
    line-height: 80px;
    margin:17px auto 40px;
    max-width: 1000px;
}
.hair-duda .banner .banner-section h2 span{
    background-color: #DB5400;
    border-radius: 5px;
    padding: 0 5px;
}
.hair-duda .banner .banner-section .duda-designer-btn{
    border-radius: 50px;
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 16px 23px;
    cursor: pointer;
    margin: 10px 0 20px;
    border: 1px solid #000;
    background: #DB5400;
    transition: 0.5s;
}
.hair-duda .banner .banner-section .duda-designer-btn:hover{
    background-color: #20c0a0;
}
.hair-duda .banner .banner-section{
    display: block;
    padding: 60px 0 20px;
}
.hair-duda .hiredesigner_batch{
    padding-top: 30px;
}
.hair-duda .hiredesigner_client{
    border-top: solid 1px rgba(255, 255, 255, 0.2);
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    padding: 15px 0;
}
.hair-duda .banner .reating h5{
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}
.hair-duda .banner .reating ul{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 5px 0 10px;
}
.hair-duda .banner .reating ul li+li{
    margin-left: 4px;
}
.hair-duda .banner .reating p{
    color: #FFF;
    font-size: 19px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.19px;
}
.hair-duda .banner .reating span{
    font-weight: 600;
}
.hair-duda .vector-shap-content{
    position: relative;
}
.hair-duda .banner .vector-shap {
    width: 178px;
    margin-left: auto;
    position: absolute;
    z-index: 9;
    right: 66px;
    top: -177px;
}

.hair-duda .hair_designer_bannerbtm{
    display: flex;
    padding: 30px 0;
    justify-content: center;
}
.hair-duda .hair_designer_bannerbtm_box {
    text-align: center;
    color: #FFFFFF;
    padding: 0 25px;
}
.hair-duda .hair_designer_bannerbtm_box+.hair_designer_bannerbtm_box {
    border-left: 1px dashed #fff;
}
.hair-duda .hair_designer_bannerbtm_box h3 {
    font-size: 17px;
    font-weight: 400;
    list-style: 25px;
    padding-top: 18px;
}
/* --- */
.hair-duda .expert__designer {
    padding: 110px 0;
}
.hair-duda .expert__designer .title {
    max-width: 1050px;
    margin-bottom: 51px;
}
.hair-duda .expert__designer .title h2{
    color: #000;
    font-size: 70px;
    font-weight: 600;
    letter-spacing: -0.81px;
}
.hair-duda .expert__designer .title h2 mark{
    color: #ff6336;
    background: transparent;
}
.hair-duda .expert__designer .title h2 span {
    color: #ffffff;
    background: #ff6336;
    line-height: 54px !important;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 10px;
}
.hair-duda .expert__designer p{
    color: #2B2B2B;
    font-size: 25px;
    font-weight: 300;
    line-height: 47px;
}
.hair-duda .expert__designer p span{
    font-weight: 500;
}
.hair-duda .expert__designer a{
    padding: 17px 33px;
    border-radius: 50px;
    border: 1.5px solid #ff6336;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    background-color: #ff6336;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin-top: 40px;
    text-transform: uppercase;
}
.hair-duda .expert__designer a:hover{
    border: 1.5px solid #20c0a0;
    background-color: #20c0a0;
}
.hair-duda .expert__designer .divider{
    flex: 0 0 50%;
    max-width: 50%;
}
.hair-duda .expert__designer .divider_first_child{
    padding-right: 90px;
}
.hair-duda .expert__designer .circle__rnbo{
    position: relative;
    width: 100%;
}
.hair-duda .expert__designer .circle__rnbo > :nth-child(1) {
    padding: 20px;
    left: 9px;
    top: 8px;
    z-index: 2;
    background: linear-gradient(180deg, #2c14beb3 0%, #183ad3b3 100%);
}
.hair-duda .expert__designer .circle__rnbo > :nth-child(2) {
    background: #FFB000;
    top: 89px;
    padding: 30px;
    left: 135px;
    z-index: 1;
}
.hair-duda .expert__designer .circle__rnbo > :nth-child(3) {
    background: rgba(221, 103, 69, 0.82);
    top: -65px;
    padding: 30px;
    left: 219px;
    z-index: 2;
}
/* .expert__designer .circle__rnbo > :nth-child(3)::after{
    content: '';
    position: absolute;
    
} */
.hair-duda .expert__designer .circle__rnbo > :nth-child(4) {
    background: #1e1e1ed6;
    top: 18px;
    padding: 34px;
    z-index: 4;
    left: 373px;
}
.hair-duda .expert__designer .circle__rnbo .ricl-item{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    position: absolute;
}
.hair-duda .expert__designer .circle__rnbo .ricl-item::before{
    content: '';
    width: 100%;
    display: block;
    padding-top: 100%;
}
.hair-duda .expert__designer .circle__rnbo .ricl-item p {
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    margin: 0;
    flex-shrink: 0;
}

/* --- */
.hair-duda .our__works{
    background-color: #F5F1EC;
    padding-bottom: 90px;
}
.hair-duda .our__works .top_bar {
    padding-top: 30px;
    padding-bottom: 140px;
    background: linear-gradient(139deg, #b83d1a 0%, #e5c573 100%);
}
.hair-duda .our__works .top_bar .title h2 {
    color: #FFF;
    font-size: 120px;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
}
.hair-duda .our__works .top_bar .title h2 span{
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff, 2px 1px 6px #000;
    color: #ff5200;
}
.hair-duda .our__works .webSlider {
    max-width: 1425px;
    margin: -125px auto 0;
}
.hair-duda .our__works .webSlider .slick-prev,
.hair-duda .our__works .webSlider .slick-next, 
.hair-duda .duda_work_with .webSlider .slick-next,
.hair-duda .duda_work_with .webSlider .slick-prev{
    position: static;
    transform: unset;
    width: 48px;
    height: 48px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none !important;
}
.hair-duda .our__works .webSlider .slick-prev, .hair-duda .duda_work_with .webSlider .slick-prev{
    margin-left: 22px;
    order: 2;
    background-image: url('../../Assets/Images/dudadesigner/slider-left.svg');
}
.hair-duda .our__works .webSlider .slick-next, .hair-duda .duda_work_with .webSlider .slick-next{
    margin-left: 16px;
    order: 3;
    background-image: url('../../Assets/Images/dudadesigner/slider-right.svg');
}
.hair-duda .our__works .webSlider .slick-slider, .hair-duda .duda_work_with .webSlider .slick-slider {
    display: flex;
    flex-wrap: wrap;
}


.hair-duda .our__works .webSlider .slick-list {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 15px;
}
/* --- */
.hair-duda .mind_blowing{
    padding: 81px 0 230px;
}
.hair-duda .mind_blowing .title h3{
    color: #000;
    font-size: 76px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    text-transform: capitalize;
}
.hair-duda .mind_blowing .title{
    padding-bottom: 110px;
}
.hair-duda .mind_blowing .divider {
    position: relative;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    display: flex;
}
.hair-duda .mind_blowing .divider::before{
    content: '';
    display: block;
    padding-top: 100%;
}
.hair-duda .mind_blowing .item-one {
    border-radius: 50%;
    border: 1px solid #000;
    background: linear-gradient(180deg, #000 0%, #000 100%);
    flex-shrink: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 70px;
}
.hair-duda .mind_blowing .item-one h4 {
    color: #FFF;
    font-size: 38px;
    font-weight: 700;
    line-height: 39px;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.hair-duda .mind_blowing .item-one h2 {
    color: #FFF;
    display: flex;
    font-size: 100px;
    align-items: center;
    font-weight: 600;
    line-height: 91px;
    letter-spacing: -3.48px;
}
.hair-duda .mind_blowing .item-one h2 span{
    color: #FFF;
    font-size: 37px;
    font-weight: 700;
    line-height: 57px;
    text-transform: capitalize;
    margin-left: 8px;
}
.hair-duda .mind_blowing .item-one p{
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    padding-top: 15px;
    text-transform: uppercase;
}
.hair-duda .mind_blowing .item-one p span{
    display: block;
    font-size: 26px;
    font-weight: 900;
}
.hair-duda .mind_blowing .item-three {
    background: #FFA620;
    padding: 0 95px;
}
.hair-duda .mind_blowing .item-two p span{
    display: block;
    color: #ff5200;
    font-size: 26px;
}

.hair-duda .mind_blowing .item-three p,
.hair-duda .mind_blowing .item-three h2,
.hair-duda .mind_blowing .item-three h2 span,
.hair-duda .mind_blowing .item-three h4{
    color: #000;
}
.hair-duda .mind_blowing .item-two h2 {
    text-align: center;
    font-size: 93px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -5.7px;
    background: linear-gradient(270deg, #FFA620 0%, #E14541 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hair-duda .mind_blowing .item-two {
    text-align: center;
    border-radius: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: scale(1.16);
    border-left: 1px solid #fff;
    position: relative;
    box-shadow: 0px 0px 0px 1px #000;
    z-index: 6;
}
.hair-duda .mind_blowing .item-two p {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    padding-top: 15px;
    text-transform: uppercase;
}
.hair-duda .mind_blowing .item-two h4{
    color: #000;
    font-size: 41px;
    font-weight: 700;
    line-height: 34px;
    text-transform: uppercase;
}

.hair-duda .your-customers{
    background: #1B1B1B;
}
.hair-duda .your-customers .text-area{
    text-align: center;
}
.hair-duda .your-customers .text-area h5 {
    border-radius: 50px;
    background: #FFA620;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    color: #1B1B1B;
    font-size: 40px;
    padding: 10px 35px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -3.72px;
    text-transform: uppercase;
}


.hair-duda .your-customers h2{color: #FFF;
text-align: center;
font-size:100px; line-height: 110px;
font-weight: 700;
text-transform: capitalize;
letter-spacing: -2.44px; padding-top: 40px;
}
.hair-duda .your-customers{ padding: 60px 0; }
.hair-duda .your-customers-btn{ 
    font-size: 22px; 
    line-height: 50px; 
    border-radius: 50px;
    background-color: transparent; 
    display: inline-block; 
    cursor: pointer; 
    border: 1px solid #ff7011; 
    font-weight: 700; 
    text-transform: uppercase; 
    color: #ff7011; 
    padding: 5px 40px; 
    margin-top: 40px;
    transition: 0.5s;
}

.hair-duda .your-customers-btn:hover{
    background-color: #ff7011;
    color: #fff;
}

.hair-duda .your-customers-white-text-box{ 
    position: absolute; 
    width: 393px; 
    z-index: 9;
    right: 0; 
    background: #fff; 
    padding: 25px 35px; 
    border: 1px solid #000; 
    line-height: 29px;
    letter-spacing: -0.22px;
    text-transform: capitalize;
}
.hair-duda .your-customers-white-text-box::before{
    position: absolute;
    top: 40px;
    background-image: url('../../Assets/Images/dudadesigner/bg-arrow.png');
    background-size: 100%;
    width: 110px;
    height: 156px;
    right: 480px;
    content: "";
    background-repeat: no-repeat;
}
.hair-duda .your-customers-white-text-box h5{
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
}
.hair-duda .your-customers-white-text-box h4{
    font-size: 24px;
    color: #DB5400;
    padding-top: 15px;
}
.hair-duda .your-customers-white-text-box span{color: #DB5400;
    font-size: 32px;
    font-weight: 600;
    line-height: 43px;
    letter-spacing: -0.32px;
    text-transform: capitalize; top: -125px;}
.hair-duda .your-customers .text-area{position: relative;}
.hair-duda .duda_work_with .title{ max-width: 760px;  margin-bottom: 51px;}
.hair-duda .duda_work_with{ padding: 110px 0;}
.hair-duda .duda_work_with .title h2{
    color: #000;
    font-size: 60px;
    line-height: 75px;
    font-weight: 600;
    letter-spacing: -0.81px;
}
.hair-duda .duda_work_with .title h2 span {
    color: #ffffff;
    background: #ff6336;
    line-height: 54px !important;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 10px;
}
.hair-duda .duda_work_with .webSlider .slick-slider .slick-list{
    padding-bottom: 20px;
}
.duda-developer-img{
    width: 100%;
    height: 350px;
    padding: 0 10px;
    position: relative;
}
.duda-developer-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.duda-developer-text{
    position: absolute;
    bottom: 20px;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    color: #fff;
    transition: 0.5s;
}
/* .duda-developer-img:hover .duda-developer-text{
    opacity: 1;
} */
.duda-developer-text h5{
    font-size: 18px;
}
.duda-developer-text p{
    font-size: 12px;
    line-height: 20px;
}

.duda-testmonial{
    background: rgb(245,241,236);
    background: linear-gradient(180deg, rgba(245,241,236,1) 20%, rgba(245,241,236,0) 96%);
    padding: 100px 0;
}

.duda-testmonial h1{
    font-size: 45px;
    text-align: center;
    padding-bottom: 40px;
}
.duda-testmonial h1 span{
    color: #ff6336;
}

.duda-testmonial-text-box{
    width: 100%;
    background-color: #fff;
    color: #1B1B1B;
    border-radius: 10px;
    padding: 20px;
    font-size: 16px;
    transition: 0.5s;
    min-height: 166px;
}
.duda-testmonial-box:hover .duda-testmonial-text-box{
    background-color: #ff6336;
    color: #fff;
}
.duda-testmonial-user-details{
    display: flex;
    align-items: center;
    padding-top: 20px;
}
.duda-testmonial-user-img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
}
.duda-testmonial-user-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}
.duda-testmonial-user-name{
    padding-left: 15px;
    flex: 1;
}
.duda-testmonial-user-name h5{
    font-size: 15px;
}

.duda-faq h3{
    font-size: 48px;
    text-align: center;
}
.duda-faq .faq-section{
    padding-top: 80px;
}
.duda-faq{
    padding-bottom: 100px;
}
.hair-duda .pricing_scaling .divider .top-bar{
    background: radial-gradient(circle,#FFA620  0%,#b12600 94%);
}
.hair-duda .pricing_scaling .bottom-bar .scr_circle{
    background: linear-gradient(130deg,#fbbb02 0%,#fa4430  100%),#D9D9D9;
}
.hair-duda .pricing_scaling .divider .bottom-bar .link_up, .contact_ups .contact_content .frm-box [type="submit"]{
    background-color: #ff6336;
    color: #fff;
}

.hair-duda .pricing_scaling .divider .bottom-bar .link_up:hover, .contact_ups .contact_content .frm-box [type="submit"]:hover{
    background-color: #db5400;
    color: #fff;
}

.hair-duda .contact_ups .contact_content{
    background: linear-gradient(130deg,#cd0000 0%,#FFA620 100%),linear-gradient(0deg,rgba(0,0,0,0.20) 0%,rgba(0,0,0,0.20) 100%),#FFF;
}

.portfolio-img{
    padding: 0 0 20px;
}
.portfolio-img img{
    border-radius: 5px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}
.schedule-btn-des-none{
    display: none;
}

.hair-duda .duda_designer_profile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0,0,0,.5);
    padding: 100px 10px 0;
    min-height: 100vh;
    overflow-y: auto;
}

.hair-duda .duda_designer_profile_box {
    max-width: 600px;
    height: 510px;
    overflow-y: auto;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    margin: 0 auto;
}

.hair-duda .hire_duda_designer_btn{     text-decoration: none;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 50px;
    background: #ff6336; color: #fff;
    padding: 7px 15px;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    margin: 12px 0 0;
    cursor: pointer;}
.hair-duda .Guideline-modal-box {
        padding: 30px;
        height: calc(100vh - 100px);
        overflow-y: scroll;
    }
.hair-duda .Guideline-modal-box{ padding: 30px;}
.hair-duda .duda_designer_profile_header{ display: flex; justify-content: center; align-items: center; background-color: #360b50; padding: 10px;}
.hair-duda .duda_designer_profile_header .duda_designer_profile_image{ width: 70px; height: 70px; border-radius: 100%;}
.hair-duda .duda_designer_profile_header .duda_designer_profile_image img{border-radius: 100%; width: 100%; height: 100%; object-fit: cover;}
.hair-duda .duda_designer_profile_header .duda_designer_profile_header_text{ margin-left: 25px;}
.hair-duda .duda_designer_profile_header .duda_designer_profile_header_text h3{ font-size:25px; line-height:25px; color: #fff; font-weight: 600;}
.hair-duda .duda_designer_profile_header .duda_designer_profile_header_text h3 span{ font-size: 15px; line-height: 15px; color: #fff; font-weight: 300;}

.hair-duda .inline-btn{ background: none;}
.hair-duda .inline-btn svg { color: #000 !important; position: absolute; right: 14px;}

.hair-duda .duda_designer_profile_content{ padding: 15px 30px 30px 30px; height: 430px; overflow-y: scroll;}
.hair-duda .duda_designer_profile_content h3{ font-size: 20px; line-height: 24px; color: #e33b00; position: relative; z-index: 1; margin-top:30px;}
.hair-duda .duda_designer_profile_content h3 span{ background-color: #fff; display: inline-block; padding-right: 12px;}
.hair-duda .duda_designer_profile_content h3::before{ position: absolute; content: ''; width: 100%; height: 1px; background-color: #e2e2e2; top: 50%; left: 0; right: 0; z-index: -1;}

.hair-duda .skill_tag ul{ display: flex; flex-wrap: wrap; gap:10px; margin-top:20px;}
.hair-duda .skill_tag ul li{  font-size: 14px; display: inline-block; background-color: #f9ecd7; padding: 0 15px; line-height: 30px; border-radius: 50px;}
.hair-duda .duda_designer_profile_content p{font-size: 14px; line-height:18px; padding-top: 15px;}

.hair-duda .project_link ul{margin-top: 10px;}
.hair-duda .duda_designer_profile.designer_profile_popup .inline-btn svg{ color: #fff !important;}


@media(max-width:1440px){
    .hair-duda .mind_blowing .item-three {
        padding: 0 75px;
    }
    .hair-duda .mind_blowing .item-one h4{
        font-size: 28px;
        line-height: 31px;
    }
    .hair-duda .mind_blowing .item-one h2{
        font-size: 80px;
    }
    .hair-duda .mind_blowing .item-one h2 span{
        font-size: 27px;
        line-height: 44px;
        letter-spacing: 0;
    }
    .hair-duda .mind_blowing .item-two h2{
        font-size: 70px;
    }
    .hair-duda .mind_blowing .item-two p{
        font-size: 16px;
    }
    .hair-duda .our__works .top_bar .title h2{
        font-size: 90px;
    }
}

@media(max-width:1366px){
    .hair-duda .mind_blowing .title h3{
        font-size: 60px;
    }
}

@media(max-width:1240px){
    .hair-duda .expert__designer .title h2 {
        color: #000;
        font-size: 48px;
    }
    .hair-duda .expert__designer .divider{
        max-width: 100%;
        flex: inherit;
    }
    .hair-duda .expert__designer .divider_first_child{
        padding-right: 0;
    }
    .hair-duda .d-flex-wap{
        display: block;
    }
    .hair-duda .expert__designer .circle__rnbo .ricl-item{
        position: inherit;
        width: 24%;
        min-height: 200px;
        left: auto !important;
        border-radius: 10px;
        top: auto !important;
        margin: 0 0.5%;
    }
    .hair-duda .expert__designer .circle__rnbo{
        display: flex;
        padding-top: 40px;
    }
    .hair-duda .expert__designer .circle__rnbo .ricl-item::before{
        display: none;
    }

    .hair-duda .mind_blowing .d-flex-wap{
        display: flex;
    }

    .hair-duda .mind_blowing .item-one h4 {
        font-size: 18px;
        line-height: 19px;
    }
    .hair-duda .mind_blowing .item-one h2 {
        font-size: 50px;
        line-height: 56px;
    }
    .hair-duda .mind_blowing .item-one{
        padding: 0 60px;
    }

    .hair-duda .your-customers-white-text-box{
        position: relative;
        padding: 17px 20px;
        margin-left: auto;
        margin-top: 20px;
    }
    .hair-duda .duda_work_with .title{
        max-width: 100%;
    }
    .hair-duda .duda_work_with .title h2{
        font-size: 48px;
        line-height: 70px;
    }
    .hair-duda .duda_work_with {
        padding: 67px 0;
    }
    .hair-duda .your-customers .text-area h5{
        font-size: 22px;
        letter-spacing: 0;
    }
    .hair-duda .your-customers h2{
        font-size: 76px;
        line-height: 86px;
    }

}

@media(max-width:991px){
    .hair-duda .banner .banner-section p{
        font-size: 18px;
    }
    .hair-duda .banner .banner-section h2{
        font-size: 57px;
        line-height: 66px;
    }
    .hair-duda .banner .banner-section {
        padding: 42px 0 45px;
    }
    .hair-duda .expert__designer .title h2{
        font-size: 40px;
        line-height: 45px;
    }
    .hair-duda .expert__designer .title h2 span{
        padding: 0 5px;
    }
    .hair-duda .expert__designer .circle__rnbo .ricl-item{
        width: 49%;
        min-height: auto;
        display: block;
        margin-top: 10px;
    }
    .hair-duda .expert__designer .circle__rnbo{
        flex-wrap: wrap;
    }
    .hair-duda .expert__designer p br{
        display: none;
    }
    .hair-duda .mind_blowing .item-one{
        border-radius: 0;
        padding: 20px;
    }
    .hair-duda .mind_blowing .divider{
        max-width: 500%;
        flex:0 0 50%;
    }
    .hair-duda .mind_blowing .d-flex-wap{
        display: flex;
    }
    .hair-duda .mind_blowing .divider::before{
        display: none;
    }
    .hair-duda .mind_blowing .item-two{
        border-radius: 0;
        transform: none;
    }
    .hair-duda .mind_blowing .title{
        padding-bottom: 40px;
    }
    .hair-duda .mind_blowing .title h3 {
        font-size: 40px;
    }
    .hair-duda .mind_blowing {
        padding: 64px 0;
    }
    .duda-faq h3 {
        font-size: 35px;
    }
    .hair-duda  .pricing_scaling {
        padding: 68px 0;
    }
    .hair-duda .banner .header-part .full-stack-top-btn{
        padding: 10px 25px;
    }
}

@media(max-width:767px){
    .hair-duda .banner .banner-section h2 {
        font-size: 47px;
        line-height: 55px;
        margin: 7px auto 23px;
    }
    .hair-duda .banner .banner-section h5{
        font-size: 15px;
        line-height: 20px;
        max-width: 600px;
        margin: 0 auto;
    }
    .hair-duda .banner-feture{
        padding-left: 0;
        justify-content: center;
    }
    .hair-duda .banner_featur_width .banner-second-feture, .hair-duda .banner_featur_width .banner-first-feture{
        width: auto;
    }
    .hair-duda .banner_featur_width{
        padding-left: 0;
    }
    .hair-duda .hair_designer_bannerbtm_box{
        width: 50%;
        padding: 15px 30px;
    }
    .hair-duda .hair_designer_bannerbtm{
        flex-wrap: wrap;
        padding: 10px 0 0;
    }
    .hair-duda .expert__designer {
        padding: 50px 0;
    }
    .hair-duda .expert__designer .title h2 {
        font-size: 32px;
        line-height: 32px;
    }
    .hair-duda .expert__designer .title h2 span{
        line-height: 37px !important;
    }
    .hair-duda .expert__designer p{
        font-size: 20px;
        line-height: 29px;
    }
    .hair-duda .expert__designer .title{
        margin-bottom: 15px;
    }
    .hair-duda .expert__designer .circle__rnbo > :nth-child(2), .hair-duda .expert__designer .circle__rnbo .ricl-item{
        padding: 20px;
    }
    .hair-duda .our__works .top_bar .title h2 {
        font-size: 60px;
    }
    .hair-duda .our__works .top_bar {
        padding-bottom: 147px;
    }
    .hair-duda .our__works{
        padding-bottom: 50px;
    }
    .hair-duda .duda_work_with .title h2{
        font-size: 35px;
        line-height: 40px;
    }
    .hair-duda .duda_work_with .title h2 span{
        line-height: 35px !important;
    }

    .hair-duda .your-customers h2 {
        font-size: 40px;
        line-height: 45px;
        padding-top: 12px;
    }

    .hair-duda .your-customers-btn{
        font-size: 16px;
        line-height: 36px;
        padding: 5px 20px;
    }
    .hair-duda .your-customers-white-text-box::before{
        display: none;
    }
    .hair-duda .your-customers-white-text-box{
        margin-right: auto;
    }
    .duda-testmonial{
        padding-top: 40px;
        padding-bottom: 50px;
    }
    .duda-testmonial h1{
        font-size: 35px;
    }
    .hair-duda .banner_featur_width .banner-second-feture, .hair-duda .banner_featur_width .banner-first-feture{
        padding: 0 10px;
    }

    .hair-duda .hair_designer_bannerbtm_box:nth-child(3){
        border-left: 0;
    }
    .hair-duda .hair_designer_bannerbtm_box h3 {
        font-size: 12px;
        padding-top: 10px;
    }
    
}

@media(max-width:600px){
    .schedule-btn-des-none{
        display: inline-block;
    }

    .duda-designer-btn.hair-designer-btn{
        width: 310px;
        margin: 10px auto 0;
    }
}
@media(max-width:575px){
    .hair-duda .mind_blowing .divider {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .hair-duda .mind_blowing .item-two{
        padding: 20px;
    }
    .hair-duda .mind_blowing .item-one{
        text-align: center;
    }
    .hair-duda .mind_blowing .item-one h2{
        justify-content: center;
    }
    .hair-duda .mind_blowing .item-one h4 br{
        display: none;
    }
    .hair-duda .mind_blowing .title h3 {
        font-size: 35px;
    }
    .hair-duda .banner .banner-section h2 {
        font-size: 39px;
        line-height: 44px;
    }
    .hair-duda .banner .header-part .full-stack-top-btn {
        padding: 7px 11px;
    }
    .hair-duda .expert__designer .circle__rnbo .ricl-item p br{
        display: none;
    }
    .hair-duda .expert__designer .circle__rnbo .ricl-item p{
        font-size: 16px;
    }
    .hair-duda .banner-feture{
        justify-content: left;
    }
    .hair-duda .expert__designer .divider_first_child, .hair-duda .expert__designer .title h2{
        text-align: center;
    }
    .hair-duda .our__works .top_bar .title h2{
        font-size: 40px;
    }
    .hair-duda .duda_designer_profile_header .duda_designer_profile_header_text h3{ font-size: 16px; line-height: 20px;}
    .hair-duda .duda_designer_profile_header .duda_designer_profile_header_text h3 span{ font-size: 13px; line-height:13px;}
}.hair-duda .duda_designer_profile_header .duda_designer_profile_image{ height: 50px; width: 50px;}
.hair-duda .duda_designer_profile_content{ padding: 15px;}

@media(max-width:400px){
    .hair-duda .your-customers-white-text-box{
        width: 100%;
    }
    
}