/* --------------------------------------------------------------------- */
.testimonial-sec-box .review_btn{display:flex;align-items:center;justify-content:center;margin:50px 0 0 0;}
.testimonial-sec-box .review_btn .review_btns{border-radius:50px;background:#50E6C8;color:#000;cursor:pointer;font-size:18px;font-style:normal;font-weight:700;line-height:normal;text-transform:uppercase;padding:0 60px;display:inline-block;line-height:62px;margin-top:30px;}
.testimonial-sec-box .review_btn .review_btns:hover{background-color:#2cb99d;}
.testimonial-sec-box .review_btn .full-stack-top-btn:hover{background-color:#50e6c8;border-color:#50e6c8;color:#000;}
.banner-developer-sec{flex-wrap:wrap;}
.banner-developer-sec .rating_box{width:100%;display:flex;align-items:center;justify-content:center;margin:0 0 30px 0;}
.banner-developer-sec .banner-form{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    background-color:#f9f9f9;
    padding:30px;
    border-radius:0.3rem;
}
.banner-developer-sec .banner-form h3{font-size:25px;text-align:center;margin-bottom:15px;}
.banner-developer-sec .banner-form form{width:100%;}
.banner-developer-sec .banner-form .form-group{margin-bottom:15px;}
.banner-developer-sec .banner-form .form-group .form-control{
    border-radius: 4px;
    border: 1px solid #B4B4B4;
    background: #f9f9f9;
    display: block;
    width: calc(100% - 10px);
    padding: 0 10px;
    height: 42px;
}
.header-part .header_middle ul li .menu_items{
    display: block;
}
.banner-form .PhoneInput{
    border-radius: 4px;
    border: 1px solid #B4B4B4;
    background: #f9f9f9;
    width: calc(100% - 10px);
    padding: 0 10px;
    height: 42px;
}
.banner-form .PhoneInput .PhoneInputInput{
    border: 0;
    background-color: transparent;
}
.banner-developer-sec .banner-form .form-group textarea.form-control{
    height:80px;
    padding: 10px;
    resize: none;
}
.banner-form .full-stack-btn .btn-ring-animation{
    border-color: #fff transparent #fff transparent;
}
.banner-developer-sec .banner-form .form-group .submit_btn{width:100%;border-radius:50px;background:#50E6C8;color:#000;cursor:pointer;font-size:18px;font-style:normal;font-weight:700;line-height:normal;text-transform:uppercase;padding:0 60px;display:inline-block;line-height:62px;margin-top:0;}
.banner-developer-sec .banner-form .form-group .submit_btn:hover{background-color:#2cb99d;}
.faq-section{
    padding-top: 120px;
}
.banner-developer-sec .banner-form  .full-stack-btn{
    width: 100%;
    text-align: center;
    justify-content: center;
    padding: 15px;
    background-color: #F0612E;
    border-color: #F0612E;
    color: #fff;
}
.logo{
    cursor: pointer;
}

.header-section{
    position: relative;
    background-color: transparent;
    transition: all 0.3s ease;
}
.header-section.fixed-header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #1b0334;
    z-index: 99;
}
.sales-page .header-section.fixed-header .container .header-part{
    padding: 10px 0;
}
.header-section.fixed-header .full-stack-top-btn.fill-btn, .header-section.fixed-header .full-stack-top-btn{
    font-size: 14px;
    padding: 10px 18px;
}
.header-section.fixed-header .logo{
    width: 150px;
}

.header-section.slide-in {
    animation: slideIn 0.3s ease-in;
}

.bars-menu{
    background-color: transparent;
    margin-left: 20px;
    color: #fff;
    width: 35px;
    border-radius: 5px;
    display: none;
    justify-content: center;
    align-items: center;
    height: 35px;
    background-color: rgba(255, 255, 255, 0.1);
}
  
@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.full-stack .rating_box{
    display: flex;
    padding: 30px 0 0;
    justify-content: space-between;
}
.full-stack .reating-box{
    padding: 10px;
}

.full-stack .header_right a+a span {
    color: #1d1919;
    transition: 0.5s;
    background-color: transparent !important;
}

@media(max-width:767px){
    .banner-developer-sec .banner-form{padding:20px;}
    .banner-developer-sec .banner-form h3{font-size:20px;margin-bottom:30px;margin-top:10px;}
    .banner-developer-sec .banner-form .form-group .submit_btn{line-height:50px;}
    
}

@media(max-width: 991px) {
    .bars-menu{
        display: inline-flex;
    }
    .header-part .header_middle{
        position: absolute;
        top: 100%;
        right: 0;
        width: 250px;
        background-color: rgba(0,0,0,0.9);
        z-index: 99;
        overflow-y: hidden;
        height: 0px;
        transition: 0.5s;
        backdrop-filter: blur(2px);
    }
    .header-part .header_middle.active{
        height: 190px;
    }
    .header-part .header_middle ul{
        flex-direction: column !important;
        padding: 20px 0;
    }
    .header-part .header_middle ul li  {
        padding: 0 !important;
    }
    .header-part .header_middle ul li a{
        padding: 10px 13px !important;
    }
}



.banner{
    background:rgb(27,3,52);
    /* background:radial-gradient(circle,rgba(140,0,255,0.8128501400560224) 0%,rgba(27,3,52,1) 68%); */
    background: rgb(52,41,132);
    background: radial-gradient(circle,rgba(52,41,132,1) 0%,rgba(27,3,52,1) 64%);
}
.full-stack-top-btn{border:solid 2px #fff;padding:13px 25px;display:inline-block;border-radius:30px;color:#fff;font-size:14px;font-weight:600;text-transform:uppercase;cursor:pointer;}
.full-stack-top-btn.call-btn-header{
    font-weight: 500;
    font-size: 16px;
}
.full-stack-top-btn.fill-btn{background-color:#20c0a0;border-color:#20c0a0;margin-left:10px;color:#000;}
.header_right{align-items:center;}
.reating-box{display:flex;align-items:center;padding-right:30px;cursor:pointer;}
.reating-box span{font-size:14px;color:#fff;font-weight:500;padding-right:10px;}
.full-stack-top-btn:hover, .full-stack-top-btn.fill-btn:hover, 
.full-stack .header_right a+a:hover span{
    background-color:#F0612E; 
    border-color:#F0612E; 
    color: #fff !important;
}
.banner .header-part .logo{filter:brightness(100);}
.banner-developer-img{width:120px;height:120px;border-radius:100%;margin:0 auto;}
.banner-developer-img img{width:100%;height:100%;border-radius:100%;object-fit:cover;}
.banner-developer{background-color:#fff;border-radius:10px;padding:40px 20px;width:47%;text-align:center;}
.banner-developer h5{font-size:20px;font-weight:600;padding-top:20px;}
.banner-developer p{font-size:16px;}
.banner-developer-sec{
    display:flex;
    width:40%;
    justify-content:space-between;
    padding-left:80px;
}
.banner-developer .tag{font-size:15px;line-height:18px;border:solid 1px #000;border-radius:20px;padding:8px 25px;display:inline-block;margin:3px;background-color:transparent;transition:0.5s;cursor:pointer;}
.banner-developer .tag:hover{background-color:#000;color:#fff;}
.tag-section{display:flex;align-items:center;justify-content:center;flex-wrap:wrap;padding-top:20px;}
.banner-section{display:flex;padding:80px 0 20px;align-items:flex-start;}
.banner-left{color:#fff;width:60%;text-align:left;}
.banner-left h2{font-size:48px;line-height:64px;font-weight:600;}
.banner-left h2 span{
    font-size:65px;
    
}
.banner-left h2 span.bg{
    background-color: #F0612E;
    border-radius: 5px;
    padding: 5px;
    display: inline-block;
}
.banner-left h2 span.color{
    color: #F0612E;
    text-shadow: 1px 0 #fff, -1px 0 #fff, 0 1px #fff, 0 -1px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
    font-size: 78px;
}
.banner-left p{font-size:22px;padding-top:15px;line-height:28px;}
.banner-left p span{
    font-weight: 600;
    color: #fd7a2e;
}
.banner-left .banner-location{
    font-size: 14px;
    font-weight: 500;
    padding-top: 0;
    padding-bottom: 15px;
    color: rgba(255, 255, 255, 0.8);
}
.banner-left .banner-location span{
    font-size: 16px;
    font-weight: 400;
    display: block;
    color: #ffffff;
}
.banner-feture{display:flex;padding:40px 0 25px; flex-wrap: wrap;}
.banner-feture p{
    font-size:16px;
    line-height:18px;
    padding:0;
    background-image: url('../../Assets/Images/sales-check2.png');
    background-repeat: no-repeat;
    background-position:0 2px;
    background-size: 20px;
    font-weight: 600;
    padding: 2px 0 2px 26px;
    text-align: left;
   
}
.banner-feture p .image-text{
    display: inline-block;
    background-color: #F0612E;
    border-radius: 10px 10px 10px 0;
    padding:1px 5px;
    margin-left: 5px;
    color: #fff;
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
}
.banner-first-feture{display:flex;padding-right:20px; border-right:dashed 1px #fff;width:50%;  margin-bottom: 15px;}
.banner-second-feture{display:flex;padding-left:20px;width:50%; margin-bottom: 15px;}
.full-stack-btn{border:solid 1px #50E6C8;padding:13px 25px;background-color:#50E6C8;display:inline-flex;border-radius:30px;color:#000;font-size:14px;font-weight:600;text-transform:uppercase;cursor:pointer;transition:0.5s;margin-top:10px;}
.full-stack-btn .btn-ring-animation{border-color:#000 transparent #000 transparent;margin-left:10px;}
.full-stack-btn:hover{background-color:#20c0a0;color:#000;border-color:#20c0a0;}
.trusted-section{padding:50px 0 0;color:#fff;}
.trusted-section + .trusted-section{padding-bottom:30px;padding-top:30px;}
.technologi-heading-section{text-align:center;position:relative;margin:20px 0 20px;}
.technologi-heading-section:before{position:absolute;top:50%;left:0;right:0;height:1px;content:"";background-color:rgba(255,255,255,0.4);}
.trusted-section + .trusted-section h5.technologi-heading{text-align:center;font-size:22px;border:solid 1px rgba(255,255,255,0.4);border-radius:100px;position:relative;display:inline-block;padding:10px 30px;background-color:#5c1895;}
.technologies-logo{display:flex;flex-wrap:wrap;justify-content:center;}
.technologies-logo div{width:14.2%;border-radius:5px;padding:3px 5px;}
.technologies-logo div img{border-radius:3px;}
.trusted-section .slick-slide{padding:0 3px;}
.trusted-logo-box{display:block;align-items:center;}
.trusted-logo-box h5{padding-right:50px;font-size:20px;font-weight:500;padding-bottom:10px;}
.testimonial-sec-box{background-color:#F1F3F4;padding:100px 0 0;position:relative;}
.testimonial-sec-box:before{background:url(/src/Assets/Images/vector-1.png) no-repeat 0 100%;position:absolute;left:0;bottom:0;content:"";width:100%;height:308px;}
.testimonial-sec-box:after{background:url(/src/Assets/Images/vector-2.png) no-repeat 100% 0;position:absolute;left:0;top:0;content:"";width:100%;height:361px;z-index:1;}
.text-prt{width:60%;float:left;}
.video-sec-prt{width:40%;float:left;padding-left:68px;position:relative;z-index:9;}
.text-prt h3{color:#000;font-size:42px;font-weight:600;line-height:57px;letter-spacing:-0.96px;}
.testimonial-prt{overflow:hidden;}
.video-sec-prt iframe{border-radius:5px;}
.video-sec-prt h4{color:#000;font-size:21px;font-style:normal;font-weight:600;line-height:27px; /* 295.238% */letter-spacing:-0.21px;padding-top:15px;}
.video-sec-prt p{color:#000;padding-top:8px;font-size:16px;font-style:normal;font-weight:400;line-height:20px; /* 387.5% */letter-spacing:-0.16px;}
.invertd{position:relative;top:-12px;margin-right:10px;}
.testimonila-white-sec{padding-top:120px;padding-bottom:120px;overflow:hidden;}
.left-sec-prt{float:left;width:45%;}
.left-sec-prt h4{text-align:center;padding-top:20px;}
.circle-sec{width:50%;float:left;text-align:center;position:relative;z-index:2;margin-bottom:15px;}
.circle-sec h5{position:absolute;top:50%;transform:translateY(-50%);left:0;color:#fff;padding:20px;font-size:24px;}
.circle-sec:nth-child(2n){position:relative;left:-12%;z-index:1;}
.left-sec-prt h4{color:#000;font-size:21px;font-style:normal;font-weight:600;line-height:32px; /* 295.238% */letter-spacing:-0.21px;padding-right:61px;}
.left-sec-prt p{color:#000;font-size:16px;font-style:normal;font-weight:400;line-height:22px; /* 387.5% */letter-spacing:-0.16px;}
.right-sec-prt{width:55%;float:left;}
.right-sec-prt h3{
    color:#000;
    font-size:48px;
    font-style:normal;
    font-weight:600;
    line-height:54px; 
    letter-spacing:-0.96px;
}
.right-sec-prt h3 span.bg{
    background-color: #F0612E;
    color: #fff;
    border-radius: 5px;
    padding: 2px 5px;
    display: inline-block;
}
.right-sec-prt h3 span.color{
    color: #F0612E;
}
.right-sec-prt p{color:#000;font-size:20px;font-style:normal;font-weight:400;line-height:30px; /* 150% */letter-spacing:-0.2px;padding-top:25px;}
.btn-stack{
    border-radius:50px;
    background: #50E6C8;
    color:#000;
    cursor:pointer;
    font-size:18px;
    font-style:normal;
    font-weight:700;
    line-height:normal;
    text-transform:uppercase;
    padding:0 60px;
    display:inline-block;
    line-height:62px;
    margin-top:30px;
    border: solid 1px #50E6C8;
    transition: 0.5s;
}
.btn-stack:hover{background-color:#F0612E; color: #fff; border-color:#F0612E;}
.developer-sec{    /* background: rgb(140,0,255);
        background: radial-gradient(circle, rgba(140,0,255,0.9) 0%, rgba(27,3,52,1) 100%); */
background:radial-gradient(circle,rgba(140,0,255,0.8128501400560224) 0%,rgba(27,3,52,1) 68%);padding:80px 0;position:relative;text-align:center;color:#fff;}
.developer-sec:before{background:url(/src/Assets/Images/doted-ptrn.png) no-repeat 50% 100%;position:absolute;bottom:0;left:50%;transform:translateX(-50%);content:"";width:100%;height:196px;}
.developer-sec h3{color:#FFF;text-align:center;font-size:46px;font-style:normal;font-weight:600;line-height:60px; /* 112.5% */letter-spacing:-0.96px;}

/* .developer-sec:before{
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background: radial-gradient(49.07% 49.05% at 50.00% 52.90%, #8C00FF 0%, rgba(42, 42, 42, 0.00) 100%);
    width: 100%;
    height: 100%;
} */
.total-three-box{display:flex;justify-content:space-between;padding-top:65px;padding-bottom:50px;}
.developer-box{width:33.333%;float:left;padding:50px;border-radius:10px;margin:0 2%;    /* border: solid 1px #db67e0 ; */border:1px solid #8C00FF;  /* border-image: linear-gradient(45deg, #db67e0, #8C00FF) 1; */box-shadow:0px 4px 4px 0px rgba(0,0,0,0.25);position:relative;}
.number-crcl{position:absolute;left:50%;top:-18px;width:36px;height:36px;border-radius:100%;line-height:36px;background:linear-gradient(130deg,#8C00FF 0%,#FF96D2 100%),#D9D9D9;color:#FFF;text-align:center;font-family:Work Sans;font-size:25px;font-style:normal;font-weight:600;line-height:36px; /* 120% */letter-spacing:-0.25px;transform:translateX(-50%);}
.developer-box h4{color:#FFF;text-align:center;font-size:23px;font-style:normal;font-weight:600;line-height:30px; /* 120% */letter-spacing:-0.25px;padding-bottom:20px;padding-top:10px;}
.developer-box p{color:#FFF;text-align:center;font-size:16px;font-style:normal;font-weight:400;line-height:25px; /* 156.25% */letter-spacing:-0.16px;}
.faq-sec-part{padding:107px 0 100px;background:#fff;}
.acordin-sec{display:flex;justify-content:space-between;}
.left-acordion{width:50%;}
.pricing_scaling{padding:127px 0;background:#F1F3F4;position:relative;}
.pricing_scaling:before{background:url(/src/Assets/Images/vector-3.png) no-repeat 100% 100%;position:absolute;right:0;bottom:0;content:"";width:100%;height:308px;}
.pricing_scaling .head-title{text-align:center;max-width:840px;margin-left:auto;margin-right:auto;}
.pricing_scaling .head-title h2{background:#F1F3F4;color:#000;font-size:48px;font-style:normal;font-weight:600;line-height:54px;letter-spacing:-0.96px;margin-bottom:20px;}
.pricing_scaling .head-title h2 span.color{
    color: #F0612E;
}
.pricing_scaling .head-title p{color:#000;font-size:20px;font-weight:400;line-height:30px;letter-spacing:-0.2px;margin-bottom:0;}
.pricing_scaling .wrapper{display:flex;flex-wrap:wrap;margin-top:66px;justify-content:space-between;}
.pricing_scaling .divider{max-width:calc(33.3333333333% - 25px);flex:0 0 calc(33.3333333333% - 25px);overflow:hidden;border-radius:10px;background:#FFF;box-shadow:0px 4px 5px 0px #00000026;}
/* .pricing_scaling .divider:last-child .top-bar{padding:60px 10px 65px;} */
.pricing_scaling .divider p{padding-top:10px;}
/* .pricing_scaling .divider:last-child .bottom-bar h4{margin-top:15px;margin-bottom:35px;} */
.pricing_scaling .divider .top-bar{text-align:center;background:radial-gradient(circle,rgba(140,0,255,1) 0%,rgba(42,42,42,1) 94%);padding:20px 10px 115px;}
.pricing_scaling .divider .top-bar p{color:#FFF;font-size:16px;font-weight:400;line-height:normal;letter-spacing:-0.16px;margin-bottom:2px;}
.pricing_scaling .divider .top-bar h3{color:#FFF;font-size:30px;font-weight:600;line-height:normal;letter-spacing:-0.3px;margin-bottom:0;}
.pricing_scaling .divider .bottom-bar h4{color:#000;text-align:center;font-size:17px;font-weight:500;line-height:30px;letter-spacing:-0.2px;margin-bottom:15px;margin-top:40px;}
.pricing_scaling .divider .bottom-bar ul{width:fit-content;margin:auto;}
.pricing_scaling .divider .bottom-bar li{color:#000;font-size:16px;font-weight:400;line-height:30px;letter-spacing:-0.16px;display:flex;list-style:none;align-items:flex-start;}
.pricing_scaling .divider .bottom-bar li::before{content:'';display:block;width:14px;height:14px;background-image:url('../../Assets/Images/check_list.png');background-repeat:no-repeat;background-position:center;background-size:cover;margin-right:8px;position:relative;top:8px;}
.pricing_scaling .bottom-bar{padding:34px;}
.pricing_scaling .bottom-bar .scr_circle{border-radius:50%;width:180px;height:180px;position:relative;display:flex;align-items:center;justify-content:center;margin:-130px auto 5px;background:linear-gradient(130deg,#8C00FF 0%,#FF96D2 100%),#D9D9D9;}
.pricing_scaling .bottom-bar .scr_circle h2{text-align:center;color:#FFF;font-size:45px;font-weight:600;line-height:36px;letter-spacing:-0.45px;}
.pricing_scaling .bottom-bar .scr_circle h2 span{font-size:20px;display:block;}
.pricing_scaling .divider:nth-child(3) .bottom-bar .scr_circle h2 span{
    font-size:28px;
    line-height: 35px;
    display:block;
}
.pricing_scaling .divider .bottom-bar .link_up{color:#000;text-decoration:none;font-size:18px;font-weight:700;line-height:normal;text-transform:uppercase;border-radius:50px;background:#50E6C8;padding:13px 33px;display:block;width:fit-content;margin:22px auto 0;cursor:pointer;}
.pricing_scaling .divider .bottom-bar .link_up:hover{background-color:#2cb99d;}
.testmonial-start{margin-top:10px;}
.accordion{width:100%;padding-top:60px;display:flex;flex-wrap:wrap;justify-content:space-between;}
.accordion-half{width:49%;}
.developer-icon{width:60px;height:60px;margin:0 auto;display:flex;align-items:center;margin-bottom:10px;}
.accordion-item:nth-child(2n+1){clear:left;}
.accordion-item{background-color:#f1f1f1;padding:15px;border-radius:5px;margin:0 1.5%;margin-bottom:30px;padding-bottom:10px;}
.accordion-title.active{color:#d34043;}
.accordion-title{color:#000;background:none;font-size:19px;font-style:normal;font-weight:600; cursor:pointer;width:100%;text-align:left;position:relative;padding:10px 25px 10px 0;}
.accordion-title:before{position:absolute;right:0;top:50%;content:"";background:url('../../Assets/Images/plus.png') no-repeat 0 100%;width:19px;height:19px;transform:translateY(-50%);transition:0.3s;}
.accordion-title.active:before{position:absolute;right:0;top:41%;content:"";background:url('../../Assets/Images/minus.png') no-repeat 0 100%;width:19px;height:19px;transform:translateY(-50%);}
.accordion-content{font-size:18px;line-height:24px; padding: 10px 0;}
.faq-sec-part h3{color:#000;text-align:center;font-size:48px;font-style:normal;font-weight:500;line-height:54px; /* 112.5% */letter-spacing:-0.96px;}
.contact_ups .contact_content input:focus{outline:none!important;}
.contact_ups .contact_content{border-radius:10px;padding:80px 50px 80px 48px;background:linear-gradient(130deg,#8C00FF 0%,#FF96D2 100%),linear-gradient(0deg,rgba(0,0,0,0.20) 0%,rgba(0,0,0,0.20) 100%),#FFF;box-shadow:0px 4px 5px 0px rgba(0,0,0,0.08);display:flex;align-items:flex-start;}
.contact_ups .contact_content .ttx{padding-right:30px;}
.contact_ups .contact_content .ttx h2{color:#FFF;font-size:36px;font-weight:600;line-height:44px;letter-spacing:-0.9px;}
.contact_ups .contact_content .ttx p{color:#FFF;font-size:20px;font-weight:400;line-height:30px;letter-spacing:-0.2px;margin:20px 0 30px;}
.contact_ups .contact_content .ttx h5{color:#FFF;font-size:20px;font-weight:700;line-height:30px;letter-spacing:-0.2px;margin-bottom:15px;}
.contact_ups .contact_content .ttx li{color:#ffffff;font-size:16px;font-weight:400;line-height:30px;letter-spacing:-0.16px;display:flex;list-style:none;align-items:flex-start;}
.contact_ups .contact_content .ttx li::before{content:'';display:block;width:14px;height:14px;background-image:url('../../Assets/Images/check_list_wht.png');background-repeat:no-repeat;background-position:center;background-size:cover;margin-right:8px;position:relative;top:8px;}
.contact_ups .contact_content .frm-box{border-radius:10px;background:#FFF;padding:22px 22px 32px;width:auto;box-shadow:0px 4px 5px 0px rgba(0,0,0,0.08);}
.contact_ups .contact_content .frm-box h3{color:#000;font-size:23px;font-weight:600;line-height:normal;letter-spacing:-0.46px;}
.contact_ups .contact_content .frm-box p{color:#000;font-size:19px;font-weight:400;line-height:normal;letter-spacing:-0.38px;padding:6px 0 14px;}
footer .item .call-btn{color:#fff;text-align:left;line-height:20px;align-items:center;padding:3px 15px 5px;margin:0 auto;border:dashed 1px #fff;display:inline-flex;border-radius:5px;font-size:18px;font-weight:500;}
footer .item .call-btn small{font-size:12px;font-weight:400;}
footer .item .call-btn:hover{color:#20c0a0;border-color:#20c0a0;}
footer .item .call-btn span:first-child{padding-top:8px;}
footer .item .number-box{padding-left:10px;}
footer .item .call-btn{line-height:20px;}
.contact_ups .contact_content .frm-box label{color:#000;font-size:15px;font-weight:600;line-height:normal;letter-spacing:-0.3px;display:block;width:100%;margin-bottom:7px;padding-top:13px;}
.contact_ups .contact_content .frm-box input{border-radius:4px;border:1px solid #B4B4B4;background:#FFF;display:block;width:calc(100% - 10px);padding-left:10px;height:42px;}
.contact_ups .contact_content .frm-box [type="submit"]{color:#000;text-decoration:none;font-size:16px;font-weight:700;line-height:normal;text-transform:uppercase;border-radius:50px;background:#50E6C8;padding:13px 33px;display:flex;width:100%;margin:22px auto 0;border:none;cursor:pointer;align-items:center;justify-content:center;transition:0.5s;}
.contact_ups .contact_content .frm-box [type="submit"]:hover{background-color:#20c0a0;}
.contact_ups .contact_content .frm-box .btn-ring-animation{border-color:#000 transparent #000 transparent;margin-left:15px;}
.contact_ups .contact_content .frm-box-rp{background-image:url('../../Assets/Images/gki-shap.png');background-repeat:no-repeat;background-size:contain;flex-shrink:0;width:430px;padding:33px 30px 0 26px;margin-top:-30px;}
.testmonial-colum{display:flex;padding:20px 0 0;}
.testmonial-row{width:33.333%;padding:0 15px;}
.testmonial-box{padding:30px;border:solid 1px #e1e6e9;border-radius:10px;margin-top:30px;transition:0.5s;}
.testmonial-box:hover{background-color:#eaf5fa;}
.testmonial-box h5{font-size:18px;font-weight:600;}
.testmonial-box p{font-size:15px;padding-top:15px;font-weight:500;}
.testmonial-details{display:flex;align-items:center;padding-top:25px;}
.testmonial-details p{padding-top:5px;line-height:15px;font-size:14px;color:#777777;}
.testmonial-details h5{font-size:16px;}
.testmonial-user-image{width:60px;height:60px;border-radius:100%;margin-right:20px;}
.testmonial-user-details{flex:1;}
.testmonial-user-image img{width:100%;height:100%;border-radius:100%;object-fit:cover;}
footer{background-color:#1B0334;padding-top:250px;margin-top:-200px;}
footer .wap{display:flex;flex-wrap:wrap;align-items:flex-start;}
footer .frt-logo{display:block;text-decoration:none;margin-bottom:14px;}
footer .item{max-width:33%;flex:0 0 33%;}
footer .item .social_cont{display:flex;align-items:center;flex-wrap:wrap;justify-content:end;margin-top:6px;}
footer .item .social_cont a{text-decoration:none;}
footer .item .social_cont a+a{margin-left:10px;}
footer .item+.item{margin-top:10px;}
footer .item:nth-child(2){padding:0  20px;text-align:center;}
footer .item:nth-child(3) p{text-align:right;}
footer .item p{text-align:center;}
footer .item p a{color:#FFF;font-size:18px;font-weight:500;line-height:50px;letter-spacing:-0.44px;margin-bottom:0;text-align:center;display:inline-block;cursor:pointer;transition:0.5s;}
footer .item p a:hover{color:#20c0a0;}
footer .item h4{color:#FFF;font-size:21px;font-weight:600;line-height:54px;letter-spacing:-0.42px;}
footer .item li a,footer .item li{display:block;width:100%;list-style:none;}
footer .item li a{color:#FFF;font-size:16px;font-weight:400;width:100%;display:block;text-decoration:none;line-height:45px;letter-spacing:-0.32px;}
footer .copy-riht{border-top:1px solid #6c6c6c5e;padding:10px 0;margin-top:66px;}
footer .copy-riht .container{display:flex;flex-wrap:wrap;align-items:center;justify-content:center;}
footer .copy-riht p{color:#FFF;font-size:16px;font-weight:400;line-height:45px;letter-spacing:0.68px;margin-bottom:0;}
footer .copy-riht ul{display:flex;flex-wrap:wrap;margin-left:auto;}
footer .copy-riht li{list-style:none;}
footer .copy-riht li+li{margin-left:18px;}
footer .copy-riht li a{color:#FFF;font-size:16px;text-decoration:none;font-weight:400;line-height:45px;letter-spacing:-0.32px;}
.bnr-dflex{    /* display: flex; */
align-items:center;flex-wrap:wrap;}
.bnr-dflex form{display:flex;align-items:start;}
.bnr-dflex input[type="email"]{width:400px;background:none;border:solid 1px #fff;color:#fff;text-indent:10px;border-radius:25px;line-height:45px;height:45px;margin:10px 10px 0 0;padding:0 0 0 10px;}
.bnr-dflex input[type="email"]::placeholder{color:#fff;}
.bnr-dflex input[type="email"]:-ms-input-placeholder{color:#fff;}
.bnr-dflex input[type="email"]::-ms-input-placeholder{color:#fff;}

.banner-full-btn{
    display: none;
}

/* .accordion-content{
    -webkit-animation: fadein 0.5s ease-in alternate infinite;
    -moz-animation: fadein  0.5s ease-in alternate infinite;
    animation: fadein  0.5s ease-in alternate infinite;
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
} */
.tawk-flex.tawk-flex-center.tawk-text-center.tawk-padding-small{display:none!important;}
.developer-video-slider{
    background: linear-gradient(156deg,#FFF3F0 0%,rgba(255,243,240,0.00) 64.56%),#F4F4FF;
    padding: 95px 0;
}
.developer-video-slider .trusted-section .trusted-logo-box h2{
    color: #000;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
    margin-bottom: 80px;
}
.developer-video-slider .trusted-section .trusted-logo-box h2 span{
    color: #F0612E;
}
.developer-video-slider .trusted-section .youtube-video h6{
    font-size: 22px;
    line-height: 25px;
    color: #000;
    padding-top: 17px;
    text-align: center;
}
.developer-video-slider .trusted-section .youtube-video h6 span{
    font-size: 16px;
    color: #505050;
    line-height: 18px;
    font-weight: 400;
}

.section_three{padding: 150px 0 0;}
.section_three h2, .section_three p{text-align: center;}
.section_three p{
    padding: 15px 0;
    font-size: 18px;
    color: #515151;

}
.section_three h2{
    font-size: 50px;
}
.section_three h2 span{
    color: #f75a1c;
}
.full-stack-batch{
    display: flex;
    padding-top: 35px;
    width: 100%;
    justify-content: center;
}
.full-stack-batch-box{
    width: 33.333%;
    padding: 0 20px;
    text-align: center;
}
.full-stack-batch-box img{
    width: 80px;
    height: 80px;
}
.full-stack-batch-box p{
    color: #fff;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    padding-top: 10px;
}
.section_three .portfolio_btn{font-size: 16px;padding: 7px 40px;border-radius: 60px;background: #fff;color: #000;letter-spacing: .3px;text-align: center;margin: 30px 15px 20px 0;text-transform: uppercase;border: 1px solid #c1d5e5;font-weight:600;position: absolute;right:0;top: 0;margin-right: 2%;}
.section_three .portfolio_btn:hover{background: #f75a1c;color:#fff;border-color:#f75a1c;}
.section_three .blue_bar .small_img{
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size:12px; 
    padding:20px; 
    color:#949eb1;
    display: block;
    transition: 0.5s;
    margin-left: 2px;
}
.section_three .blue_bar .small_img:hover{
    background-color: #fff;
}
.section_three .blue_bar .slick-current .small_img{
    background-color: #fff;
}
.section_three .blue_bar .small_img img{
    display: block;
    width: 66px;
    height: 66px; 
    max-width: 100%;
    margin: 0 auto;
}
.section_three .blue_bar .small_img img + h5{
    padding-top: 10px;
}
.section_three .blue_bar{
    margin:2% 0 5%;
    background: #e4f1f7;
    border-top: 1px solid #b0cae1;
    border-bottom: 1px solid #b0cae1;
    position: relative; 
    padding: 0 40px;
    z-index: 2;
}
.section_three .blue_bar .owl-pagination{display: none;}
.section_three .blue_bar .owl-carousel .owl-wrapper-outer{overflow-y: visible !important;}
.section_three .blue_bar ul{height:101px;overflow: hidden; display: flex;}
.section_three .blue_bar ul li{float: left;width:100%;text-align: center;float: left;padding: 6px 35px; transition:all 500ms ease-in-out;cursor: pointer;-o-transition:all 500ms ease-in-out;-ms-transition:all 500ms ease-in-out;-moz-transition:all 500ms ease-in-out;-webkit-transition:all 500ms ease-in-out; }
.section_three .blue_bar ul li.active, .section_three .blue_bar ul li:hover{background: #f8fbff; transition:all 500ms ease-in-out;-o-transition:all 500ms ease-in-out;-ms-transition:all 500ms ease-in-out;-moz-transition:all 500ms ease-in-out;-webkit-transition:all 500ms ease-in-out; -webkit-box-shadow: 0px 0px 12px -140px rgba(0,0,0,0.75);-moz-box-shadow: 0px 0px 12px -140px rgba(0,0,0,0.75);box-shadow: 0px 0px 12px -140px rgba(0,0,0,0.75);}
.section_three .arrow_control{position: relative;top: 40px;cursor: pointer;}
.section_three .arrow_control img{
    width:18px;
}
.section_three .arrow_control .left{
    left: -47px !important;
    position: absolute;
}
.section_three .arrow_control .right{
    right: -47px !important;
    position: absolute;
}
.section_three .sec_inner_port{
    display:flex;
}

.section_three .sec_inner_port .right .laps{position: relative; padding-left: 50px;}
.section_three .sec_inner_port .right .laptopz{position: relative; z-index: 2;} 
.section_three .sec_inner_port .right .laptopz img{ 
    display: block; 
    margin: 0 auto;
}
.section_three .sec_inner_port .right .laps .picz{
    position: absolute; 
    z-index: 1;
    text-align: center;
    width: 510px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top:46%;
    transform: translateY(-50%);
}
.section_three .sec_inner_port .left{
    color:#000000; 
    font-size:16px; 
    width: 40%;
    position: relative;
    z-index: 9;
}
.section_three .sec_inner_port .right{ 
    width: 60%;
}
.section_three .sec_inner_port .left ul{
    margin-top:20px;
}
.section_three .sec_inner_port .left ul li{
    padding: 8px 0;
    display: block;
    color: #515151;
    font-weight: 700;
    font-size: 14px;
} 
.section_three .sec_inner_port .left ul li span{color:#F0612E;font-weight:600; display: block; padding-bottom: 5px; font-size: 18px; text-transform: uppercase;}
.section_three .sec_inner_port .left p{text-align: left;font-size: 17px; color: #515151;line-height: 25px; margin-top: 10px; font-weight: 500;}
.section_three .sec_inner_port .left p span{ font-weight: 600;}
.section_three .sec_inner_port .left .btn{ display: inline-block; padding: 14px 30px; border-radius: 60px; 
    font-size: 14px; background: #f75a1c; color: #fff; letter-spacing: .3px;text-align: center; margin: 20px 15px 20px 0;text-transform: uppercase;border: 1px solid #f75a1c;font-weight:600; letter-spacing: 1px;}
.section_three .sec_inner_port .left .btn:hover{background: #fff;color:#515151;border-color:#515151;}
.section_three .sec_inner_port .left .btn i{ color:#f75a1c; }
.section_three .sec_inner_port .left .btn i:hover{color:#fff;}

.price-btn{
    display: flex;
    justify-content: center;
    padding-top: 40px;
}
/* .full-stack .price-btn .btn-stack{
    background-color: #F0612E;
    color: #fff;
}
.full-stack .price-btn .btn-stack:hover{
    background-color: #c84313;
} */

@media(max-width:1440px){
    .banner-left h2 {
        font-size: 43px;
        line-height: 51px;
    }
    .banner-left h2 span {
        font-size: 55px;
    }
    .banner-developer-sec{
        padding-left: 80px;
    }
    .banner-feture {
        padding: 29px 0 16px;
    }
    .banner-left h2 span.color{
        font-size: 66px;
    }
    .banner-developer-sec .banner-form h3 {
        font-size: 21px;
    }
    .banner-section{
        padding: 45px 0 20px;
    }
}

@media (max-width:1366px){
    .banner-left{padding-right:20px;}
    .circle-sec h5{font-size:19px;}
    .banner-developer-sec .banner-form h3 {
        font-size: 20px;
    }
    .banner-left h2 span.color {
        font-size: 55px;
    }
    .banner-left h2 {
        font-size: 36px;
    }
    .banner-left h2 span {
        font-size: 44px;
    }
    .full-stack .header-part .header_middle ul li{
        padding: 8px 10px;
    }
}

@media (max-width:1200px){
    .circle-sec:nth-child(2n){position:relative;left:-4%;}
    .circle-sec h5{font-size:16px;}
    .banner-left{padding-right:20px;}
    .contact_ups .contact_content .ttx p br{display:none;}
    .right-sec-prt h3{font-size:31px;line-height:40px;}
    .banner-left h2 span.color {
        font-size: 50px;
    }
}

@media  ( min-width:991px) and (max-width:1250px)  {
    .full-stack-top-btn {
        padding: 12px 10px !important;
        font-size: 12px !important;
    }
    .full-stack-top-btn.call-btn-header{
        font-size: 14px !important;
    }
    .header-part .header_middle ul li .menu_items {
        font-size: 14px !important;
    }
    .header-part .header_middle ul li {
        padding: 10px 10px !important;
        letter-spacing: 0 !important;
    }
}

@media (max-width:1024px){
    .contact_ups .contact_content .frm-box-rp{margin-top:0;}
    .invertd{top:0;}
    .circle-sec h5{font-size:22px;padding:0 90px;}
    .banner-section{flex-wrap:wrap;}
    .banner-left{width:100%;padding-right:0;}
    .banner-developer-sec{width:100%;padding-left:0;margin-top:20px;}
    .text-prt h3{font-size:30px;line-height:36px;}
    .video-sec-prt{width:40%;float:left;padding-left:28px;position:relative;z-index:9;}
    .testimonial-sec-box{padding:68px 0 0;}
    .left-sec-prt{width:100%;float:none;}
    .right-sec-prt{width:100%;float:none;clear:left;padding-top:50px; text-align: center;}
    .developer-box{width:33.333%;float:left;padding:50px 15px;margin:0 1%;}
    .developer-box h4{font-size:19px;line-height:23px;}
    .developer-box svg{height:69px;}
    .pricing_scaling .head-title h2, .developer-video-slider .trusted-section .trusted-logo-box h2{
        font-size:35px;
        line-height:39px;
    }
    .developer-video-slider .trusted-section .trusted-logo-box h2{
        margin-bottom: 30px;
    }
    .pricing_scaling .bottom-bar{padding:34px 15px;}
    .pricing_scaling .divider .bottom-bar .link_up{font-size:15px;}
    .accordion-item{width:100%;margin:15px 0;float:none;}
    .contact_ups .contact_content{flex-wrap:wrap;}
    .contact_ups .contact_content .ttx{padding-right:0;}
    .contact_ups .contact_content .frm-box-rp{width:100%;max-width:100%;}
    .pricing_scaling .divider .bottom-bar li{font-size:15px;line-height:23px;}
}

@media (max-width:991px){
    .trusted-section + .trusted-section h5.technologi-heading{background-color:#1b0334;font-size:18px;}
    .accordion-half{width:100%;}
    .left-sec-prt h4{padding-right:0;}
    .sales-page .container .header-part .logo{
        width: 120px;
    }
    .banner-left .banner-feture p{
        text-align: left;
    }
    .banner-left p{
        text-align: center;
    }
    .banner-left h2{
        text-align: center;
    }
    .bnr-dflex form{
        justify-content: center;
    }
    .blue_bar .slick-slide{
        padding: 0;
    }
    .section_three .blue_bar .small_img{
        font-size: 10px;
    }
    .section_three{
        padding-top: 100px;
    }
    .header-part .header_middle ul li {
        padding: 4px 13px;
        letter-spacing: 0;
    }
    .section_three .sec_inner_port .left{
        width: 100%;
    }
    .section_three .sec_inner_port .right{
        width: 100%;
        padding-top: 20px;
    }
    .section_three .sec_inner_port{
        flex-wrap: wrap;
    }
    .section_three .sec_inner_port .right .laps{
        padding-left: 0;
    }
    .header-part .header_middle ul li .menu_items{
        font-size: 14px;
    }
    .banner-section{
        padding: 50px 0 50px;
    }
    .faq-section {
        padding-top: 80px;
    }
    .header-section .full-stack-top-btn.fill-btn {
        font-size: 14px;
        padding: 10px 18px;
    }
    .pricing_scaling .divider {
        max-width: calc(50% - 15px);
        flex-basis: calc(50% - 15px);
    }
    .pricing_scaling .wrapper {
        row-gap: 30px;
    }
    .circle-sec h5{font-size:22px;padding:0 44px;}
    .testmonial-row{width:100%;padding:0;}
    .testmonial-colum{display:block;}
    footer .item p{color:#FFF;font-size:18px;}
    footer .item h4{color:#FFF;font-size:18px;line-height:27px;}
    footer .copy-riht li a{font-size:13px;}
    footer .copy-riht p{font-size:13px;}
    footer .item:last-child{max-width:30%;flex:0 0 30%; font-size: 16px;}
    footer .item:nth-child(2){padding:0;}
    footer .item:nth-child(3){padding-left:26px;}
    footer .item{max-width:33%;flex:0 0 33%;}
}

@media (max-width:740px){
    .banner-left h2{font-size:30px;line-height:34px;}
    .section_three h2{
        font-size:35px;line-height:34px;
    }
    .section_three p{
        padding-top: 0;
    }
    .trusted-section + .trusted-section h5.technologi-heading{background-color:#5d1997;}
    footer .item:nth-child(3) p{text-align:center;}
    footer .copy-riht{padding-top:20px;}
    .banner-developer{background-color:#fff;border-radius:10px;padding:20px 10px;width:49%;text-align:center;}
    .banner-developer h5{font-size:18px;font-weight:600;padding-top:20px;}
    .banner-developer p{font-size:16px;line-height:22px;margin-top:10px;}
    .banner-developer .tag{font-size:11px;line-height:18px;padding:2px 8px;}
    .full-stack-top-btn, .header-section .full-stack-top-btn.fill-btn, 
    .header-section.fixed-header .full-stack-top-btn.fill-btn, 
    .header-section.fixed-header .full-stack-top-btn{
        padding:10px 11px;font-size:11px;
    }
    .banner-section{padding:40px 0 0;}
    .trusted-logo-box h5{padding-bottom:5px;padding-right:0;}
    .trusted-section + .trusted-section h5{font-size:18px;padding-top:0;}
    .technologies-logo div{width:33.333%;}
    .text-prt{width:100%;float:none;}
    .video-sec-prt{width:100%;float:none;padding-left:0;padding-top:30px;}
    .text-prt h3{font-size:24px;line-height:32px;}
    .circle-sec{width:48%;float:left;text-align:center;margin:0 1%;}
    .circle-sec:nth-child(2n){left:0;}
    .left-sec-prt h4{font-size:16px;}
    .right-sec-prt h3{color:#000;font-size:32px;font-style:normal;font-weight:600;line-height:44px;}
    .btn-stack{font-size:15px;padding:0 37px;}
    .developer-sec h3{font-size:26px;line-height:32px;}
    .total-three-box{flex-wrap:wrap;}
    .developer-box{width:98%;margin:30px 1% 0;}
    .pricing_scaling .divider{max-width:100%;margin-top:40px;}
    .pricing_scaling .wrapper{display:block;}
    .faq-sec-part h3{font-size:35px;line-height:40px;}
    .accordion-title{font-size:21px;}
    .contact_ups .contact_content{padding:40px 20px 40px 20px;}
    .contact_ups .contact_content .ttx h2{font-size:30px;line-height:34px;}
    .contact_ups .contact_content .ttx p{font-size:16px;line-height:24px;}
    .contact_ups .contact_content .frm-box-rp{padding:35px 0;}
    .contact_ups .contact_content .frm-box [type="submit"]{font-size:15px;}
    footer .item{max-width:100%;flex:0 0 100%;text-align:center;}
    footer .item:nth-child(2){padding-left:0;}
    footer .item .social_cont{justify-content:center;}
    footer .item li{display:inline-block;width:auto;list-style:none;}
    footer .item li a{padding:0 7px;}
    footer .copy-riht p{font-size:16px;line-height:23px;text-align:center;padding-bottom:10px;}
    footer .copy-riht ul{justify-content:center;}
    footer .copy-riht li a{line-height:23px;}
    footer .copy-riht li{margin-top:10px;}
    .right-sec-prt h3{font-size:30px;line-height:40px;}
    .pricing_scaling .bottom-bar .scr_circle h2{font-size:38px;}
    .testimonila-white-sec{padding-top:70px;padding-bottom:70px;}
    .trusted-logo-box{flex-wrap:wrap;}
    footer .item:last-child{max-width:100%;flex:0 0 100%;}
    footer .item:nth-child(3){padding-left:0;}
    .bnr-dflex input[type="email"]{width:220px;}
    .full-stack-btn{font-size:13px;padding:15px 15px;}
    .banner-left h2 span {
        font-size: 31px;
    }
    .banner-left h2 span.color {
        font-size: 40px;
    }
}

@media (max-width:600px){
    .circle-sec h5{font-size:19px;padding:0 33px;}
    .trusted-section + .trusted-section h5.technologi-heading{font-size:14px;padding:7px 20px;}
    .technologi-heading-section{margin:0 0 10px;}
    .sales-page.full-stack .container .header-part .header_right{flex-wrap:wrap;justify-content:center;align-items:center;}
    .sales-page.full-stack .container .header-part .header_right{justify-content:center;gap:20px;}
    .full-stack-top-btn.fill-btn{margin-left:0;}
    .testimonial-sec-box .review_btn .review_btns{
        padding: 0 30px;
        font-size: 14px;
        line-height: 49px;
        margin-top: 20px;
    }
    .section_three .blue_bar{
        padding: 0;
    }

    .mobile-view-change{
        display: flex;
        align-items: center;
    }
    .developer-box .mobile-view-change  h4{
        text-align: left;
        padding: 0 0 0 20px;
    }
    .developer-icon{
        margin: 0;
    }
    .developer-box p{
        text-align: left;
        padding-top: 15px;
    }
    .developer-box{
        padding: 40px 40px 20px;
    }
    .number-crcl{
        left: 14%;
    }
    .full-stack-top-btn{
        background-color: #F0612E;
        border-color: #F0612E;
    }
    .developer-sec h3 br{
        display: none;
    }
    .total-three-box{
        padding-top: 35px;
    }
    

   

    .bnr-dflex input[type="email"]{
        width: 100%;
        margin: 0;
        padding-right: 156px;
    }
    .bnr-dflex form{
        position: relative;
        display: block;
    }
    .bnr-dflex form .full-stack-btn{
        position: absolute;
        right: 4px;
        top: 3px;
        padding: 11px 10px;
        margin-top: 0;
    }
    .mobile-none{
        display: none;
    }
    .full-stack-top-btn.fill-btn.banner-full-btn{
        display: block;
        margin-top: 15px;
        text-align: center;
        background-color: #f06130;
        border-color: #f06130;
        font-size: 15px;
        color: #fff;
        padding: 10px;
    }
}

@media (max-width:575px){
    .sales-page.full-stack .container .header-part .header_right{padding-top:0; gap: 0;}
    .full-stack-btn{font-size:12px;}
    .accordion{padding-top:20px;}
    .pricing_scaling{padding:60px 0;}
    .accordion-title{font-size:17px;}
    .accordion-item{border-bottom:solid 1px #cdcdcd;}
    .faq-sec-part{padding:70px 0;}
    .circle-sec h5{font-size:16px;padding:0 23px;}
    .header-section.fixed-header .full-stack-top-btn.fill-btn, .header-section .full-stack-top-btn.fill-btn, .header-section.fixed-header .full-stack-top-btn, .full-stack-top-btn {
        font-size: 12px;
        padding: 8px 11px;
    }
    .header_right{
        margin-top: 0;
    }
    .full-stack .rating_box{
        flex-wrap: wrap;
    }
    .full-stack .reating-box{
        width: 50%;
    }
    .banner-feture p {
        font-size: 14px;
    }
    .full-stack .reating-box{
        padding-left: 40px;
    }
    .section_three {
        padding-top: 70px;
    }
    .hair-designer .full-stack-top-btn {
        font-size: 11px;
        padding: 10px 11px;
    }
}

@media (max-width:480px){
    .circle-sec h5{font-size:13px;padding:0 19px;}
    .btn-stack{
        font-size: 14px;
        padding: 0 24px;
        line-height: 50px;
    }
    .banner-developer-sec .banner-form h3 {
        font-size: 18px;
    }
    .banner-left h2 span.color {
        font-size: 33px;
    }
    .banner-left h2 span {
        font-size: 28px;
    }
    .banner-left h2 {
        font-size: 26px;
        line-height: 34px;
    }
    .banner-feture .banner-first-feture, .banner-second-feture{
        width: 100%;
        border-right: 0;
        padding-left: 0;
    }
    
}

@media (max-width:400px){
    .header-section.fixed-header .full-stack-top-btn.fill-btn, .header-section .full-stack-top-btn.fill-btn, .full-stack-top-btn {
        font-size: 10px;
        padding: 8px 9px;
    }
    .bnr-dflex form{
        display: block;
    }
    .bars-menu{
        margin-left: 7px;
    }
    .full-stack .banner-left h2 {
        font-size: 20px;
    }
    .full-stack .banner-left h2 span {
        font-size: 26px;
    }
    .full-stack .banner-left h2 span.bg{
        padding: 1px 5px;
    }
    .full-stack-top-btn, .header-section .full-stack-top-btn.fill-btn, .header-section.fixed-header .full-stack-top-btn, .header-section.fixed-header .full-stack-top-btn.fill-btn{
        font-size: 10px;
        padding: 8px 9px;
    }
    .banner-left p{
        font-size: 19px;
        line-height: 25px;
    }
    .banner-left .banner-feture p{
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
    }
    .hair-designer .full-stack-top-btn {
        font-size: 10px !important;
        padding: 8px 11px !important;
    }
}

.accordion-item-body{max-height:0;overflow:hidden;transition:max-height 0.2s ease-out;}
.slide-enter{transform:translateY(-100%);opacity:0;}
.slide-enter-active{transform:translateY(0);opacity:1;transition:transform 300ms ease-in-out,opacity 300ms ease-in-out;}
.slide-exit{transform:translateY(0);opacity:1;}
.slide-exit-active{transform:translateY(100%);opacity:0;transition:transform 300ms ease-in-out,opacity 300ms ease-in-out;}