.exit-modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.exit-modal .modal-content{
    max-width: 500px;
    flex: 1;
    margin: 10px;
    max-height: 95vh;
    overflow-y: auto;
    background-color: #fff;
    text-align: left;
    border-radius: 5px;
    position: relative;
}
.exit-modal h4{
    color: #fff;
    font-family: Patua One;
    font-size: 24px;
    padding-top: 10px;
    font-weight: 200;
    letter-spacing: 1px;
}
.exit-modal h3{
    color: #fff;
    font-family: Patua One;
    font-size: 34px;
    font-weight: 200;
    letter-spacing: 1px;
    padding-bottom: 3px;
}
.exit-modal p{
    font-size: 16px;
    line-height: 20px;
    padding-top: 15px;
    color: #fff;
    
}
.exit-modal-top{
    background: linear-gradient(45deg,#d3071a 10%,#0368b5);
    padding: 20px 40px 20px 20px;
    border-radius: 5px 5px 0 0;
}
.exit-modal .schedule_consultation-box{
    padding: 20px;
}
.exit-modal .default-btn{
    font-size: 13px;
    background-color: #F0612E;
    color: #fff;
    line-height: 25px;
    padding: 8px 20px 8px 25px;
    border-radius: 30px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
    cursor: pointer;
    margin-right: 15px;
}
.exit-modal .default-btn:hover{
    background-color: #f04e13;
}
.download-btn{
    font-size: 13px;
    background-color: #6946b9;
    color: #fff;
    line-height: 25px;
    padding: 8px 20px 8px 25px;
    border-radius: 30px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
    cursor: pointer;
}

.download-btn:hover{
    background-color: #4f2e9a;
}

.exit-modal-btns{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.exit-modal .close{
    align-items: center;
    -webkit-appearance: none;
    background: #6946b9;
    background: linear-gradient(45deg,#d3071a 10%,#0368b5);
    border: 0;
    border-radius: 0 6px 0 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    filter: alpha(opacity=20);
    float: right;
    font-size: 26px;
    font-weight: 500;
    height: 30px;
    justify-content: center;
    line-height: 1;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 2px;
    text-shadow: none;
    top: 2px;
    width: 30px;
    z-index: 9;
}

.exit-modal .form-group label{
    display: inline-block;
    font-family: Patua One;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0;
    max-width: 100%;
}
.exit-modal .form-group{
    padding-bottom: 10px;
    padding-top: 10px;
}
.exit-modal .form-group input{
    appearance: none;
    background-color: #fff;
    border: 1px solid transparent;
    border-bottom-color: #000;
    border-radius: 0;
    box-shadow: none;
    color: #757575;
    display: block;
    font-family: Assistant,sans-serif;
    font-size: 15px;
    font-weight: 400;
    height: 38px;
    padding: 0;
    position: relative;
    width: 100%;
}

.exit-modal .form-group textarea {
    appearance: none;
    background-color: #fff;
    border: 1px solid transparent;
    border-bottom-color: #000;
    border-radius: 0;
    box-shadow: none;
    color: #757575;
    display: block;
    font-family: Assistant,sans-serif;
    font-size: 15px;
    font-weight: 400;
    height: 38px;
    padding: 0;
    position: relative;
    width: 100%;
    resize: none;
}

.exit-modal .stay-pge-btn{
    font-size: 14px;
    background-color: #F0612E;
    color: #fff;
    padding: 10px 15px;
    margin: 20px 5px 0;
    border-radius: 30px;
    width: 150px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
}

.exit-modal .leave-pge-btn{
    font-size: 14px;
    background-color: #20c0a0;
    color: #fff;
    padding: 10px 15px;
    margin: 20px 5px 0;
    border-radius: 30px;
    width: 150px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
}