.landibgfullstack .banner {
    min-height: calc(100vh - 87px);
    padding-bottom:20px;
    background: #1b0334;
    background: radial-gradient(circle,rgb(72 21 10 / 81%) 0,#101010 68%) !important;
}

.landibgfullstack .trusted-section + .trusted-section h5.technologi-heading { background-color: #784a40;}
.landibgfullstack .banner-left h4{font-size: 20px; line-height: 25px; font-weight: 400; color: #fff; padding-bottom: 20px; font-weight: 600;} 
.landibgfullstack .banner-left h4 span{color: #ff662a; font-weight:600;}
.landibgfullstack .banner-left h3{font-size: 28px; line-height: 35px; font-weight: 400; color: #fff;} 
.landibgfullstack .banner-left h2{font-size:58px; line-height: 66px; font-weight:700; color: #fff; padding-bottom: 20px;} 
.landibgfullstack .banner-left h2 span{ color: #ff662a;font-size:60px; line-height: 75px; font-weight:700;}
.landibgfullstack .banner-left ul { padding-bottom: 25px;}
.landibgfullstack .banner-left ul li { font: 400 14px/18px 'Montserrat', sans-serif;  padding: 10px 0 0 25px;
    background: url(../../Assets/Images/landing-page-fullstack/orange-check-mark.png) no-repeat 0 12px;  width: 100%;  color: #fff;  text-align: left;}
.landibgfullstack .banner-left .image-text {
    display: inline-block;
    background-color: #F0612E;
    border-radius: 10px 10px 10px 0;
    padding: 1px 5px;
    margin-left: 5px;
    color: #fff;
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
}
.landibgfullstack .banner-left p{font-size:18px; line-height:22px; font-weight:400; color: #fff; padding-bottom: 20px;} 
.landibgfullstack .banner-left .banner-location {
    border-color: #56565694!important; padding: 20px;
    border-radius: 10px;
    background: #ffffff14;
}

.landibgfullstack .get_experts_total{ display: flex;}
.landibgfullstack .get_experts_left{ width: 40%; padding-right: 50px;}
.landibgfullstack .get_experts_right{ width: 60%; display: flex; flex-wrap: wrap;}
.landibgfullstack .get_experts_right_box{ width:46%; margin:30px 2% 0 2%;-webkit-box-shadow: 0 2.8125rem 3.75rem 0 rgba(34, 34, 34, .1); box-shadow: 0 2.8125rem 3.75rem 0 rgba(34, 34, 34, .1); background-color: #fff; padding: 20px;}

.landibgfullstack .number_count{display: flex; flex-wrap: wrap;}
.landibgfullstack .number_count > div{ width: 50%; padding-right: 20px; margin:10px 0;}

.landibgfullstack  .get_experts{ padding:90px 0;}
.landibgfullstack  .get_experts h2{ font-size: 30px; line-height: 37px; color: #000; font-family:'Montserrat', sans-serif ;}
.landibgfullstack  .get_experts p.line{ font-size: 16px; line-height: 24px; color: #000; font-family:'Montserrat', sans-serif ; font-weight: 400; padding: 20px 0; border-bottom: 1px solid #ff662a;}
.landibgfullstack .number_count{ margin-top: 20px;}

.landibgfullstack  .get_experts_left h3{ font-size: 25px; line-height: 30px; color: #000; font-family:'Montserrat', sans-serif ;}
.landibgfullstack  .get_experts p{ font-size: 16px; line-height: 24px; color: #000; font-family:'Montserrat', sans-serif ; font-weight: 400; padding-top: 10px;}

.landibgfullstack .requerment_btn{ font-size: 16px; line-height: 50px; font-family:'Montserrat', sans-serif ; font-weight: 600; display: inline-block; padding:0 40px; border-radius: 50px; margin-top: 40px; cursor: pointer; background-color: #ff662a; color: #fff; text-transform: uppercase;}
.landibgfullstack  .section_three{ padding: 0 !important;}
.landibgfullstack .get_experts_right_box_top{ display: flex; align-items: center;}
.landibgfullstack .get_experts_right_box_top h3{ font-size:22px; line-height:27px; font-family:'Montserrat', sans-serif ; color: #000; padding-left: 15px;}

.landibgfullstack .get_experts_right_box ul{ padding-top: 20px;}
.landibgfullstack .get_experts_right_box ul li{ font: 400 16px/20px 'Montserrat', sans-serif;  padding: 10px 0 0 25px; background: url(../../Assets/Images/landing-page-fullstack/bluearrow.png) no-repeat 0 12px;  width: 100%;  color: #000;}


.landibgfullstack .offering_allbox{ display: flex; flex-wrap: wrap; justify-content: center; padding-top: 30px;}
.landibgfullstack .offering_allbox > div{ width: 14.66%; margin: 20px 1% 0 1%; background-color: #fff; padding: 20px;}

.landibgfullstack .offering{ background-color: #f9f9f9; padding: 90px 0; text-align: center;}
.landibgfullstack .offering_allbox > div:hover{ -webkit-box-shadow: 0 2.8125rem 3.75rem 0 rgba(34, 34, 34, .1);
    box-shadow: 0 2.8125rem 3.75rem 0 rgba(34, 34, 34, .1);}
.landibgfullstack .offering h3{ font-size:28px; line-height:34px; font-family:'Montserrat', sans-serif ; color: #000;}
.landibgfullstack .offering h3 span{ color: #ff662a;}
.landibgfullstack .offering .offering_allbox h3{ 
    color: #000;
    font-family: Montserrat,sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-top: 15px;
}
.landibgfullstack .offering p{ font-size: 16px; line-height: 24px; color: #000; font-family: 'Montserrat', sans-serif; font-weight: 400; padding: 15px 0 0;}

.landibgfullstack .why_redensoft{ padding: 90px 0;}
.landibgfullstack .why_redensoft h2{ font-size: 50px; line-height:60px; font-weight: 700; color: #000;}
.landibgfullstack .why_redensoft h2 span{ color: #ff662a;}
.landibgfullstack .why_redensoft_total{ display: flex;}

.landibgfullstack .why_redensoft_left{ width: 50%;}
.landibgfullstack .why_redensoft .why_redensoft_left h3{ font-size:20px; line-height:25px; font-weight:600; color: #000; position: relative; padding-left:32px;}
.landibgfullstack .why_redensoft .why_redensoft_left h3:after{ position: absolute; background: url(../../Assets/Images/landing-page-fullstack/orange_tick.png) no-repeat 0 0; content: ''; width:32px; height:32px; left: 0; top: 2px;background-size: 1.3125rem;}
.landibgfullstack .why_redensoft .why_redensoft_left p{ font-size: 16px; line-height: 24px; color: #000; font-family: 'Montserrat', sans-serif; font-weight: 400; padding: 10px 0 0;}
.landibgfullstack .why_redensoft_left_allbox{ display: flex; flex-wrap: wrap;}
.landibgfullstack .why_redensoft_left_allbox > div{ width: 50%; padding-right: 25px; margin-top: 40px;}
.landibgfullstack .why_redensoft .why_redensoft_right_img{ margin-left: 24.66666667%; position: relative;}
.landibgfullstack .why_redensoft .man_img_box img  {
    border-radius: 50px;
}

.fullstack_banner_ul{ display: flex;}
.fullstack_banner_ul ul+ul{ padding-left: 50px;}



.landibgfullstack .rating-box{   background: linear-gradient(193deg, rgba(101,172,233,1) 0%, rgba(236,97,0,1) 100%) !important; position: absolute; width: 250px; box-shadow: 0px 20px 36px #00000014;  border-radius: 12px;
    top: 140px;  left: -25%; padding: 20px;}
.landibgfullstack .rating-box h5{ font-size: 20px; line-height: 25px; font-family: 'Montserrat', sans-serif; color: #fff;}
.landibgfullstack .why_redensoft .why_redensoft_left .rating-box p{ font-size: 20px; line-height: 25px; font-family: 'Montserrat', sans-serif; font-size: 300; color: #fff;}
.landibgfullstack .why_redensoft .why_redensoft_left .rating-box .text-white{ padding-bottom: 27px;}

.landibgfullstack .priceingallbox{ display: flex;}
.landibgfullstack .priceing_box{ width: 33.33%; background-color: #fff; padding-bottom: 60px; border-radius: 30px; margin-top: 60px; display: flex; flex-direction: column;} 
.landibgfullstack .priceing_table{ background: #f9f9f9; padding: 90px 0;}
.landibgfullstack .priceing_table h2{ text-align: center; font-size: 50px; line-height: 55px; font-weight: 700; padding-bottom: 0px;}
.landibgfullstack .priceing_table h2 span{ color: #ff662a;;}
.landibgfullstack .priceing_table .priceing_box h2{ text-align: center; font-size: 25px; line-height:30px; font-weight: 700; color: #ff662a;}
.landibgfullstack .priceing_table .priceing_box p{ text-align: center; font-size:18px; line-height:25px; font-weight: 700;}
.landibgfullstack .box-radius{box-shadow: inset 0px 1px 4px #00000029, 0px 30px 30px #0000000F; padding: 40px 0;     border-bottom-left-radius: 50%; border-bottom-right-radius: 50%; border-top-left-radius: 20px; border-top-right-radius: 20px;}
.landibgfullstack .priceing_table .priceing_box ul li{ font: 400 16px/20px 'Montserrat', sans-serif; background:url(../../Assets/Images/landing-page-fullstack/bluearrow.png) no-repeat 0 17px; padding: 14px 0 0 20px;}
.landibgfullstack .priceing_table .priceing_box ul{ width:84%; margin:25px auto 0;}

.landibgfullstack .priceing_btn {
    font-size: 16px;
    line-height: 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    display: inline-block;
    padding: 0 40px;
    border-radius: 50px;
    margin-top: 40px;
    cursor: pointer;
    background-color: #ff662a;
    color: #fff; margin:auto auto 0; display: block;
}

.landibgfullstack .priceing_box:nth-child(2) .box-radius{ background-color: #ff662a;}
.landibgfullstack .priceing_box:nth-child(2) h2{ color: #fff;}
.landibgfullstack .priceing_box:nth-child(2) p{ color: #fff;}
.landibgfullstack .priceing_box:nth-child(2){transform: scale(1.05); z-index: 9 !important;    box-shadow: 5px 5px 30px #00000014; overflow: hidden;}

.landibgfullstack .priceing_box:nth-child(2) .box-radius{ position: relative;}
.landibgfullstack .popular-badge-2 {
    font-size: 16px;
    color: #ff662a;
    background: #ffffff;
    padding: 3px 21px;
    position: absolute;
    right: -30px;
    transform: rotateZ(45deg);
    top: 20px;
    width: 130px;
    text-align: center;
    z-index: 1;
}

.landibgfullstack .priceing_table .priceing_box ul.deactive-list li{color: #ccc; padding-left: 0 !important; background-image: none !important; padding: 0 !important; padding-top: 10px !important;}
.landibgfullstack .priceing_table .priceing_box ul+ul+ul{ margin-top: 10px!important; margin-bottom: 50px;}

.landibgfullstack .hire_experience h2{font-size:36px; line-height:45px; font-weight: 700;}
.landibgfullstack .hire_experience h2 span{ color: #ff662a;}
.landibgfullstack .hire_experience ul{ padding-top: 30px;}
.landibgfullstack .hire_experience_total{ display: flex;}
.landibgfullstack .hire_experience ul li{    font: 400 16px/20px 'Montserrat', sans-serif;
    padding: 10px 0 10px 30px; background: url(../../Assets/Images/landing-page-fullstack/orange_tick.png) no-repeat 0 10px; width: 100%; background-size: 1.3125rem;  color: #000;}
.landibgfullstack .hire_experience .hire_experience_left{ width: 45%;}
.landibgfullstack .hire_experience .hire_experience_left+.hire_experience_left{ width: 55%;}
.landibgfullstack .hire_experience .hire_experience_left+.hire_experience_left{ padding-left: 60px;}
.landibgfullstack .hire_experience{ padding: 90px 0;}

.landibgfullstack .faq_total{ display: flex;}
.landibgfullstack .faq_left{ width: 25%;}
.landibgfullstack .faq_rifgt{ width: 75%;}
.landibgfullstack .accordion-half{ width: 100%;}
.landibgfullstack .accordion{ padding-top: 0;}
.landibgfullstack .faq_sec{ padding: 90px 0;}
.landibgfullstack .accordion-item{ margin-bottom: 20px;}
.landibgfullstack .accordion-title{ padding: 5px 25px 5px 0;}

.landibgfullstack .fullstack_bg{     color: #fff;
    background-color: #F0612E;
    border-radius: 5px;
    line-height: 95% !important;
    display: inline-block;}

.landibgfullstack .faq_total h3{font-size:35px; line-height:40px; font-weight: 700; color: #000;}
.landibgfullstack .faq_total h5{font-size:25px; line-height:30px; font-weight: 700; color: #ff662a;  padding-top: 25px;}

.landibgfullstack .hiredesigner_batch  { padding-top: 40px;  display: flex; flex-wrap: wrap; margin: auto; max-width: initial;}

.landibgfullstack .hiredesigner_batch .hiredesigner_batchsub  { width: 33.33%; padding: 10px; }
.landibgfullstack .pricing_scaling .divider .bottom-bar .link_up, .landibgfullstack .btn-stack{
    background-color: #ff662a;
    border-color: #ff662a;
    color: #fff;
}


.landibgfullstack .pricing_scaling .divider .bottom-bar .link_up:hover, .landibgfullstack .btn-stack:hover{
    background-color: #20c0a0;
    color: #fff;
    border-color: #20c0a0;
}

.landibgfullstack .fill-btn {
    background-color: #20c0a0;
    border-color: #20c0a0;
    color: #000;
    margin-left: 10px;
}
.landibgfullstack .container .header-part .header_right a:not(:last-child){ margin-right: 0 !important;}

.landibgfullstack .header-part .header_middle ul li {
    padding: 8px 10px;
}

@media (max-width:1200px)  {
    .landibgfullstack .hiredesigner_batch {
        padding-top: 20px;
    }
    .trusted-section {
        padding: 0px 0 0;
    }
    .landibgfullstack .hire_experience .hire_experience_left+.hire_experience_left {
        padding-left: 15px;
    }
    .landibgfullstack .hire_experience h2 {
        font-size: 40px;
        line-height: 48px;
    }
    .landibgfullstack .faq_left {
        width: 36%;
    }
    .landibgfullstack .faq_rifgt {
        width: 64%;
    }
    .landibgfullstack .faq_rifgt .accordion-item {
        margin: 0px 0;
    }
    .landibgfullstack .faq_rifgt .accordion-item:not(:last-child)  {
        margin-bottom: 20px;
    }
}

@media (max-width:991px)  {
    .landibgfullstack .banner-left h4 {
        padding-bottom: 15px;
        text-align: center;
    }
    .landibgfullstack .banner-left h2 {
        font-size: 54px;
        line-height: 62px;
    }
    .landibgfullstack .banner-left h3 {
        font-size: 25px;
        text-align: center;
    }
    .landibgfullstack .banner-left ul li {
        font: 400 15px/18px 'Montserrat', sans-serif;
    }
    .landibgfullstack .banner-left .banner-location span {
        padding-top: 6px;
    }
    .landibgfullstack .banner-section {
        padding: 50px 0 20px;
    }
    .landibgfullstack .trusted-section + .trusted-section {
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .landibgfullstack  .get_experts {
        padding: 80px 0;
    }
    .landibgfullstack .get_experts_left,
    .landibgfullstack .get_experts_right  {
        width: 100%;
        padding-right: 0px;
    }
    .landibgfullstack .get_experts_total {
        flex-wrap: wrap;
    }
    .landibgfullstack  .get_experts h2 {
        text-align: center;
    }
    .landibgfullstack  .get_experts p.line {
        font-size: 15px;
        padding: 15px 0;
        text-align: center;
    }
    .landibgfullstack .number_count > div {
        margin: 0px 0;
        padding: 0;
    }
    .landibgfullstack .number_count_sub  {
        padding: 10px !important;
    }
    .landibgfullstack .number_count_sub .number_count_text  {
        box-shadow: 0 2.8125rem 3.75rem 0 rgba(34, 34, 34, .1);
        background-color: #fff;
        padding: 20px 15px;
        border-radius: 5px;
        text-align: center;
    }
    .landibgfullstack .requerment_btn {
        margin: 30px auto 0 auto;
        display: flex;
        justify-content: center;
    }
    .landibgfullstack .offering_allbox > div {
        width: 24%;
        margin: 20px 0.5% 0 0.5%;
        padding: 20px;
    }
    .landibgfullstack .offering,
    .landibgfullstack .why_redensoft,
    .landibgfullstack .priceing_table,
    .landibgfullstack .hire_experience,
    .landibgfullstack .faq_sec {
        padding: 70px 0;
    }
    .landibgfullstack .why_redensoft_left {
        width: 100%;
    }
    .landibgfullstack .why_redensoft_total {
        display: flex;
        flex-wrap: wrap;
    }
    .landibgfullstack .why_redensoft h2 {
        font-size: 30px;
        text-align: center;
    }
    .landibgfullstack .why_redensoft .why_redensoft_right_img {
        padding-top: 30px;
    }
    .landibgfullstack .priceing_table h2 {
        font-size: 30px;
        line-height: 36px;
        padding-bottom: 30px;
    }
    .landibgfullstack .priceingallbox {
        flex-wrap: wrap;
        justify-content: center;
    }
    .landibgfullstack .priceing_box {
        width: 50%;
        padding-bottom: 15px;
        margin-top: 30px;
    }
    .landibgfullstack .priceing_box {
        width: 48%;
        padding-bottom: 15px;
        margin: 1%;
    }
    .landibgfullstack .priceing_box:nth-child(2) {
        transform: inherit;
    }
    .landibgfullstack .hire_experience_total {
        flex-wrap: wrap;
    }
    .landibgfullstack .hire_experience .hire_experience_left  {
        width: 100%;
    }
    .landibgfullstack .hire_experience .hire_experience_left img  {
        width: 44%;
        margin: 0 auto 30px auto;
        display: flex;
    }
    .landibgfullstack .hire_experience h2 {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
    }
    .landibgfullstack .hire_experience_left button  {
        margin: inherit;
        margin-top: 30px;
    }
    .landibgfullstack .faq_left,
    .landibgfullstack .faq_rifgt  {
        width: 100%;
    }
    .landibgfullstack .faq_total  {
        flex-wrap: wrap;
    }
    .landibgfullstack .faq_total h5 {
        font-size: 22px;
        line-height: 19px;
        padding-top: 15px;
        padding-bottom: 30px;
    }
    .landibgfullstack .hire_experience .hire_experience_left+.hire_experience_left {
        padding-left: 0px; width: 100%;
    }
}

@media (max-width:767px)  {
    .landibgfullstack .banner-left h4 {
        font-size: 18px;
    }
    .landibgfullstack .banner-left h2 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 900;
    }
    .landibgfullstack .banner-left h2 span {
        color: #ff662a;
        font-size: 30px;
        line-height: 30px;
        font-weight: 700;
    }
    .landibgfullstack .banner-left h3 {
        font-size: 19px;
        line-height: 26px;
    }
    .landibgfullstack .banner-left ul li {
        font: 400 14px/16px 'Montserrat', sans-serif;
    }
    .landibgfullstack .banner-left p {
        font-size: 15px;
        padding-bottom: 15px;
    }
    .landibgfullstack .banner-left ul {
        padding-bottom: 15px;
    }
    .banner-left .banner-location span {
        font-size: 14px;
    }
    .landibgfullstack .offering,
    .landibgfullstack .why_redensoft,
    .landibgfullstack .priceing_table,
    .landibgfullstack .hire_experience,
    .landibgfullstack .faq_sec {
        padding: 60px 0;
    }
    .landibgfullstack .get_experts_right_box {
        width: 100%;
        margin: 30px 0% 0 0%;
        padding: 30px 30px;
    }
    .landibgfullstack .get_experts_right_box_top h3 {
        font-size: 20px;
    }
    .landibgfullstack .offering h3 {
        font-size: 23px;
        line-height: 32px;
    }
    .landibgfullstack .offering p {
        font-size: 16px;
        padding: 10px 0 0;
    }
    .landibgfullstack .offering_allbox {
        padding-top: 20px;
    }
    .landibgfullstack .offering_allbox > div {
        width: 48%;
        margin: 20px 1% 0 1%;
        padding: 20px 10px;
    }
    .landibgfullstack .why_redensoft h2 {
        font-size: 28px;
        line-height: 36px;
    }
    .landibgfullstack .why_redensoft_left_allbox > div {
        width: 100%;
        padding-right: 0px;
        margin-top: 30px;
    }
    .landibgfullstack .priceing_table h2 {
        font-size: 28px;
        line-height: 36px;
        padding-bottom: 28px;
    }
    .landibgfullstack .priceing_box {
        width: 100%;
        padding-bottom: 30px;
        margin: 0%;
    }
    .landibgfullstack .priceing_box:nth-child(2) {
        margin-bottom: 30px;
    }
    .landibgfullstack .hire_experience .hire_experience_left img {
        width: 70%;
    }
    .landibgfullstack .hire_experience h2 {
        font-size: 28px;
        line-height: 36px;
    }
    .landibgfullstack .faq_total h3 {
        font-size: 35px;
        line-height: 40px;
        text-align: center;
    }
    .landibgfullstack .faq_total h5 {
        font-size: 20px;
        line-height: 24px;
        padding-bottom: 20px;
        text-align: center;
        font-weight: 500;
    }
    .fullstack_banner_ul{ flex-wrap: wrap;}
    /* .landibgfullstack .banner-left ul li{ padding-left: 0;} */
    .fullstack_banner_ul ul+ul{ padding-left: 0;}

    .landibgfullstack .hire_experience_left button{
        margin: 30px auto;
    }
}