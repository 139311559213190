.sales-page .priceing_table .priceingallbox .priceing_box .deactive-list li svg path  {
    fill: #fc4a4a;
}
.container .header-part .header_right a:not(:last-child)  {
    margin-right: 10px;
}

.container .header-part .header_right .header_background_btn {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 28px;
    color: #f5f5f5;
    font-weight: 700;
    border-radius: 26px;
    padding: 12px 27px;
    background-image: linear-gradient(88deg, #df6541 0%, #678bcb 100%);
}

.container .header-part .header_right .header_border_btn  {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 28px;
    color: #3b3f48;
    font-weight: 700;
    border-radius: 26px;
    padding: 10px 40px;
    background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #df6541, #688bcb) border-box;
    border-radius: 50em;
    border: 2px solid transparent;
    transition: all ease-in-out .3s;
    cursor: pointer;
}

.container .header-part .header_right .header_border_btn:hover  {
    background: #f07651;
    border: 2px solid #f07651;
    color: #f5f5f5;
}

.designer_banner  {
    padding: 30px 15px 100px;
    position: relative;
}

.hair-designer .banner-left h3  {
    font-size: 36px;
    letter-spacing: -2px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    text-align: center;
    padding-bottom: 0px;
}

.hair-designer .banner-left h2 {
    font-size: 22px;
    letter-spacing: -1px;
    line-height: 40px;
    color: #f5f5f5;
    font-weight: 500;
    border-radius: 0 30px 0 30px;
    filter: drop-shadow(3.5px 5.794px 0px #ba5031);
    background-color: #dd6643;
    padding: 3px 26px;
    margin-bottom: 30px;
    display: inline-block;
}

.designer_banner .designer_banner_container  {
    display: flex;
    justify-content: center;
    margin: auto;
    max-width: 1820px;
    width: 100%;
}

.designer_banner .designer_text_box  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    width: 50%;
}

.designer_banner .designer_text_box h2  {
    font-size: 22px;
    letter-spacing: -1px;
    line-height: 40px;
    color: #f5f5f5;
    font-weight: 500;
    border-radius: 0 30px 0 30px;
    filter: drop-shadow(3.5px 5.794px 0px #ba5031);
    background-color: #dd6643;
    padding: 3px 26px;
    margin-bottom: 30px;
}

.designer_text_box .designer_price p  {
    font-size: 22px;
    line-height: 28px;
    padding-top: 15px;
    text-align: center;
    padding-bottom: 15px;
}

.designer_text_box .designer_price p span  {
    color: #fd7a2e;
    font-weight: 600;
}

.designer_banner .designer_text_box h1  {
    font-size: 36px;
    letter-spacing: -2px;
    text-transform: uppercase;
    color: #000000;
    font-weight: 600;
    text-align: center;
    padding-bottom: 0px;
}

.designer_option .banner-feture {
    padding: 15px 0 10px 0;
}

.designer_option .banner-feture {
    flex-wrap: wrap;
}

.designer_option .banner-first-feture {
    border-right: 1px dashed #000;
    display: flex;
    margin-bottom: 15px;
    padding-right: 20px;
    width: 50%;
}

.designer_option .banner-second-feture {
    margin-bottom: 15px;
}

.designer_option .banner-feture p {
    background-image: url(../../Assets/Images/bestdesigner/right.png);
    background-position: 0 2px;
    background-repeat: no-repeat;
    background-size: 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    padding: 2px 0 2px 26px;
}

.designer_option .banner-feture p .image-text {
    background-color: #f0612e;
    border-radius: 10px 10px 10px 0;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    margin-left: 5px;
    padding: 1px 5px;
}

.designer_client .banner-location {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 0px;
    padding-top: 0;
}

.designer_client .banner-location span {
    color: #000;
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding-top: 5px;
}

.rating_section .rating_box {
    display: flex;
    justify-content: space-between;
    padding: 15px 0 30px;
}

.rating_section .rating_box .sub_box {
    padding: 10px;
}

.designer_banner .designer_text_box h1 span  {
    display: block;
    font-size: 70px;
    letter-spacing: -2px;
    text-transform: uppercase;
    color: #000000;
    font-weight: 700;
}

.designer_banner .designer_text_box a  {
    border-radius: 30px;
    filter: drop-shadow(2px 3.464px 28.5px rgba(95,128,193,0.49));
    background-image: linear-gradient(88deg, #df6541 0%, #678bcb 100%);
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 40px;
    text-transform: uppercase;
    color: #f8f8f8;
    font-weight: 700;
    padding: 10px 49px;
    transition: all ease-in-out .3s;
}

.designer_banner .designer_text_box a:hover  {
    background: #f07651;
}

.designer_banner .designer_image_div  {
    position: relative;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    display: none;
}

.designer_banner .designer_image_div .designer_imageOne  {
    position: relative;
}

.designer_banner .designer_image_div .designer_imageOne .profile_icon  {
    position: absolute;
    top: 0;
    right: -10%;
}

.designer_banner .designer_image_div .designer_imageTwo  {
    position: relative;
}

.designer_banner .designer_image_div .designer_imageTwo .profile_icon  {
    position: absolute;
    top: 56%;
    left: -16%;
}

.designer_banner .designer_image_div .designer_imageThree  {
    position: relative;
}

.designer_banner .designer_image_div .designer_imageThree .profile_icon  {
    position: absolute;
    right: -15%;
    bottom: 25%;
}

.designer_banner .designer_image_div .designer_imageFour  {
    position: relative;
}

.designer_banner .designer_image_div .designer_imageFour .profile_icon  {
    position: absolute;
    left: -28%;
    bottom: 25%;
}

.designer_banner .designer_imageOne .banner_box_img,
.designer_banner .designer_imageTwo .banner_box_img,
.designer_banner .designer_imageThree .banner_box_img,
.designer_banner .designer_imageFour .banner_box_img  {
    width: 90%;
    display: block;
}

.designer_banner .designer_imageThree .banner_box_img,
.designer_banner .designer_imageFour .banner_box_img  {
    margin-left: auto;
}

.expert_webdesigner  {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.expert_webdesigner .expert_webdesigner_iconbox  {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.expert_webdesigner .expert_webdesigner_icon  {
    width: 70px;
    height: 70px;
    background: #f07651;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-top: -35px;
}

.expert_webdesigner .expert_webdesigner_icon img  {
    width: 46%;
}

.expert_webdesigner .expert_webdesigner_flex  {
    display: flex;
    padding: 160px 0;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left,
.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right  {
    width: 50%;
    padding: 0 15px;
    z-index: 1;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h2  {
    font-size: 50px;
    letter-spacing: -4px;
    line-height: 58px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    padding-bottom: 10px;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h3  {
    font-size: 66px;
    letter-spacing: -3px;
    line-height: 66px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    background-color: #dd6643;
    padding: 0px 10px;
    display: inline-block;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h4  {
    font-size: 36px;
    letter-spacing: -2px;
    line-height: 48px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 600;
    padding: 20px 0 46px 0;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left p  {
    font-size: 19px;
    line-height: 32px;
    color: #ffffff;
    padding-bottom: 52px;
    max-width: 568px;
}
.hair-designer .three_section{
    margin-top: 0;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left a  {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 28px;
    color: #f5f5f5;
    font-weight: 700;
    border-radius: 38px;
    filter: drop-shadow(2px 3.464px 28.5px rgba(95,128,193,0.49));
    background-image: linear-gradient(88deg, #df6541 0%, #678bcb 100%);
    transition: all ease-in-out .3s;
    padding: 20px 58px;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left a:hover  {
    background: #f07651;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width  {
    padding-left: 100px;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box  {
    background-color: #051532;
    border: 1px solid #223a65;
    padding: 20px;
    display: flex;
    width: 100%;
    border-radius: 20px;
    transition: all ease-in-out .3s;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box:not(:last-child)  {
    margin-bottom: 20px;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .icon  {
    width: 92px;
    height: 92px;
    background: #46669e;
    border-radius: 10px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out .3s;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .icon img  {
    filter: brightness(0) invert(1);
    transition: all ease-in-out .3s;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .text  {
    width: calc(100% - 92px);
    padding-left: 30px;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .text h3  {
    font-size: 22px;
    line-height: normal;
    color: #fff;
    font-weight: 700;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .text p  {
    font-size: 15px;
    line-height: 26px;
    color: #ffffff;
    padding-top: 4px;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box:hover  {
    background: #688bca;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box:hover .icon  {
    background: #fff;
}

.expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box:hover .icon img  {
    filter: none;
}

.facts_section {
    padding-top: 80px;
    padding-bottom: 20px;
    border-bottom: 2px dashed #d6d6d6;
    background-color: #fff;
}

.facts_section .heading  {
    text-align: center;
}

.facts_section .heading h3  {
    font-size: 26px;
    letter-spacing: -1px;
    text-transform: uppercase;
    /* color: #3b3b3b; */
    font-weight: 700;
    padding-bottom: 20px;
    line-height: 24px;
}

.facts_section .heading h2  {
    font-size: 80px;
    letter-spacing: -1px;
    text-transform: uppercase;
    /* color: #fff; */
    font-weight: 700;
    line-height: 67px;
    padding-bottom: 50px;
}

.facts_section .facts_number .facts_flex  {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 80px;
}

.facts_section .facts_number .facts_flex .facts_sub  {
    width: 25%;
    text-align: center;
    padding: 15px 0px;
}

.facts_section .facts_number .facts_flex .facts_sub:not(:last-child)  {
    border-right: 2px dashed #d6d6d6;
}

.facts_section .facts_number .facts_flex .facts_sub h2  {
    font-size: 66px;
    line-height: 60px;
    color: #000000;
    font-weight: 700;
    background: -webkit-linear-gradient(300deg, #ff0704 0%, #f5b503 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 10px;
}

.facts_section .facts_number .facts_flex .facts_sub p  {
    font-size: 16px;
    line-height: 21px;
    color: #3b3b3b;
    font-weight: 700;
}

.elevated_section  {
    padding: 150px 0 150px 0;
}

.elevated_section .elevated_flex  {
    display: flex;
}

.elevated_section .elevated_sub_leftbox  {
    width: 60%;
    padding: 0 15px;
    position: relative;
}

.elevated_section .elevated_sub_rightbox  {
    width: 40%;
    padding: 0 15px;
}

.elevated_section .elevated_sub_leftbox .elevated_sub_left  {
    position: relative;
}

.elevated_section .elevated_sub_leftbox .elevated_sub_left::after  {
    content: "";
    width: 320px;
    height: 116px;
    right: 0;
    bottom: 0;
    background-image: url(../../Assets/Images/bestdesigner/elevated-arrow.png);
    background-repeat: no-repeat;
    position: absolute;
    background-size: contain;
}

.elevated_section .elevated_sub_leftbox .elevated_sub_left h3  {
    font-size: 20px;
    color: #000000;
    font-weight: 700;
}

.elevated_section .elevated_sub_leftbox .elevated_sub_left h4  {
    font-size: 60px;
    color: #000000;
    font-weight: 700;
    line-height: 44px;
    padding: 10px 0 15px 0;
}

.elevated_section .elevated_sub_leftbox .elevated_sub_left h2  {
    font-size: 66px;
    letter-spacing: -3px;
    line-height: 48px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    background-color: #dd6643;
    padding: 9px 9px;
    display: inline-block;
}

.elevated_section .elevated_sub_leftbox .elevated_sub_left p  {
    font-size: 23px;
    line-height: 39px;
    color: #3b3b3b;
    padding-bottom: 60px;
    max-width: 568px;
    padding-top: 40px;
}

.elevated_section .elevated_sub_leftbox .elevated_sub_left a  {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 28px;
    color: #f5f5f5;
    font-weight: 700;
    border-radius: 38px;
    filter: drop-shadow(2px 3.464px 28.5px rgba(95,128,193,0.49));
    background-image: linear-gradient(88deg, #df6541 0%, #678bcb 100%);
    padding: 20px 48px;
    display: inline-block;
    transition: all ease-in-out .3s;
}

.elevated_section .elevated_sub_leftbox .elevated_sub_left a:hover  {
    background: #f07651;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right  {
    padding-left: 30px;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_heading  {
    padding-bottom: 50px;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_heading h4  {
    font-size: 32px;
    color: #000000;
    font-weight: 700;
    line-height: 39px;
    border-left: 3px solid #dd6543;
    padding-left: 30px;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider  {
    display: flex;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content  {
    width: 79%;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .elevated_slider_details  {
    box-shadow: 6px 10.392px 0px rgba(105,139,202,0.75);
    background-color: #ffffff;
    border: 1px solid #8fa8d7;
    padding: 30px;
    border-radius: 30px;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .slick-slide  {
    padding: 0 30px 15px 0;
    text-align: center;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .elevated_slider_details .profile_img  {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: 0 auto 20px;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .elevated_slider_details .profile_img img  {
    width: 100%;
    border-radius: 50px;
    object-fit: cover;
    object-position: center;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .elevated_slider_details h2  {
    font-size: 20px;
    line-height: normal;
    color: #000000;
    font-weight: 700;
    padding-bottom: 10px;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .elevated_slider_details h3  {
    font-size: 16px;
    line-height: normal;
    color: #000000;
    font-weight: 700;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .elevated_slider_details h3 span  {
    font-size: 14px;
    font-weight: 500;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .elevated_slider_details p  {
    font-size: 14px;
    line-height: 24px;
    color: #3b3b3b;
    font-weight: 500;
    padding-bottom: 20px;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .elevated_slider_details h4  {
    font-size: 15px;
    line-height: 21px;
    color: #3b3b3b;
    font-weight: 700;
    padding-bottom: 24px;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .elevated_slider_details a  {
    font-size: 16px;
    line-height: 21px;
    color: #3b3b3b;
    font-weight: 700;
    padding: 9px 26px;
    background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #df6541, #688bcb) border-box;
    border-radius: 50em;
    border: 2px solid transparent;
    transition: all ease-in-out .3s;
    cursor: pointer;
    display: inline-block;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .elevated_slider_details a:hover  {
    background: #dd6643;
    border: 2px solid #dd6643;
    color: #fff;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_option  {
    width: 21%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_option .slick-track  {
    height: 100% !important;
}

.elevated_section .slick-slide .elevated_slider_nav  {
    opacity: 0.502;
}

.elevated_section .slick-slide.slick-current .elevated_slider_nav  {
    opacity: 1;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_option .elevated_slider_nav .profile_navimg  {
    width: 70px;
    height: 70px;
    margin: auto;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_option .slick-slide {
    padding: 0;
    padding-bottom: 20px;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_option .elevated_slider_nav .profile_navimg img  {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_option .elevated_slider_nav p  {
    font-size: 13px;
    line-height: normal;
    color: #000000;
    font-weight: 600;
    text-align: center;
    padding-top: 15px;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .slick-prev, 
.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .slick-next  {
    position: relative;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .slick-arrow.slick-next  {
    transform: rotate(222deg);
    right: 0;
    border-top: solid 2px #dd6643;
    border-left: solid 2px #dd6643;
}

.elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .slick-arrow.slick-prev  {
    transform: rotate(45deg);
    left: 0;
    margin-bottom: 10px;
    border-top: solid 2px #dd6643;
    border-left: solid 2px #dd6643;
}

.client_slider  {
    padding: 140px 0 185px 0;
    background: #dd6543;
    position: relative;
    /* margin-bottom: 30px; */
}

.client_slider::after  {
    content: "";
    width: 100%;
    height: 217px;
    bottom: 0;
    left: 0;
    position: absolute;
    background-image: url(../../Assets/Images/bestdesigner/customer-background.png);
    background-repeat: no-repeat;
}

.client_slider .client_slider_box  {
    display: flex;
    justify-content: center;
    align-items: center;
}

.client_slider .client_slider_box .client_slider_imagebox  {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client_slider .client_slider_box .client_slider_imagebox .client_slider_image  {
    width: 320px;
   height: 320px; 
   border-radius: 50%;
   position: relative;
}

.client_slider .client_slider_box .client_slider_imagebox .client_slider_image img  {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    object-position: center;
}

.client_slider .client_slider_box .client_slider_text  {
    width: 60%;
}

.client_slider .client_slider_box .client_slider_image .client_slider_image  {
    width: 320px;
    height: 320px;
    position: relative;
}

.client_slider .client_slider_box .client_slider_image img  {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.client_slider .client_slider_box .client_slider_text .question  {
    width: 80px;
    height: 50px;
}

.client_slider .client_slider_box .client_slider_text p  {
    font-size: 22px;
    line-height: 36px;
    color: #ffffff;
    font-weight: 500;
    padding: 20px 30px 36px 0;
}

.client_slider .client_slider_box .client_slider_text h4  {
    font-size: 26px;
    line-height: normal;
    color: #ffffff;
    font-weight: 700;
}

.client_slider .client_slider_box .client_slider_text h5  {
    font-size: 16px;
    line-height: normal;
    color: #ffffff;
    font-weight: 300;
    padding-top: 5px;
}

.client_slider .slick-prev,
.client_slider .slick-next{
    width: 60px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none !important;
    transform: unset;
}

.client_slider .slick-slider .slick-prev  {
    background-image: url('../../Assets/Images/bestdesigner/customer-leftarrow.png');
} 

.client_slider .slick-slider .slick-next  {
    background-image: url('../../Assets/Images/bestdesigner/customer-rightarrow.png');
} 

.our_work  {
    padding: 120px 15px 140px 15px;
}

.our_work .container_box  {
    max-width: 1820px;
    margin: auto;
}

.our_work .our_work_heading  {
    text-align: center;
    position: relative;
}

.our_work .our_work_heading h3  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.051;
    font-size: 309px;
    letter-spacing: -9px;
    line-height: 223px;
    text-transform: uppercase;
    color: #000000;
    font-weight: 700;
    z-index: 0;
}

.our_work .our_work_heading h2  {
    font-size: 80px;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #000000;
    font-weight: 700;
    line-height: 67px;
    position: relative;
}

.our_work .our_work_box  {
    padding-top: 70px;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    position: relative;
}

.our_work .our_work_box .our_work_sub  {
    width: 33.33%;
    padding: 15px;
}

.our_work .our_work_box .our_work_sub a {
    width: 100%;
    display: block;
}

.our_work .our_work_box .our_work_sub:nth-child(3n+2)  {
    margin-top: 30px;
}

.our_work .our_work_box .our_work_sub img  {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 30px;
    box-shadow: 2px 3.464px 40.5px rgba(0,0,0,0.15);
}

.fast_affordably  {
    padding: 210px 0 210px 0;
    text-align: center;
    margin-bottom: 0px;
    /* margin-top: 120px; */
}

.fast_affordably .fast_affordably_box  {
    text-align: center;
}

.fast_affordably .fast_affordably_box h2  {
    font-size: 60px;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    line-height: 46px;
    padding-bottom: 36px;
}

.fast_affordably .fast_affordably_box h3  {
    font-size: 34px;
    line-height: 38px;
    color: #ffffff;
    font-weight: 700;
    padding-bottom: 24px;
}

.fast_affordably .fast_affordably_box p  {
    font-size: 24px;
    line-height: 38px;
    color: #ffffff;
    padding-bottom: 60px;
    font-weight: 400;
    max-width: 980px;
    margin: auto;
}

.fast_affordably .fast_affordably_box a  {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 28px;
    color: #f5f5f5;
    font-weight: 700;
    border-radius: 38px;
    filter: drop-shadow(2px 3.464px 28.5px rgba(95,128,193,0.49));
    background-image: linear-gradient(88deg, #df6541 0%, #678bcb 100%);
    padding: 18px 78px;
    display: inline-block;
    transition: all ease-in-out .3s;
}

.fast_affordably .fast_affordably_box a:hover  {
    background: #f07651;
    color: #fff;
}

.best_designer_contact .container .contact_content  {
    justify-content: space-between;
}

.best_designer_contact .container .contact_content h5  {
    text-transform: uppercase;
}

.best_designer_contact .container .contact_content h2 {
    text-transform: uppercase;
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    position: relative;
    max-width: 400px;
}

.best_designer_contact .container .contact_content ul  {
    padding-top: 30px;
}

.best_designer_contact .container .contact_content ul li  {
    display: flex;
    align-items: center;
    column-gap: 24px;
}

.best_designer_contact .container .contact_content ul li:not(:last-child)  {
    padding-bottom: 20px;
}

.best_designer_contact .container .contact_content ul li img  {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
}

.best_designer_contact .container .contact_content ul li::before  {
    display: none;
}

.best_designer_contact .container .contact_content ul li a  {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
}

@media (max-width:1420px)  {
    .designer_banner .designer_text_box h2 {
        font-size: 21px;
        line-height: 32px;
        padding: 8px 18px;
        margin-bottom: 40px;
    }
    .designer_banner .designer_text_box h1 {
        font-size: 46px;
    }
    .designer_banner .designer_text_box h1 span {
        font-size: 80px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h3 {
        font-size: 68px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width {
        padding-left: 30px;
    }
    .elevated_section .elevated_sub_leftbox {
        width: 55%;
    }
    .elevated_section .elevated_sub_rightbox {
        width: 45%;
    }
    .elevated_section .elevated_sub_leftbox .elevated_sub_left::after {
        width: 158px;
        height: 75px;
    }
}

@media (max-width:1200px)  {
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h3 {
        font-size: 50px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h4 {
        font-size: 30px;
        line-height: 34px;
        font-weight: 500;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .icon {
        width: 80px;
        height: 80px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .icon img {
        width: 50%;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .text {
        width: calc(100% - 80px);
        padding-left: 15px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box {
        padding: 16px;
    }
    .expert_webdesigner .expert_webdesigner_flex {
        padding: 100px 0 60px;
    }
    .facts_section {
        padding-top: 90px;
    }
    .facts_section .heading h2 {
        font-size: 52px;
        line-height: 39px;
        padding-bottom: 30px;
    }
    .facts_section .heading h3 {
        font-size: 22px;
        padding-bottom: 10px;
        line-height: 24px;
    }
    .facts_section .facts_number .facts_flex {
        padding-bottom: 60px;
    }
    .elevated_section {
        padding: 90px 0 90px 0;
    }
    .elevated_section .elevated_sub_leftbox .elevated_sub_left h2 {
        font-size: 56px;
        line-height: 37px;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right {
        padding-left: 10px;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_heading h4 {
        font-size: 26px;
        line-height: 33px;
        padding-left: 20px;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .elevated_slider_details {
        padding: 20px 15px;
    }
    .client_slider {
        padding: 90px 0 100px 0;
    }
    .client_slider .client_slider_box .client_slider_text p {
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        padding: 20px 40px 30px 0;
    }
    .client_slider .client_slider_box .client_slider_imagebox .client_slider_image {
        width: 220px;
        height: 220px;
    }
    .elevated_section .elevated_sub_leftbox .elevated_sub_left::after  {
        display: none;
    }
}

@media (max-width:991px)  {
    .hair-designer .hair_designer_bannerbtm_box+.hair_designer_bannerbtm_box:nth-child(3)  {
        border: none;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width {
        padding-left: 0px;
    }
    .hair-designer .banner-left h2 {
        font-size: 18px;
        line-height: 26px;
        padding: 3px 24px;
        margin-bottom: 24px;
    }
    .hair-designer .banner-left h3 {
        font-size: 28px;
    }
    .hair-designer .banner-left h4 {
        font-size: 46px;
        line-height: 54px;
    }
    .expert_webdesigner .expert_webdesigner_flex {
        padding: 70px 0 60px;
        flex-wrap: wrap;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left, .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right {
        width: 100%;
        padding: 15px;
        margin: auto;
        text-align: center;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h2 {
        font-size: 36px;
        letter-spacing: -2px;
        line-height: 31px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: center;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h3 {
        font-size: 42px;
        line-height: 42px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h4 {
        font-size: 26px;
        line-height: 21px;
        font-weight: 500;
        padding: 20px 0 20px 0;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left p {
        font-size: 17px;
        line-height: 28px;
        color: #ffffff;
        padding-bottom: 20px;
        max-width: 568px;
        margin: auto;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left a {
        font-size: 18px;
        padding: 13px 55px;
        display: inline-block;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box {
        padding: 22px;
        text-align: left;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .text {
        padding-left: 22px;
    }
    .facts_section {
        padding-top: 70px;
    }
    .facts_section .heading h3 {
        font-size: 18px;
        padding-bottom: 8px;
    }
    .facts_section .heading h2 {
        font-size: 44px;
        line-height: 30px;
    }
    .facts_section .facts_number .facts_flex .facts_sub {
        width: 50%;
        padding: 15px 0px;
        margin: 10px 0;
    }
    .facts_section .facts_number .facts_flex .facts_sub h2 {
        font-size: 42px;
        line-height: 33px;
        padding-bottom: 7px;
    }
    .facts_section .facts_number .facts_flex .facts_sub p {
        font-size: 15px;
    }
    .facts_section .facts_number .facts_flex .facts_sub:nth-child(2)  {
        border: none;
    }
    .facts_section .facts_number .facts_flex {
        padding-bottom: 40px;
    }
    .elevated_section {
        padding: 70px 0 70px 0;
    }
    .elevated_section .elevated_flex {
        flex-wrap: wrap;
        text-align: center;
    }
    .elevated_section .elevated_sub_leftbox,
    .elevated_section .elevated_sub_rightbox  {
        width: 100%;
        margin: auto;
    }
    .elevated_section .elevated_sub_leftbox .elevated_sub_left h4 {
        font-size: 46px;
        line-height: 37px;
        padding: 6px 0 12px 0;
    }
    .elevated_section .elevated_sub_leftbox .elevated_sub_left h2 {
        font-size: 46px;
        line-height: 30px;
    }
    .elevated_section .elevated_sub_leftbox .elevated_sub_left p {
        font-size: 21px;
        line-height: 34px;
        padding-bottom: 36px;
        max-width: 100%;
        padding-top: 24px;
    }
    .elevated_section .elevated_sub_leftbox .elevated_sub_left a {
        font-size: 18px;
        line-height: 28px;
        padding: 12px 44px;
        margin-bottom: 30px;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_heading h4 {
        font-size: 24px;
        line-height: 28px;
        padding-left: 0px;
        border: none;
    }
    .client_slider .client_slider_box {
        flex-wrap: wrap;
    }
    .client_slider .client_slider_box .client_slider_imagebox,
    .client_slider .client_slider_box .client_slider_text  {
        width: 100%;
        text-align: center;
    }
    .client_slider .client_slider_box .client_slider_text .question {
        display: none;
    }
    .client_slider .client_slider_box .client_slider_text p {
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        padding: 20px;
    }
    .client_slider .client_slider_box .client_slider_text h4 {
        font-size: 23px;
    }
    .client_slider {
        padding: 70px 15px 70px 15px;
        margin-bottom: 0px;
    }
    .client_slider .slick-slider .slick-next,
    .client_slider .slick-slider .slick-prev {
        width: 33px;
    }
    .fast_affordably {
        padding: 90px 0 90px 0;
        margin-bottom: 0px;
        /* margin-top: 70px; */
    }
    .fast_affordably .fast_affordably_box h2 {
        font-size: 44px;
        line-height: 38px;
        padding-bottom: 24px;
    }
    .fast_affordably .fast_affordably_box h3 {
        font-size: 24px;
        line-height: 28px;
        padding-bottom: 15px;
    }
    .fast_affordably .fast_affordably_box p {
        font-size: 18px;
        line-height: 30px;
        padding-bottom: 38px;
        padding-top: 10px;
    }
    .fast_affordably .fast_affordably_box a {
        font-size: 18px;
        line-height: 28px;
        border-radius: 38px;
        padding: 12px 52px;
    }
    .faq-sec-part h3 {
        font-size: 40px;
        line-height: 46px;
    }
    .faq-sec-part {
        padding: 70px 0 70px;
        background: #fff;
    }
    .accordion {
        padding-top: 30px;
    }
}

@media (max-width:767px)  {
    .hair-designer .banner-left h2 {
        font-size: 16px;
        line-height: 19px;
        padding: 3px 16px;
        margin-bottom: 24px;
    }
    .hair-designer .banner-left h3 {
        font-size: 20px;
        letter-spacing: 0px;
    }
    .hair-designer .banner-left h4 {
        font-size: 38px;
        line-height: 44px;
    }
    .expert_webdesigner .expert_webdesigner_icon {
        width: 40px;
        height: 40px;
        margin-top: -17px;
    }
    .expert_webdesigner .expert_webdesigner_flex {
        padding: 50px 0 50px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h2 {
        font-size: 28px;
        letter-spacing: -1px;
        line-height: 26px;
        padding-bottom: 8px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h3 {
        font-size: 34px;
        line-height: 38px;
        line-height: -1px;
        letter-spacing: -2px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left h4 {
        font-size: 20px;
        line-height: 26px;
        padding: 15px 0 15px 0;
        letter-spacing: 0px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left p {
        font-size: 15px;
        line-height: 24px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_left a {
        font-size: 16px;
        padding: 10px 53px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width {
        padding-left: 0px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box {
        flex-direction: column;
        text-align: center;
        margin: auto;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .icon {
        margin: 0 auto 20px auto;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .text {
        padding-left: 0px;
        width: 100%;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .text h3 {
        font-size: 21px;
    }
    .expert_webdesigner .expert_webdesigner_flex .expert_webdesigner_right .expert_webdesigner_right_width .expert_webdesigner_right_box .text p {
        font-size: 15px;
        line-height: 24px;
        padding-top: 10px;
    }
    .facts_section .facts_number .facts_flex .facts_sub h2 {
        font-size: 36px;
        line-height: 30px;
        padding-bottom: 7px;
    }
    .facts_section .facts_number .facts_flex .facts_sub p {
        font-size: 14px;
        line-height: 16px;
        padding-top: 6px;
    }
    .elevated_section .elevated_sub_leftbox .elevated_sub_left h4 {
        font-size: 37px;
        line-height: 37px;
        padding: 6px 0 10px 0;
    }
    .elevated_section .elevated_sub_leftbox .elevated_sub_left h2 {
        font-size: 36px;
        line-height: 26px;
        letter-spacing: -2px;
        padding: 5px 8px;
    }
    .elevated_section .elevated_sub_leftbox .elevated_sub_left p {
        font-size: 17px;
        line-height: 29px;
        padding-bottom: 36px;
        padding-top: 20px;
    }
    .elevated_section .elevated_sub_leftbox .elevated_sub_left a {
        font-size: 16px;
        padding: 10px 16px;
        margin-bottom: 30px;
    }
    .elevated_section .elevated_sub_rightbox {
        padding: 0px;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_heading h4 {
        font-size: 20px;
        line-height: 26px;
        padding: 0px;
        border: none;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_heading {
        padding-bottom: 20px;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content,
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_option {
        width: 100%;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider {
        flex-wrap: wrap;
        position: relative;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_content .slick-slide {
        padding: 0 0px 34px 0;
    }
    .client_slider {
        padding: 60px 15px 60px 15px;
    }
    .client_slider .client_slider_box .client_slider_imagebox .client_slider_image {
        width: 140px;
        height: 140px;
    }
    .client_slider .client_slider_box .client_slider_text p {
        font-size: 15px;
        line-height: 21px;
        padding: 15px 10px;
    }
    .client_slider .client_slider_box .client_slider_text h4 {
        font-size: 18px;
        padding-top: 20px;
    }
    .client_slider .client_slider_box .client_slider_text h5 {
        font-size: 14px;
        padding-top: 8px;
    }
    .fast_affordably .fast_affordably_box h2 {
        font-size: 36px;
        line-height: 39px;
        padding-bottom: 22px;
    }
    .fast_affordably .fast_affordably_box h3 {
        font-size: 20px;
        line-height: 25px;
        padding-bottom: 15px;
        font-weight: 500;
    }
    .fast_affordably .fast_affordably_box p {
        font-size: 16px;
        line-height: 26px;
        padding-bottom: 20px;
        padding-top: 10px;
    }
    .fast_affordably .fast_affordably_box a {
        font-size: 16px;
        line-height: 28px;
        padding: 10px 48px;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .elevated_slider_option  {
        display: none;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .slick-arrow.slick-prev {
        transform: rotate(-46deg);
        left: 9px;
    }
    .elevated_section .elevated_sub_rightbox .elevated_sub_right .elevated_sub_right_slider .slick-arrow.slick-next {
        transform: rotate(134deg);
        right: 9px;
        border-top: solid 2px #dd6643;
        border-left: solid 2px #dd6643;
        position: absolute;
    }
    .faq-sec-part h3 {
        font-size: 35px;
        line-height: 40px;
    }
}