// Schedule Consultation Model

.schedule_consultation {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,.8);
    backdrop-filter: blur(5px);
	.modal {
        position: fixed;
		top: 10%;
		left: 0;
        bottom: 0;
		z-index: 9999999;
		overflow: visible;
		right: 0;
		margin: 0 auto;
        outline: 0;
        .overlay{
            // position: fixed;
            // top: 0;
            // right: 0;
            // bottom: 0;
            // left: 0;
            // z-index: 1;
            // background-color: #000;
            // opacity: .8;
        }
		.modal-dialog {
            position: relative;
			width: 865px;
			margin: 0 auto;
            z-index: 9999;
            -webkit-transform: translate3d(0,0,0);
            -o-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
            .modal-content {
                position: relative;
                background-color: #fff;
                -webkit-background-clip: padding-box;
                background-clip: padding-box;
                border: 1px solid #999;
                border: 1px solid rgba(0,0,0,.2);
                border-radius: 6px;
                outline: 0;
                overflow: hidden;
                -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
                box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
                .modal-header {
                    min-height: unset;
                    padding: 0;
                    border-bottom: transparent;
                    button.close {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        top: 2px;
                        right: 2px;
                        float: right;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 1;
                        color: #fff;
                        text-shadow: none;
                        border-radius: 0 6px 0 0;
                        filter: alpha(opacity=20);
                        opacity: 1;
                        padding: 0;
                        cursor: pointer;
                        background: #6946b9;
                        background: linear-gradient(45deg,rgb(211, 7, 26) 10%,rgb(3, 104, 181) 100%);
                        border: 0;
                        z-index: 9;
                        -webkit-appearance: none;

                        &.pink-close{
                            background: #6946b9;
                            background: linear-gradient(45deg,rgb(140,0,255) 10%,rgb(49, 5, 95) 100%);
                        }
                    }
                    
                }
                .modal-body {
                    position: relative;
                    padding: 0;
                    .PhoneInputCountry{
                        border-bottom: solid 1px #000;
                        margin-right: 0;
                        
                    }
                    .PhoneInput{
                        input{
                            padding-left: 5px !important;
                        }
                    }
                    .schedule_consultation-content {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        .sidepanel {
                            width: 30%;
                            padding: 59px 14px 0 14px;
                            background: rgb(253,82,6);
                            background: linear-gradient(0deg,rgb(211, 7, 26) 10%,rgb(3, 104, 181) 100%);
                            // background: linear-gradient(to right,#a609b1 0,#6946b9 100%);
                            &.learge-side-bar{
                                width: 45%;
                                padding: 59px 25px;
                                background: rgb(49, 5, 95);
                                background: radial-gradient(circle, rgba(140,0,255, 1) 0%, rgba(49, 5, 95,1) 68%);
                            }
                            h6 {
                                font-family: 'Patua One';
                                color: #fff;
                                font-size: 18px;
                                font-weight: 200;
                                letter-spacing: 4px;
                                margin-top: 10px;
                                margin-bottom: 10px;
                            }
                            h2 {
                                font-family: 'Patua One';
                                color: #fff;
                                font-size: 45px;
                                line-height: 40px;
                                font-weight: 200;
                                margin-top: 10px;
                                margin-bottom: 10px;
                            }
                            h3 {
                                font-family: 'Patua One';
                                color: #fff;
                                font-size: 25px;
                                line-height: 20px;
                                font-weight: 200;
                                border-top: 1px solid rgba(255, 255, 255, 0.4);
                                border-bottom: 1px solid rgba(255, 255, 255, 0.4);
                                padding: 10px 0 10px 0;
                                letter-spacing: 1px;
                                margin-top: 20px;
                                margin-bottom: 10px;
                            }
                            .icon-left{
                                color: #fff;
                                margin-bottom: 20px;
                            }
                            h4{
                                font-size: 34px;
                                color: #fff;
                                position: relative;
                                padding-bottom: 25px;
                                margin-bottom: 10px;
                                font-family: "Patua One";
                                font-weight: 400;
                                line-height: 38px;
                                letter-spacing: 1px;
                                &::before{
                                    position: absolute;
                                    content: "";
                                    left: 0;
                                    width: 50px;
                                    height: 3px;
                                    border-radius: 3px;
                                    background-color: #fff;
                                    bottom: 0;
                                }
                            }
                            p{
                                font-size: 13px;
                                color: #fff;
                                padding: 20px 0 0;
                                line-height: 22px;
                            }
                            .schedule_consultation_icon_wrapper {
                                position: relative;
                                margin-top: 100px;
                                margin-bottom: 30px;
                                .round-shape {
                                    position: relative;
                                    width: 157px;
                                    height: 157px;
                                    background: #c9273e;
                                    border-radius: 50%;
                                    margin: 0 auto;
                                }
                                .round-shape.pulse {
                                    animation: pulse-animation 2s infinite;
                                }
                                .schedule_consultation_icon {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    bottom: 35px;
                                    max-width: 75%;
                                    display: block;
                                    margin-left: auto;
                                    margin-right: auto;
                                }
                            }
                        }
                        .rightpanel {
                            width: 70%;
                            &.learge-side-bar{
                                width: 55%;
                            }
                            h5 {
                                font-family: 'Patua One';
                                color: #000;
                                font-size: 30px;
                                font-weight: 200;
                                margin: 30px 0 0 0;
                                padding: 0;
                                text-align: center;
                                // text-transform: uppercase;
                                letter-spacing: 1px;
                            }
                            h4{
                                font-family: 'Patua One';
                                color: #000;
                                font-size: 22px;
                                font-weight: 200;
                                margin: 30px 0 0 0;
                                padding: 0 20px 0 44px;
                                text-align: left;
                                letter-spacing: 1px;
                            }
                            .wizard {
                                margin-top: 40px;
                                padding: 0 30px 20px;
                                .wizard-inner {
                                    position: relative;
                                    margin: 0 0 70px 0;
                                    .connecting-line {
                                        height: 2px;
                                        background: #dbdbdb;
                                        position: absolute;
                                        width: 100%;
                                        margin: 0 auto;
                                        left: 0;
                                        right: 0;
                                        top: 50%;
                                        z-index: 1;
                                    }
                                    .nav-tabs {
                                        position: relative;
                                        margin-top: 0;
                                        margin-bottom: 0;
                                        padding-left: 0;
                                        border-bottom: 1px solid transparent;
                                        display: flex;
                                        align-items: center;
                                        li {
                                            position: relative;
                                            width: 33%;
                                            float: left;
                                            margin-bottom: -1px;
                                            a{
                                                width: 20px;
                                                height: 20px;
                                                display: block;
                                                margin: 0 auto;
                                                border-radius: 100%;
                                                padding: 0;
                                                background-color: transparent;
                                                position: relative;
                                                top: 0;
                                                color: #555555;
                                                cursor: default;
                                                border: 0;
                                                border-bottom-color: transparent;
                                                transition: all 500ms ease-in-out;
                                                -o-transition: all 500ms ease-in-out;
                                                -ms-transition: all 500ms ease-in-out;
                                                -moz-transition: all 500ms ease-in-out;
                                                -webkit-transition: all 500ms ease-in-out;
                                                .round-tab {
                                                    width: 20px;
                                                    height: 20px;
                                                    display: inline-block;
                                                    border-radius: 50%;
                                                    background: #dbdbdb;
                                                    z-index: 1;
                                                    position: absolute;
                                                    left: 0;
                                                }
                                                i {
                                                    font-family: 'Assistant',sans-serif;
                                                    position: absolute;
                                                    top: 30px;
                                                    white-space: normal;
                                                    left: 50%;
                                                    transform: translateX(-50%);
                                                    font-size: 13px;
                                                    font-weight: 700;
                                                    font-style: normal;
                                                    color: #dddd;
                                                    text-align: center;
                                                    text-transform: uppercase;
                                                    width: 150px;
                                                }
                                            }
                                            &.active{
                                                a{
                                                    i {
                                                        color: #e45923;
                                                    }
                                                }
                                                .round-tab {
                                                    background: #e45923;
                                                    border-color: #e45923;
                                                }
                                            }
                                        }

                                    }
                                }
                                .schedule_consultation-box{
                                    .details{
                                        .row {
                                            display:flex;
                                            .left {
                                                position: relative;
                                                min-height: 1px;
                                                padding-right: 0;
                                                padding-left: 0;
                                                width: 16.66666667%;
                                                .schedule_consultation_person {
                                                    display: block;
                                                    margin-left: auto;
                                                    margin-right: 15px;
                                                    position: relative;
                                                }
                                            }
                                            .right {
                                                position: relative;
                                                min-height: 1px;
                                                padding-right: 0;
                                                padding-left: 0;
                                                width: 100%;
                                                .row {
                                                    display:flex;
                                                    .left-sec {
                                                    width: 50%;
                                                    position: relative;
                                                    min-height: 1px;
                                                    padding-right: 15px;
                                                    padding-left: 15px;
                                                    .form-group {
                                                        margin-bottom: 20px;
                                                        label {
                                                            font-family: 'Patua One';
                                                            font-size: 15px;
                                                            display: inline-block;
                                                            max-width: 100%;
                                                            margin-bottom: 0;
                                                            font-weight: 500;
                                                            letter-spacing: 1px;
                                                        }
                                                        input{
                                                            position: relative;
                                                            font-family: 'Assistant',sans-serif;
                                                            padding: 0;
                                                            height: 38px;
                                                            font-size: 15px;
                                                            font-weight: 400;
                                                            background-color: #fff;
                                                            border-top: 1px solid transparent;
                                                            border-right: 1px solid transparent;
                                                            border-left: 1px solid transparent;
                                                            border-bottom: 1px solid #000000;
                                                            border-radius: 0;
                                                            color: #757575;
                                                            display: block;
                                                            width: 100%;
                                                            -webkit-appearance: none;
                                                            -moz-appearance: none;
                                                            appearance: none;
                                                            box-shadow: none;
                                                        }
                                                    }                                                   
                                                }
                                                .right-sec {
                                                    width: 50%;
                                                    position: relative;
                                                    min-height: 1px;
                                                    padding-right: 15px;
                                                    padding-left: 15px;
                                                    .form-group {
                                                        margin-bottom: 30px;
                                                        label {
                                                            font-family: 'Patua One';
                                                            font-size: 15px;
                                                            display: inline-block;
                                                            max-width: 100%;
                                                            margin-bottom: 0;
                                                            font-weight: 500;
                                                            letter-spacing: 1px;
                                                        }
                                                        input{
                                                            position: relative;
                                                            font-family: 'Assistant',sans-serif;
                                                            padding: 0;
                                                            height: 38px;
                                                            font-size: 15px;
                                                            font-weight: 400;
                                                            background-color: #fff;
                                                            border-top: 1px solid transparent;
                                                            border-right: 1px solid transparent;
                                                            border-left: 1px solid transparent;
                                                            border-bottom: 1px solid #000000;
                                                            border-radius: 0;
                                                            color: #757575;
                                                            display: block;
                                                            width: 100%;
                                                            -webkit-appearance: none;
                                                            -moz-appearance: none;
                                                            appearance: none;
                                                            box-shadow: none;
                                                        }
                                                    }                                                
                                                }
                                                .buttom-sec{
                                                    width: 100%;
                                                    position: relative;
                                                    min-height: 1px;
                                                    padding-right: 15px;
                                                    padding-left: 15px;
                                                    .form-group {
                                                        margin-bottom: 50px;
                                                        label {
                                                            font-family: 'Patua One';
                                                            font-size: 15px;
                                                            display: inline-block;
                                                            max-width: 100%;
                                                            margin-bottom: 0;
                                                            font-weight: 500;
                                                            letter-spacing: 1px;
                                                        }
                                                        textarea{
                                                            position: relative;
                                                            font-family: 'Assistant',sans-serif;
                                                            padding: 0;
                                                            height: 50px;
                                                            font-size: 15px;
                                                            font-weight: 400;
                                                            background-color: #fff;
                                                            border-top: 1px solid transparent;
                                                            border-right: 1px solid transparent;
                                                            border-left: 1px solid transparent;
                                                            border-bottom: 1px solid #000000;
                                                            border-radius: 0;
                                                            color: #757575;
                                                            display: block;
                                                            width: 100%;
                                                            -webkit-appearance: none;
                                                            -moz-appearance: none;
                                                            appearance: none;
                                                            box-shadow: none;
                                                            resize: none;
                                                        }
                                                        .input-style{
                                                            position: relative;
                                                            font-family: 'Assistant',sans-serif;
                                                            padding: 0;
                                                            height: 38px;
                                                            font-size: 15px;
                                                            font-weight: 400;
                                                            background-color: #fff;
                                                            border-top: 1px solid transparent;
                                                            border-right: 1px solid transparent;
                                                            border-left: 1px solid transparent;
                                                            border-bottom: 1px solid #000000;
                                                            border-radius: 0;
                                                            color: #757575;
                                                            display: block;
                                                            width: 100%;
                                                            -webkit-appearance: none;
                                                            -moz-appearance: none;
                                                            appearance: none;
                                                            box-shadow: none;
                                                        }
                                                    }
                                                    .pull-right{
                                                        float: right;
                                                        .next-step {
                                                            display: flex;
                                                            align-items: center;
                                                            gap: 5px;
                                                            font-family: 'Patua One';
                                                            font-size: 16px;
                                                            line-height: 18px;
                                                            font-weight: 200;
                                                            padding: 13px 30px;
                                                            border: none;
                                                            border-radius: 50px;
                                                            margin-top: 0;
                                                            background-color: #000;
                                                            color: #fff;
                                                            letter-spacing: 1px;
                                                            -webkit-appearance: button;
                                                            cursor: pointer;
                                                            transition: 0.5s;
                                                            &.btn-green{
                                                                background-color: #20c0a0;
                                                                color: #000;
                                                                &:hover{
                                                                    background-color: #049377;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                    .date-time{
                                    .row {
                                        display:flex;
                                        .left {
                                            position: relative;
                                            min-height: 1px;
                                            padding-right: 0;
                                            padding-left: 0;
                                            width: 16.66666667%;
                                            .schedule_consultation_calander {
                                                display: block;
                                                margin-left: auto;
                                                margin-right: 15px;
                                                position: relative;
                                            }
                                        }
                                        .right {
                                            position: relative;
                                            min-height: 1px;
                                            padding-right: 0;
                                            padding-left: 0;
                                            width: 100%;
                                            .row {
                                                display:flex;
                                                margin-right: -15px;
                                                margin-left: -15px;
                                                .left-sec {
                                                width: 50%;
                                                position: relative;
                                                min-height: 1px;
                                                padding-right: 15px;
                                                padding-left: 15px;
                                                .form-group {
                                                    margin-bottom: 50px;
                                                    label {
                                                        font-family: 'Patua One';
                                                        font-size: 15px;
                                                        display: inline-block;
                                                        max-width: 100%;
                                                        margin-bottom: 0;
                                                        font-weight: 500;
                                                        letter-spacing: 1px;
                                                    }
                                                    input{
                                                        position: relative;
                                                        font-family: 'Assistant',sans-serif;
                                                        padding: 0;
                                                        height: 38px;
                                                        font-size: 15px;
                                                        font-weight: 400;
                                                        background-color: #fff;
                                                        border-top: 1px solid transparent;
                                                        border-right: 1px solid transparent;
                                                        border-left: 1px solid transparent;
                                                        border-bottom: 1px solid #000000;
                                                        border-radius: 0;
                                                        color: #757575;
                                                        display: block;
                                                        width: 100%;
                                                        -webkit-appearance: none;
                                                        -moz-appearance: none;
                                                        appearance: none;
                                                        box-shadow: none;
                                                    }
                                                    .date-icon{
                                                        position: absolute;
                                                        right: 15px;
                                                        top: 25px;
                                                        color: #757575;
                                                    }
                                                }                                                   
                                            }
                                            .right-sec {
                                                width: 50%;
                                                position: relative;
                                                min-height: 1px;
                                                padding-right: 15px;
                                                padding-left: 15px;
                                                .form-group {
                                                    margin-bottom: 50px;
                                                    label {
                                                        font-family: 'Patua One';
                                                        font-size: 15px;
                                                        display: inline-block;
                                                        max-width: 100%;
                                                        margin-bottom: 0;
                                                        font-weight: 500;
                                                        letter-spacing: 1px;

                                                        &.date-icon{
                                                            position: absolute;
                                                            top: 5px;
                                                            right: 15px;
                                                            color: #555555;
                                                        }
                                                    }
                                                    .left-select, .right-select{
                                                        width: 100%;
                                                        position: relative;
                                                        min-height: 1px;
                                                        padding-right: 15px;
                                                        padding-left: 15px;
                                                        select{
                                                            position: relative;
                                                            font-family: 'Assistant',sans-serif;
                                                            padding: 0 5px;
                                                            height: 38px;
                                                            font-size: 15px;
                                                            font-weight: 400;
                                                            background-color: #fff;
                                                            border-top: 1px solid transparent;
                                                            border-right: 1px solid transparent;
                                                            border-left: 1px solid transparent;
                                                            border-bottom: 1px solid #000000;
                                                            border-radius: 0;
                                                            color: #757575;
                                                            display: block;
                                                            width: 100%;
                                                            -webkit-appearance: none;
                                                            -moz-appearance: none;
                                                            appearance: none;
                                                            box-shadow: none;
                                                            &.hlfinput {
                                                                background-image: url('../Assets/Images/arr.png');
                                                                background-position: 96% center;
                                                                background-repeat: no-repeat;
                                                            }
                                                        }
                                                    }
                                                    input{
                                                        position: relative;
                                                        font-family: 'Assistant',sans-serif;
                                                        padding: 0;
                                                        height: 38px;
                                                        font-size: 15px;
                                                        font-weight: 400;
                                                        background-color: #fff;
                                                        border-top: 1px solid transparent;
                                                        border-right: 1px solid transparent;
                                                        border-left: 1px solid transparent;
                                                        border-bottom: 1px solid #000000;
                                                        border-radius: 0;
                                                        color: #757575;
                                                        display: block;
                                                        width: 100%;
                                                        -webkit-appearance: none;
                                                        -moz-appearance: none;
                                                        appearance: none;
                                                        box-shadow: none;
                                                    }
                                                    .schedule_consultation_calander2 {
                                                        position: absolute;
                                                        top: 24px;
                                                        right: 16px;
                                                    }                                                
                                                }                                                
                                            }
                                            .buttom-sec{
                                                width: 100%;
                                                position: relative;
                                                min-height: 1px;
                                                padding-right: 15px;
                                                padding-left: 15px;
                                                .form-group {
                                                    margin-bottom: 50px;
                                                    label {
                                                        font-family: 'Patua One';
                                                        font-size: 15px;
                                                        display: inline-block;
                                                        max-width: 100%;
                                                        margin-bottom: 0;
                                                        font-weight: 500;
                                                        letter-spacing: 1px;
                                                    }
                                                    ul {
                                                        display: flex;
                                                        align-items: center;
                                                        flex-wrap: wrap;
                                                        margin-top: 15px;
                                                        li {
                                                            margin-right: 12px;
                                                            &:last-child {
                                                                margin-right: 0;
                                                            }
                                                            label {
                                                                display: flex;
                                                                align-items: center;
                                                                gap: 10px;
                                                                max-width: 100%;
                                                                font-family: 'Assistant',sans-serif;
                                                                color: #000;
                                                                font-size: 15px;
                                                                line-height: 24px;
                                                                margin-bottom: 0;
                                                                font-weight: 600;
                                                                letter-spacing: 0;
                                                                vertical-align: middle;
                                                                cursor: pointer;
                                                                svg {
                                                                    fill: #7a7a7a;
                                                                }
                                                                input{
                                                                    display: none;
                                                                }
                                                                input:checked + span{
                                                                   background-color: #fd5206;
                                                                   color: #fff; // display: none;
                                                                }
                                                                input:checked + span svg{
                                                                    fill: #fff;
                                                                }
                                                                span{
                                                                    display: inline-flex;
                                                                    align-items: center;
                                                                    padding: 5px 20px;
                                                                    margin-top: 5px;
                                                                    border-radius: 20px;
                                                                    svg{
                                                                        margin-right: 5px;
                                                                    }
                                                                }
                                                            }
                                                            
                                                        }
                                                    }
                                                }
                                                .pull-right{
                                                    float: right;
                                                    .next-step {
                                                        display: flex;
                                                        align-items: center;
                                                        gap: 5px;
                                                        font-family: 'Patua One';
                                                        font-size: 16px;
                                                        line-height: 18px;
                                                        font-weight: 200;
                                                        padding: 13px 30px;
                                                        border: none;
                                                        border-radius: 50px;
                                                        margin-top: 0;
                                                        background-color: #000;
                                                        color: #fff;
                                                        letter-spacing: 1px;
                                                        -webkit-appearance: button;
                                                        cursor: pointer;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                }
                                .complete{
                                    .row {
                                        display:flex;
                                        margin-right: -15px;
                                        margin-left: -15px;
                                        .left {
                                            position: relative;
                                            min-height: 1px;
                                            padding-right: 0;
                                            padding-left: 0;
                                            width: 16.66666667%;
                                            .schedule_consultation_calander {
                                                display: block;
                                                margin-left: auto;
                                                margin-right: 15px;
                                                position: relative;
                                            }
                                        }
                                        .right {
                                            position: relative;
                                            min-height: 1px;
                                            padding-right: 0;
                                            padding-left: 0;
                                            width: 100%;
                                            text-align: center;
                                            h2 {
                                                font-family: 'Patua One';
                                                font-size: 45px;
                                                font-weight: 500;
                                                line-height: 50px;
                                                color: #000000;
                                                letter-spacing: 1px;
                                                padding:10px 20px 20px ;
                                            }
                                            h3 {
                                                font-family: 'Patua One';
                                                font-size: 24px;
                                                font-weight: 500;
                                                line-height: 28px;
                                                color: #000000;
                                                letter-spacing: 1px;
                                                margin-bottom: 30px;
                                                margin-left: 20px;
                                                margin-top: 0;
                                            }
                                            ul {
                                                display: flex;
                                                align-items: center;
                                                margin-bottom: 20px;
                                                justify-content: center;
                                                li{
                                                    .default-btn {
                                                        font-family: 'Patua One';
                                                        font-size: 16px;
                                                        line-height: 18px;
                                                        font-weight: 500;
                                                        padding: 13px 27px;
                                                        border: none;
                                                        border-radius: 50px;
                                                        margin-top: 0;
                                                        margin-right: 9px;
                                                        background-color: #000;
                                                        letter-spacing: 1px;
                                                        color: #fff;
                                                        &.orange {
                                                            background: #fd5206;
                                                        }
                                                        &.gray {
                                                            background: #858585;
                                                        }
                                                    }
                                                }
                                            }
                                    }
                                }
                                }
                            }
                        }
                        .info {
                            background: #ebebeb;
                            padding: 8px 0;
                            margin: 20px 0 0 0;
                            p {
                                font-family: 'Patua One',cursive;
                                font-size: 16px;
                                font-weight: 300;
                                line-height: 24px;
                                color: #acacac;
                                text-align: right;
                                margin-right: 20px;
                                letter-spacing: 1px;
                            }
                        }
                    }
                }
            }
		}
    }
}
}

.react-datepicker-wrapper{
    width: 100%;
    display: block;
    position: relative;
}
@media only screen and (min-width: 960px) and (max-width: 1200px) {}
@media only screen and (min-width: 768px) and (max-width: 959px) {
    
}
@media (max-width: 480px) {
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .wizard-inner .nav-tabs li a i{
        width: 107px;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel h5{
        padding: 0 30px 13px 19px;
        text-align: left;
        font-size: 18px;
        letter-spacing: 0;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .wizard-inner .nav-tabs li a i{
        font-size: 11px;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .date-time .row .right .row .left-sec, 
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .date-time .row .right .row .right-sec{
        width: 100%;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .date-time .row .right .row{
        flex-wrap: wrap;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .date-time .row .right .row .left-sec .form-group, 
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .date-time .row .right .row .right-sec .form-group{
        margin-bottom: 20px;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .date-time .row .right .row .buttom-sec .form-group{
        margin-bottom: 26px
    }
}

@media (max-width: 600px) {

    .responsive-padding{
        padding: 0 15px !important;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .complete .row .right h2{
        font-size: 30px;
        margin-left: 0;
        line-height: 34px;
        
    }

    
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .complete .row .left{
        display: none;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .complete .row .right{
        width: 100%;
        padding: 0 20px;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .complete .row .right h3{
        font-size: 20px;
        line-height: 22px;
        margin-left: 0;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .complete .row .right ul{
        flex-wrap: wrap;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .complete .row .right ul li .default-btn{
        width: 100%;
        display: block;
        margin: 10px 0 0;
        text-align: center;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .complete .row .right ul li{
        width: 100%;
    }
    .schedule_consultation .modal .schedule_consultation-content .rightpanel .schedule_consultation-box .details, 
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row .right .row .left-sec, 
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row .right .row .right-sec, 
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row .right .row .buttom-sec{
        padding: 0;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .info, 
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .info p{
        text-align: center;
        margin: 0;
        padding: 2px 10px;
    }
}
@media only screen and  (max-width: 992px) {
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .sidepanel.learge-side-bar{
        display: block;
        width: 100%;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body.case-studies{
        padding: 0;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .sidepanel p{
        padding-bottom: 0;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .sidepanel.learge-side-bar{
        padding: 33px 25px;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel.learge-side-bar{
        width: 100%;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel h4{
        padding: 0 20px 0 35px;
    }
.schedule_consultation .modal{overflow-y:auto;}
.schedule_consultation .modal .modal-dialog{width:auto; max-width: 700; margin: 0 15px;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body{
    position:relative;
    padding:25px 10px 10px;
}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .sidepanel{
    width: 100%;
    padding: 30px;
}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel h5{
    padding-left: 30px;
    text-align: left;
    padding-top: 20px;
}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body{
    padding: 0;
}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .sidepanel .schedule_consultation_icon_wrapper{
    display: none;
}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel{width:100%;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel h5{font-size:20px;line-height:1;margin:0;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard{
    margin-top:20px;
    margin-bottom:40px;
    padding:0;
}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .wizard-inner{margin:0 0 46px 0;}
.schedule_consultation .modal .schedule_consultation-content .rightpanel .schedule_consultation-box .details{padding:0 20px;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row{flex-wrap:wrap;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row{flex-wrap:wrap;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row .left{width:100%;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row .right{width:100%;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row .right .row{flex-wrap:wrap;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row .right .row .left-sec{width:100%;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row .right .row .left-sec .form-group{margin-bottom:15px;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row .right .row .right-sec .form-group{margin-bottom:15px;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row .right .row .right-sec{width:100%;}
.schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .info{position:absolute;bottom:0;left:auto;right:0;width:100%;}
}

@media only screen and  (max-width: 600px) {
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel h4 {
        padding: 0 20px 0 20px;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .sidepanel h4 {
        font-size: 18px;
        line-height: 22px;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .sidepanel p {
        font-size: 14px;
    }

    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel .wizard .schedule_consultation-box .details .row .right .row .buttom-sec .form-group {
        margin-bottom: 35px;
    }

    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .sidepanel{
        padding: 20px;
    }
    .schedule_consultation .modal .modal-dialog .modal-content .modal-body .schedule_consultation-content .rightpanel h5{
        padding: 20px 20px 0;
    }
}

.relative-modal{
    position: relative;
    z-index: 99;
}